import {
	EuiButtonIcon,
	EuiFlexGroup,
	EuiFlexItem,
	EuiHorizontalRule,
	EuiLoadingSpinner,
	EuiModal,
	EuiModalBody,
	EuiModalHeader,
	EuiModalHeaderTitle,
	EuiOverlayMask,
} from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { addToast } from 'modules/toasts/toasts.actions';
import { getErrorMessage } from 'utils/helpers';
import { FiShare2 } from 'react-icons/fi';
import AdsRating from './AdsRating';
import { postAccumulatePts } from './advertisement.fetch';

const AdsModal = () => {
	const dispatch = useDispatch();
	const adsDataRating = JSON.parse(localStorage.getItem('dataForRating'));
	const advertisementPoints = adsDataRating?.accumulatePoints
		? JSON.parse(adsDataRating?.accumulatePoints)
		: null;

	const [visible, setVisible] = useState(false);
	const [ratingVisible, setRatingVisible] = useState(false);

	const { mutate } = useMutation(postAccumulatePts, {
		onSuccess: () => {
			dispatch(
				addToast(
					'You have accumulated points by viewing this advertisement.',
					null,
					'success',
					'check',
				),
			);
		},
		onError: (err) => {
			if (err?.response?.status !== 409) {
				const errorMessage = getErrorMessage(err);
				dispatch(addToast('Error', errorMessage, 'danger', 'help'));
			}
		},
	});

	useEffect(() => {
		setVisible(!!adsDataRating);

		const timeout = setTimeout(() => {
			setRatingVisible(true);
		}, 7000);

		return () => {
			clearTimeout(timeout);
		};
	}, [adsDataRating]);

	useEffect(() => {
		if (ratingVisible) {
			const pointsByReading = advertisementPoints?.reading ?? 0;

			if (pointsByReading > 0) {
				mutate({
					advertisementId: adsDataRating?.advertisementId,
					action: 'READING',
					points: pointsByReading,
				});
			}
		}
	}, [ratingVisible]);

	const handleHideModal = () => {
		setVisible(false);
		localStorage.removeItem('dataForRating');
	};

	const handleClickShare = () => {
		// local storage data value is string
		const isMedicine = adsDataRating?.medicine === 'true';

		if (isMedicine) {
			window.localStorage.removeItem('dataForRating');
			window.localStorage.setItem(
				'data',
				JSON.stringify({
					...adsDataRating,
					event_id: 'adsData',
				}),
			);

			window.location.reload();
		} else {
			const content = adsDataRating?.content.replace(/<(.|\n)*?>/g, '');
			window.open(
				`https://mail.google.com/mail/?view=cm&fs=1&su=${adsDataRating?.title}&bcc=orders@nowna.com.ph&body=${content} %0D%0A%0D%0A link: ${adsDataRating?.link}`,
				'name',
				'width=600,height=400',
			);
		}
	};

	const spinner = (
		<>
			<EuiFlexItem style={{ paddingLeft: '24px' }}>
				<EuiLoadingSpinner size="l" />
			</EuiFlexItem>
		</>
	);

	if (!visible) {
		return null;
	}

	return (
		<EuiOverlayMask key="ads-modal">
			<EuiModal onClose={handleHideModal}>
				<EuiModalHeader>
					<EuiModalHeaderTitle size="m">
						{adsDataRating.title}
					</EuiModalHeaderTitle>
				</EuiModalHeader>
				<EuiHorizontalRule />
				<EuiFlexGroup justifyContent="spaceBetween">
					{ratingVisible ? (
						<>
							<EuiFlexItem
								grow={false}
								style={{ paddingLeft: '24px' }}
							>
								<AdsRating advertisementData={adsDataRating} />
							</EuiFlexItem>
						</>
					) : (
						spinner
					)}
					<EuiFlexItem grow={false} style={{ paddingRight: '24px' }}>
						<EuiFlexGroup
							alignItems="center"
							grow={false}
							gutterSize="s"
						>
							<EuiFlexItem>
								<EuiButtonIcon
									iconType={() => <FiShare2 size={22} />}
									onClick={handleClickShare}
								/>
							</EuiFlexItem>
						</EuiFlexGroup>
					</EuiFlexItem>
				</EuiFlexGroup>
				<EuiHorizontalRule />
				<EuiModalBody>
					<img
						alt={adsDataRating.title}
						src={adsDataRating.image}
						style={{
							width: '100%',
							height: 'auto',
							objectFit: 'cover',
						}}
					/>
				</EuiModalBody>
			</EuiModal>
		</EuiOverlayMask>
	);
};

export default AdsModal;
