import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { EuiHealth } from '@elastic/eui';

const OrderStatus = ({ status }) => {
	const { t } = useTranslation();
	let color;

	switch (status || '') {
		case 'new':
		case 'pendingPayment':
		case 'processing':
		case 'readyForPickup':
			color = 'primary';
			break;
		case 'released':
			color = 'secondary';
			break;
		case 'cancelled':
			color = 'danger';
			break;
		default:
			color = 'disabled';
	}

	return (
		<EuiHealth color={color}>
			{status ? t(`orders.status.${status}`) : 'No Status'}
		</EuiHealth>
	);
};

OrderStatus.propTypes = {
	status: PropTypes.oneOf([
		'new',
		'pendingPayment',
		'processing',
		'readyForPickup',
		'released',
		'cancelled',
	]),
};

OrderStatus.defaultProps = {
	status: null,
};

export default OrderStatus;
