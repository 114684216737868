/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// import { EuiToolTip, EuiIcon } from '@elastic/eui';
import Table from 'components/Table';
import { EuiBadge } from '@elastic/eui';

const PrescriptionItemTable = ({
	isEditable,
	isPatient,
	items,
	onDelete,
	onEdit,
	...props
}) => {
	const { t } = useTranslation();

	const snakeCaseToTitleCase = (string) => {
		if (!string) return '';
		return string
			.replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
			.replace(/[-_]+(.)/g, (_, c) => ` ${c.toUpperCase()}`);
	};

	let columns = [
		{
			field: 'generic',
			name: t('prescriptions.generic'),
			sortable: true,
		},
		{
			field: 'brand',
			name: t('prescriptions.brand'),
			sortable: true,
		},
		{
			field: 'formulation',
			name: t('prescriptions.formulation'),
			sortable: true,
		},
		{
			field: 'addedBy',
			name: t('prescriptions.addedBy'),
			render: (userRole) => {
				return (
					userRole && (
						<>
							<EuiBadge>
								{snakeCaseToTitleCase(userRole)}
							</EuiBadge>
						</>
					)
				);
			},
			sortable: true,
		},
		{
			field: 'quantity',
			name: 'Quantity',
			sortable: true,
			dataType: 'number',
		},
	];

	// 	columns.push({
	// 		field: 'currentQty',
	// 		name: (
	// 			<span>
	// 				{t('prescriptions.quantity')}{' '}
	// 				<EuiToolTip
	// 					content="Quantity already served to the patient"
	// 					delay="long"
	// 				>
	// 					<EuiIcon
	// 						className="eui-alignCenter"
	// 						color="subdued"
	// 						size="s"
	// 						type="questionInCircle"
	// 					/>
	// 				</EuiToolTip>
	// 			</span>
	// 		),
	// 		sortable: true,
	// 		dataType: 'number',
	// 		render: (currentQty, { quantity: qty }) => {
	// 			let served = 0;

	// 			if (currentQty > 0) {
	// 				served = qty - currentQty;
	// 			} else {
	// 				served = qty;
	// 			}

	// 			return `${served}/${qty}`;
	// 		},
	// 	});

	if (isEditable) {
		columns = [
			...columns,
			{
				width: '10%',
				actions: [
					{
						name: 'Edit',
						description: 'Edit',
						onClick: onEdit,
						icon: 'pencil',
						type: 'icon',
						color: 'primary',
						isPrimary: true,
					},
					{
						name: 'Delete',
						description: 'Delete',
						onClick: onDelete,
						icon: 'trash',
						type: 'icon',
						color: 'danger',
						isPrimary: true,
					},
				],
			},
		];
	}

	return (
		<Table
			columns={columns}
			isExpandable
			itemId="id"
			items={items}
			{...props}
		/>
	);
};

PrescriptionItemTable.propTypes = {
	isEditable: PropTypes.bool,
	isPatient: PropTypes.bool,
	items: PropTypes.oneOfType([PropTypes.array]).isRequired,
	onDelete: PropTypes.func,
	onEdit: PropTypes.func,
};

PrescriptionItemTable.defaultProps = {
	isEditable: true,
	isPatient: false,
	onDelete: () => {},
	onEdit: () => {},
};

export default PrescriptionItemTable;
