/* eslint-disable no-plusplus */
/* eslint-disable no-console */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
	EuiButton,
	EuiButtonEmpty,
	EuiFilePicker,
	EuiFlexGroup,
	EuiFlexItem,
	EuiFlyout,
	EuiFlyoutBody,
	EuiFlyoutFooter,
	EuiFlyoutHeader,
	EuiForm,
	EuiFormRow,
	EuiRadioGroup,
	EuiTitle,
	EuiHorizontalRule,
	EuiLink,
	EuiButtonIcon,
	EuiSwitch,
	EuiSelect,
	EuiText,
} from '@elastic/eui';

import _ from 'lodash';
import { useFormik } from 'formik';

import PropTypes from 'prop-types';

import PatientSearch from 'components/forms/PatientSearch';
import PersonalInformationForm from 'components/PersonalInfomationForm';
import AddressForm from 'components/AddressForm';
import { addToast } from 'modules/toasts/toasts.actions';

import FilesSlider from 'components/FilesSlider';

import { getVendors } from 'modules/vendors/vendors.fetch';
import { ROLE_PATH } from 'constants/protect';
import { DOCTOR } from 'components/roles';

import { useMutation } from 'react-query';
import { postAccumulatePts } from 'modules/dashboard/components/Advertisements/advertisement.fetch';
import { getErrorMessage } from 'utils/helpers';
import {
	createPrescription,
	AddPrescriptionSchema,
	AddPrescriptionEmailRequiredSchema,
	rxModel,
} from './prescriptions.fetch';
import {
	createRxSuccess,
	createRxDashboardSuccess,
} from './prescriptions.actions';
import { openSendRxModal } from './sendRxModal.actions';

const AddPrescription = ({ onClose, rxSharedData, visible }) => {
	const { role } = useSelector((state) => ({
		role: _.get(state, ROLE_PATH),
	}));
	const { push } = useHistory();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [isLoadingAttachments, setIsLoadingAttachments] = useState(false);
	const [, setAttachments] = useState([]);
	const [resetFilePicker, setResetFilePicker] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [patient, setPatient] = useState(null);
	const [isSpecialRecommendation, setIsSpecialRecommendation] =
		useState(false);
	const [isMaintenanceMedicine, setIsMaintenanceMedicine] = useState(false);
	const [selectVendor, setSelectVendor] = useState(false);
	const [vendorOptions, setVendorOptions] = useState([]);

	const advertisementPoints = rxSharedData?.accumulatePoints
		? JSON.parse(rxSharedData?.accumulatePoints)
		: null;

	const { mutate: accumulatePointsMutate } = useMutation(postAccumulatePts, {
		onSuccess: () => {
			dispatch(
				addToast(
					'You have accumulated points by sharing an advertisement.',
					null,
					'success',
					'check',
				),
			);
		},
		onError: (err) => {
			if (err?.response?.status !== 409) {
				const errorMessage = getErrorMessage(err);
				dispatch(addToast('Error', errorMessage, 'danger', 'help'));
			}
		},
	});

	async function fetchVendors() {
		setIsLoading(true);
		setVendorOptions([]);
		const recommendation =
			rxSharedData?.special_recommendation || isSpecialRecommendation;
		const results = await getVendors();

		if (recommendation === true || recommendation === 'true') {
			try {
				if (results.data.length > 0) {
					const filteredVendors = results.data
						.filter((item) => item.isSpecialRecommendation)
						.map((item) => {
							return { value: item.id, text: item.name };
						});
					setVendorOptions(filteredVendors);
				} else {
					setVendorOptions([]);
				}
			} catch (err) {
				console.log(err);
			} finally {
				setIsLoading(false);
			}
		} else {
			setIsLoading(false);
		}
	}

	useEffect(() => {
		fetchVendors();
	}, [isSpecialRecommendation]);

	const onChangeSpecialRecommendation = () => {
		setIsSpecialRecommendation(!isSpecialRecommendation);
	};

	const onChangeVendor = (data) => {
		setSelectVendor(data);
	};

	const onChangeMaintenanceMedicine = () => {
		setIsMaintenanceMedicine(!isMaintenanceMedicine);
	};

	const formikBag = useFormik({
		initialValues: {
			...rxModel,
		},
		validationSchema: isMaintenanceMedicine
			? AddPrescriptionEmailRequiredSchema
			: AddPrescriptionSchema,
		onSubmit: async (data, { resetForm: reset }) => {
			try {
				setIsLoading(true);

				const payload = { ...data };

				if (isMaintenanceMedicine) payload.maintenance = true;

				const { data: newPrescription } = await createPrescription(
					payload,
				);

				if (!newPrescription) {
					throw new Error('Failed to create prescription');
				}

				dispatch(
					addToast(
						'Success',
						`Successfully added prescription with code: ${newPrescription.code}`,
						'success',
					),
				);

				if (advertisementPoints?.sharing) {
					accumulatePointsMutate({
						advertisementId: rxSharedData?.advertisementId,
						action: 'SHARING',
						points: advertisementPoints?.sharing ?? 0,
						maxPoints: advertisementPoints?.max_sharing,
					});
				}

				dispatch(createRxSuccess(newPrescription));
				dispatch(createRxDashboardSuccess(newPrescription));
				dispatch(openSendRxModal(newPrescription.id));

				setAttachments([]);

				reset();
				onClose();
			} catch (err) {
				const errorResponse = ((err || {}).response || {}).data || null;
				dispatch(
					addToast('Error', errorResponse.message, 'danger', 'help'),
				);
			} finally {
				setIsLoading(false);
			}
		},
	});

	const {
		errors,
		handleSubmit,
		isSubmitting,
		resetForm,
		setFieldTouched,
		setFieldValue,
		touched,
		values,
	} = formikBag;

	useEffect(() => {
		const errorKeys = Object.keys(errors);
		if (errorKeys.length) {
			const errorField = document.getElementsByName(errorKeys[0])[0];
			if (errorField) {
				errorField.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
				});
			}
		}
	}, [isSubmitting]);

	const toDataURL = (url) =>
		fetch(`${url}?${123456}`, {
			mode: 'cors',
			headers: {
				'Access-Control-Allow-Origin': '*',
				'Content-Type': 'text/plain',
			},
		})
			.then((response) => response.blob())
			.then(
				(blob) =>
					new Promise((resolve, reject) => {
						const reader = new FileReader();
						reader.onloadend = () => resolve(reader.result);
						reader.onerror = reject;
						reader.readAsDataURL(blob);
					}),
			);

	function dataURLtoFile(dataurl, filename) {
		const arr = dataurl.split(',');
		const mime = arr[0].match(/:(.*?);/)[1];
		const bstr = atob(arr[1]);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], filename, { type: mime });
	}

	const handleFilePicker = (event) => {
		const { length, ...fileItems } = event;
		setFieldTouched('attachments', true);
		if (!length) return null;

		const newAdditionalFiles = Object.keys(fileItems).map(
			(key) => event[key],
		);

		if (newAdditionalFiles.length) {
			setAttachments((value) => [...value, ...newAdditionalFiles]);
			setFieldValue('attachments', []);
			setIsLoadingAttachments(true);
			setTimeout(() => {
				setFieldValue('attachments', [
					...values.attachments,
					...newAdditionalFiles,
				]);

				setIsLoadingAttachments(false);
			}, 1000);
		}

		return setResetFilePicker(Math.random().toString(36));
	};

	const adAttachment = () => {
		if (rxSharedData) {
			if (rxSharedData?.special_recommendation === 'true')
				setIsSpecialRecommendation(true);
			if (rxSharedData?.maintenance_medicine === 'true')
				setIsMaintenanceMedicine(true);
			toDataURL(rxSharedData?.image).then((dataUrl) => {
				const fileData = dataURLtoFile(
					dataUrl,
					`${rxSharedData?.title.replace(' ', '')}`,
				);
				handleFilePicker([fileData]);
			});
		}
	};

	useEffect(() => {
		adAttachment();
	}, [!values.attachments]);

	useEffect(() => {
		const setPatientData = () => {
			setFieldValue('firstName', _.get(patient, 'firstName', ''));
			setFieldValue('middleName', _.get(patient, 'middleName', ''));
			setFieldValue('lastName', _.get(patient, 'lastName', ''));
			setFieldValue('birthdate', _.get(patient, 'birthdate', null));
			setFieldValue('line1', _.get(patient, 'address.line1', ''));
			setFieldValue('line2', _.get(patient, 'address.line2', ''));
			setFieldValue('cityId', _.get(patient, 'address.cityId', ''));
			setFieldValue('brgyId', _.get(patient, 'address.brgyId', ''));
			setFieldValue('email', patient?.email ?? '');
			setFieldValue('mobile', patient?.mobile ?? '');
		};
		setPatientData();
	}, [patient]);

	useEffect(() => {
		setPatient(null);
		setFieldValue('patient', '');
	}, [values.addNewPatient]);

	const handleClose = () => {
		onClose();
		resetForm();
		localStorage.removeItem('data');
		if (rxSharedData) {
			window.location.reload(true);
		}
	};

	if (!visible) return null;

	const onPatientSelect = (selected) => {
		if (selected) {
			setPatient(selected);
			return setFieldValue('patient', selected.value);
		}

		setPatient('');
		return setFieldValue('patient', '');
	};

	const handleNewPatientAddress = () => {
		setFieldValue('newPatientAddress', !values.newPatientAddress);
	};

	const toCreate = () => {
		onClose();
		resetForm();
		localStorage.removeItem('data');
		push('/prescriptions/create');
	};

	const patientOptions = [
		{
			id: 'new',
			label: 'New Patient',
		},
		{
			id: 'existing',
			label: 'Existing Patient',
		},
	];

	const encodeErxLink = () => {
		return (
			<>
				OR <EuiLink onClick={toCreate}>Encode eRx</EuiLink>
			</>
		);
	};

	return (
		<EuiFlyout
			closeButtonAriaLabel="Close Add Prescription"
			onClose={handleClose}
			ownFocus={false}
			size="s"
		>
			<EuiFlyoutHeader hasBorder>
				<EuiTitle size="s">
					<EuiText>
						Add Prescription{' '}
						{role === DOCTOR.key && encodeErxLink()}
					</EuiText>
				</EuiTitle>
			</EuiFlyoutHeader>
			<EuiFlyoutBody>
				<EuiForm>
					<EuiFormRow>
						<EuiTitle size="xs">
							<h3>Patient Information</h3>
						</EuiTitle>
					</EuiFormRow>
					<EuiHorizontalRule margin="xs" />
					<EuiFormRow>
						<EuiRadioGroup
							className="radio-group-row"
							id="addNewPatient"
							idSelected={
								values.addNewPatient ? 'new' : 'existing'
							}
							name="addNewPatient"
							onChange={() => {
								setFieldValue(
									'addNewPatient',
									!values.addNewPatient,
								);
							}}
							options={patientOptions}
						/>
					</EuiFormRow>
					{!values.addNewPatient && (
						<EuiFormRow
							error={touched.patient && errors.patient}
							fullWidth
							helpText="Enter 3 or more characters to search"
							isInvalid={touched.patient && !!errors.patient}
							label="Patient"
						>
							<PatientSearch
								fullWidth
								isInvalid={touched.vendor && !!errors.patient}
								name="patient"
								onChange={onPatientSelect}
								selected={patient}
							/>
						</EuiFormRow>
					)}
					{(values.addNewPatient || patient) && (
						<EuiFormRow fullWidth>
							<PersonalInformationForm
								editContacts
								emailRequired={isMaintenanceMedicine}
								formikBag={formikBag}
								readOnly={!values.addNewPatient}
							/>
						</EuiFormRow>
					)}
					{(values.addNewPatient || patient) && (
						<>
							<EuiFormRow>
								<EuiFlexGroup
									alignItems="center"
									gutterSize="s"
									responsive={false}
								>
									<EuiFlexItem grow={false}>
										<EuiTitle size="xs">
											<h3>Address</h3>
										</EuiTitle>
									</EuiFlexItem>
									<EuiFlexItem grow={false}>
										<EuiButtonIcon
											aria-label="Add Contact Detail Entry"
											color="primary"
											iconType={
												values.newPatientAddress
													? 'minusInCircle'
													: 'plusInCircle'
											}
											onClick={handleNewPatientAddress}
										/>
									</EuiFlexItem>
								</EuiFlexGroup>
							</EuiFormRow>
							<EuiHorizontalRule margin="xs" />
							{values.newPatientAddress && (
								<EuiFormRow fullWidth>
									<AddressForm
										formikBag={formikBag}
										readOnly={false}
									/>
								</EuiFormRow>
							)}
						</>
					)}

					<EuiFormRow fullWidth>
						<EuiSwitch
							checked={!!isSpecialRecommendation}
							label="Special Recommendation"
							name="switch"
							onChange={onChangeSpecialRecommendation}
						/>
					</EuiFormRow>

					<EuiFormRow fullWidth>
						<EuiSwitch
							checked={!!isMaintenanceMedicine}
							label="Maintenance Medicine"
							name="switch"
							onChange={onChangeMaintenanceMedicine}
						/>
					</EuiFormRow>

					{!!isSpecialRecommendation && (
						<EuiFormRow fullWidth label="Vendor">
							<EuiSelect
								disabled={
									isSpecialRecommendation === false ||
									vendorOptions.length === 0
								}
								hasNoInitialSelection
								onChange={(e) => onChangeVendor(e.target.value)}
								options={
									vendorOptions && vendorOptions.length > 0
										? vendorOptions
										: []
								}
								value={selectVendor.toString()}
							/>
						</EuiFormRow>
					)}

					<EuiFormRow>
						<EuiTitle size="xs">
							<h3>Attachments</h3>
						</EuiTitle>
					</EuiFormRow>
					<EuiHorizontalRule margin="xs" />
					<EuiFormRow
						error={
							!isLoadingAttachments &&
							touched.attachments &&
							errors.attachments
						}
						fullWidth
						isInvalid={
							!isLoadingAttachments &&
							touched.attachments &&
							!!errors.attachments
						}
					>
						<EuiFlexGroup direction="column" gutterSize="s">
							<EuiFlexItem>
								<EuiFilePicker
									key={resetFilePicker}
									fullWidth
									id="attachmentsFilePicker"
									multiple
									onChange={handleFilePicker}
								/>
							</EuiFlexItem>
							<EuiFlexItem>
								<FilesSlider
									data={values.attachments}
									isLoading={isLoadingAttachments}
									name="attachments"
									onChange={({ value }) => {
										setFieldValue('attachments', [
											...value,
										]);
										setAttachments([...value]);
									}}
									readOnly
								/>
							</EuiFlexItem>
						</EuiFlexGroup>
					</EuiFormRow>
				</EuiForm>
			</EuiFlyoutBody>
			<EuiFlyoutFooter>
				<EuiFlexGroup
					direction="row"
					gutterSize="m"
					justifyContent="spaceBetween"
					responsive={false}
				>
					<EuiFlexItem>
						<EuiButtonEmpty
							color="danger"
							iconType="crossInACircleFilled"
							onClick={handleClose}
						>
							{t('general.cancel')}
						</EuiButtonEmpty>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiButton
							disabled={isLoading}
							fill
							iconType="plusInCircleFilled"
							isLoading={isLoading}
							onClick={handleSubmit}
						>
							Submit
						</EuiButton>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiFlyoutFooter>
		</EuiFlyout>
	);
};

AddPrescription.propTypes = {
	onClose: PropTypes.func.isRequired,
	rxSharedData: PropTypes.func,
	visible: PropTypes.bool,
};

AddPrescription.defaultProps = {
	rxSharedData: null,
	visible: false,
};

export default AddPrescription;
