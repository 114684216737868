import React from 'react';

import { EuiSuperSelect } from '@elastic/eui';

import PropTypes from 'prop-types';

import {
	NEW,
	PENDING_PAYMENT,
	PROCESSING,
	READY_FOR_PICKUP,
	RELEASED,
	CANCELLED,
} from '../../../constants/order.statuses';

import OrderStatus from './OrderStatus';

const OrderStatusDropdown = ({
	isPaid,
	onChange,
	pickup,
	readOnly,
	selected,
	showDisabled,
	...props
}) => {
	const statusOptions = [
		NEW,
		PENDING_PAYMENT,
		PROCESSING,
		READY_FOR_PICKUP,
		RELEASED,
		CANCELLED,
	];

	const isDisabled = (value, selectedValue) => {
		const index = statusOptions.findIndex(
			(status) => status.value === selectedValue,
		);

		if (!statusOptions[index]) {
			return value === selectedValue;
		}

		return value === selectedValue || pickup
			? !statusOptions[index].pickupAllowedNextStatus.includes(value)
			: !statusOptions[index].deliveryAllowedNextStatus.includes(value);
	};

	const statusOpts = () => {
		let options = statusOptions;
		if (!showDisabled) {
			options = statusOptions.filter(
				(status) =>
					status.value === selected ||
					!isDisabled(status.value, selected),
			);
		}
		return options.map((status) => {
			return {
				value: status.value,
				inputDisplay: <OrderStatus status={status.value} />,
				dropdownDisplay: <OrderStatus status={status.value} />,
				disabled: isDisabled(status.value, selected),
			};
		});
	};

	if (readOnly) {
		return <OrderStatus status={selected} />;
	}

	return (
		<EuiSuperSelect
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
			data-testid="order.status"
			id="order.status"
			name="order.status"
			onChange={onChange}
			options={statusOpts()}
			valueOfSelected={selected}
		/>
	);
};

OrderStatusDropdown.propTypes = {
	isPaid: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	pickup: PropTypes.bool,
	readOnly: PropTypes.bool,
	selected: PropTypes.string,
	showDisabled: PropTypes.bool,
};

OrderStatusDropdown.defaultProps = {
	isPaid: false,
	pickup: false,
	readOnly: false,
	selected: null,
	showDisabled: false,
};

export default OrderStatusDropdown;
