import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
	EuiTitle,
	EuiSpacer,
	EuiHorizontalRule,
	EuiButton,
	EuiText,
	EuiFlexGroup,
	EuiFlexItem,
	EuiFormRow,
	EuiFieldText,
} from '@elastic/eui';
import Page from 'components/Page';
import CustomStep from 'components/Step';
import {
	getOrderSchedule,
	setOrderSchedule,
} from 'modules/patients/patients.fetch';
import { getPrescription } from 'modules/prescriptions/prescriptions.fetch';
import PrescriptionItemTable from 'modules/prescriptions/components/PrescriptionItemsTable';
import PatientOrderScheduleForm from './PatientOrderScheduleForm';
import { addToast } from '../toasts/toasts.actions';

const PatientOrderSchedule = () => {
	const [rxCode, setRxCode] = useState(null);
	const [prescription, setPrescription] = useState(null);
	const [schedule, setSchedule] = useState({});
	const [step, setStep] = useState(1);
	const [loading, setLoading] = useState(false);
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		async function init() {
			if (location.state) {
				const { rxCode: code } = location.state;
				if (code) {
					setRxCode(code);

					const rxData = await getPrescription(code);
					setPrescription(rxData.data);

					const rxSchedule = await getOrderSchedule(rxData.data?.id);
					setSchedule(rxSchedule.data);
				}
			} else {
				history.push('/my-orders/create');
			}
		}
		init();
	}, []);

	const getStepStatus = (index) => {
		if (index === step) return 'current';
		if (step > index) return 'complete';
		return 'incomplete';
	};

	const steps = [
		{
			key: 1,
			title: 'Recurring Prescription Details',
			description: 'List of medicines',
			status: getStepStatus(1),
		},
		{
			key: 2,
			title: 'Recurring Order Details',
			description: 'Set schedule',
			status: getStepStatus(2),
		},
	];

	const onPrev = () => {
		if (step > 1) setStep(step - 1);
		return null;
	};

	const onNext = () => {
		if (step < steps.length) {
			setStep(step + 1);
			window.scrollTo(0, 0);
		}
		return null;
	};

	const goToDashboard = () => {
		history.push('/');
	};

	const formikBag = useFormik({
		initialValues: {
			title: '',
			intervalValue: 1,
			intervalUnit: 'month',
			dayOfMonth: 1,
			dayOfWeek: 1,
			repeatCount: 1,
			repeatEndDate: null,
			modeOfContact: 'all',
			email: '',
			mobile: '',
			schedule: null,
		},
		validateOnBlur: true,
		onSubmit: (data) => {
			setLoading(true);
			const payload = {
				title: data.title,
				dates: data.schedule?.map((date) => moment(date).toISOString()),
				rxCode,
			};
			switch (data.modeOfContact) {
				case 'all':
					payload.email = true;
					payload.mobile = true;
					break;
				case 'email':
					payload.email = true;
					break;
				case 'mobile':
					payload.mobile = true;
					break;
				default:
					break;
			}
			try {
				setOrderSchedule(payload).then(() => {
					setLoading(false);
					dispatch(
						addToast(
							'Success',
							'Reminders Saved!',
							'success',
							'check',
						),
					);
					history.push('/');
				});
			} catch (err) {
				setLoading(false);
				const errorResponse = ((err || {}).response || {}).data || null;
				dispatch(
					addToast(
						'Error',
						errorResponse.error || 'Something went wrong',
						'danger',
						'help',
					),
				);
			}
		},
	});

	const { errors, handleBlur, handleChange, setFieldValue, touched, values } =
		formikBag;

	useEffect(() => {
		setFieldValue('schedule', schedule.dates);
		setFieldValue('title', schedule.title);
	}, [schedule]);

	return (
		<>
			<Page>
				<CustomStep steps={steps} />
				{step === 1 && (
					<>
						<EuiSpacer />
						<EuiTitle size="s">
							<span>Recurring Prescription Details</span>
						</EuiTitle>
						<EuiHorizontalRule margin="s" />
						<EuiText>
							The medicine added here will be included in the list
							of recurring orders. You may still add additional
							items to each individual order as needed.
						</EuiText>
						<EuiSpacer />
						<EuiTitle size="xs">
							<span>Reminder title</span>
						</EuiTitle>
						<EuiHorizontalRule margin="s" />
						<EuiFormRow
							error={touched.title && errors.title}
							isInvalid={touched.title && !!errors.title}
						>
							<EuiFieldText
								id="title"
								isInvalid={touched.title && !!errors.title}
								name="title"
								onBlur={handleBlur}
								onChange={handleChange}
								value={values.title}
							/>
						</EuiFormRow>
						<EuiSpacer />
						<EuiTitle size="xs">
							<span>Prescription Items</span>
						</EuiTitle>
						<EuiHorizontalRule margin="s" />
						<EuiSpacer />
						<PrescriptionItemTable
							isEditable={false}
							items={prescription?.prescriptionItems}
						/>
						<EuiSpacer />
						<EuiFlexGroup justifyContent="spaceBetween">
							<EuiFlexItem grow={false}>
								<EuiButton onClick={goToDashboard}>
									Cancel
								</EuiButton>
							</EuiFlexItem>
							<EuiFlexItem grow={false}>
								<EuiButton onClick={onNext}>Next</EuiButton>
							</EuiFlexItem>
						</EuiFlexGroup>
					</>
				)}
				{step === 2 && (
					<>
						<PatientOrderScheduleForm
							formikBag={formikBag}
							prescription={prescription}
						/>
						<EuiSpacer />
						<EuiFlexGroup justifyContent="spaceBetween">
							<EuiFlexItem grow={false}>
								<EuiButton onClick={onPrev}>Prev</EuiButton>
							</EuiFlexItem>
							<EuiFlexItem grow={false}>
								<EuiButton
									fill
									isLoading={loading}
									onClick={formikBag.handleSubmit}
								>
									Submit
								</EuiButton>
							</EuiFlexItem>
						</EuiFlexGroup>
					</>
				)}
			</Page>
		</>
	);
};

PatientOrderSchedule.propTypes = {};

PatientOrderSchedule.defaultProps = {};

export default PatientOrderSchedule;
