import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import {
	EuiFlexGroup,
	EuiFlexItem,
	EuiTitle,
	EuiHorizontalRule,
	EuiForm,
	EuiRadioGroup,
	EuiText,
	EuiDescriptionList,
	EuiFormRow,
	EuiSpacer,
	EuiLink,
	EuiButton,
	EuiFilePicker,
	EuiCheckbox,
	EuiTextArea,
	EuiFieldText,
} from '@elastic/eui';

import { get } from 'lodash';
import PropTypes from 'prop-types';

import VendorDropdown from 'components/forms/VendorDropdown';
import TermsModal from 'modules/auth/TermsModal';
import CityMunicipalityDropdown from 'components/forms/CityMunicipalityDropdown';
import BranchDropdown from 'components/forms/BranchDropdown';
import { formSetFieldValue, getInvalid } from 'utils/helpers';
import FilesSlider from 'components/FilesSlider';
import RxFulfillmentDropdown from 'modules/prescriptions/components/RxFulfillmentDropdown';
import PaymentMethodDropdown from 'modules/prescriptions/components/PaymentMethodDropdown';
import Map from 'modules/validate-prescription/components/Map';
// import PrescriptionItemForm from 'modules/prescriptions/components/PrescriptionItemForm';
// import { ADD_PRESCRIPTION } from 'constants/prescription';
import {
	FIELDS_CUSTOMER_DETAILS,
	FIELDS_SHIPPING_DETAILS,
} from 'constants/form.fields';
import PrivacyModal from '../validate-prescription/components/PrivacyModal';
import PatientOrderReceiverForm from './PatientOrderReceiverForm';
import PrescriptionItemForm from '../prescriptions/components/PrescriptionItemForm';
import { ADD_PRESCRIPTION } from '../../constants/prescription';
import { getGeocode } from '../validate-prescription/validate-prescription.fetch';

const PatientOrderForm = ({
	disabled,
	formikBag,
	isSameCustomerDeets,
	isSubmitting,
	isSubmittingNewOrder,
	mutateRenewRx,
	onNext,
	onPrev,
	patientInfo,
	setAttachments,
	setIsSameCustomerDeets,
	step,
	steps,
}) => {
	const [isLoadingAttachments, setIsLoadingAttachments] = useState(false);
	const [reset, setReset] = useState(0);
	const [acceptTermsAndAgreement, setAcceptTermsAndAgreement] =
		useState(false);
	const [termOpen, setTermOpen] = useState(false);
	const [privacyOpen, setPrivacyOpen] = useState(false);
	const [mapCenter, setMapCenter] = useState(undefined);
	const [markerPosition, setMarkerPosition] = useState(undefined);
	const [coordinates, setCoordinates] = useState(null);
	const [mobile, setMobile] = useState('');

	const pickupTypes = [
		{ id: 'branch', label: 'Branch Pickup' },
		{ id: 'grab', label: 'Grab Pickup' },
		// { id: 'delivery', label: 'Pharmacy Delivery' },
	];

	const history = useHistory();

	const {
		errors,
		handleSubmit,
		setFieldTouched,
		setFieldValue,
		touched,
		values,
	} = formikBag;

	useEffect(() => {
		const { contactDetails, customer } = patientInfo;

		if (customer) {
			formSetFieldValue(
				FIELDS_SHIPPING_DETAILS,
				setFieldValue,
				patientInfo?.customer,
			);
		}

		if (contactDetails) {
			const mobileDetail = contactDetails.find(
				(contact) => contact?.type === 'mobile',
			);

			setMobile(mobileDetail?.value ?? '');
		}
	}, [patientInfo]);

	useEffect(() => {
		setFieldValue('mobile', mobile);
	}, [mobile]);

	const setCity = (e) => {
		setFieldTouched('cityMunId', true);
		if (e) {
			return setFieldValue('cityMunId', e.value);
		}
		return setFieldValue('cityMunId', null);
	};

	const setVendor = (value) => {
		setFieldTouched('vendor', true);
		if (value) {
			return setFieldValue('vendor', value);
		}
		return setFieldValue('vendor', null);
	};

	const setBranch = (selectedOption) => {
		setFieldTouched('preferredBranch', true);
		if (selectedOption) {
			return setFieldValue('preferredBranch', selectedOption.value);
		}
		return setFieldValue('preferredBranch', null);
	};

	const handleFilePicker = (event) => {
		const { length, ...fileItems } = event;

		setFieldTouched('attachments', true);

		if (!length) return null;

		const newAdditionalFiles = Object.keys(fileItems).map(
			(key) => event[key],
		);
		if (newAdditionalFiles.length) {
			setAttachments((value) => [...value, ...newAdditionalFiles]);
			setFieldValue('attachments', []);
			setIsLoadingAttachments(true);
			setTimeout(() => {
				setFieldValue('attachments', [
					...values.attachments,
					...newAdditionalFiles,
				]);
				setIsLoadingAttachments(false);
			}, 1000);
		}

		return setReset(Math.random().toString(36));
	};

	const handleSameCustomerDeets = () => {
		setIsSameCustomerDeets(!isSameCustomerDeets);
	};

	const handleAcceptTermsAndAgreement = () => {
		setAcceptTermsAndAgreement(!acceptTermsAndAgreement);
	};

	const toggleTerm = () => setTermOpen(!termOpen);
	const togglePrivacy = () => setPrivacyOpen(!privacyOpen);

	const getRxItemsList = () => {
		if (!values.prescriptionItems || !values.prescriptionItems.length) {
			return null;
		}
		const items = values.prescriptionItems
			.filter((rxItem) => rxItem.currentQty > 0)
			.map((prescriptionItem) => {
				let quantity = prescriptionItem.currentQty;

				if (prescriptionItem.currentQty === 0) {
					quantity = prescriptionItem.quantity;
				}

				let title = prescriptionItem.generic;

				if (prescriptionItem.brand) {
					title = `${title} -  ${prescriptionItem.brand}`;
				}
				return {
					title,
					description: `${
						prescriptionItem.formulation || ''
					} x${quantity}`,
				};
			});

		return (
			<EuiFormRow label="Prescription Items">
				<EuiDescriptionList
					listItems={items}
					responsive={false}
					type="column"
				/>
			</EuiFormRow>
		);
	};

	const handleRxItemsSubmit = (data) => {
		setFieldValue('prescriptionItems', data);
	};

	const populateCustomerDeets = () => {
		if (isSameCustomerDeets) {
			if (patientInfo) {
				formSetFieldValue(
					FIELDS_SHIPPING_DETAILS,
					setFieldValue,
					patientInfo,
				);
			}
		}
	};

	const buildCoordinates = async () => {
		const geocode = await getGeocode({
			brgy: values.brgy,
			city: values.city,
			line1: values.line1,
			line2: values.line2,
		});

		const position = {
			lat: geocode.latitude,
			lng: geocode.longitude,
		};

		setCoordinates(position);
		setMarkerPosition(position);
		setMapCenter(position);
	};

	useEffect(() => {
		if (values.pickupType === 'grab') {
			buildCoordinates();
		} else {
			setCoordinates(null);
		}
	}, [
		values.pickupType,
		values.brgy,
		values.city,
		values.line1,
		values.line2,
	]);

	const showMap = () => {
		return mapCenter && markerPosition && values.pickupType === 'grab';
	};

	const handleChangeCenter = (newValue) => {
		// setMapCenter(newValue);
		setMarkerPosition(newValue);
	};

	useEffect(() => {
		populateCustomerDeets();
		formSetFieldValue(FIELDS_CUSTOMER_DETAILS, setFieldValue, patientInfo);
	}, [isSameCustomerDeets]);

	if (values.status === 'partial' || values.status === 'served') {
		let message = '';

		if (values.status === 'partial') {
			message = 'Your order has been partially served.';
		}

		if (values.status === 'served') {
			message = 'Your order has been partially served.';
		}

		return (
			<EuiFlexGroup direction="column" justifyContent="spaceAround">
				<EuiFlexItem
					grow={false}
					style={{
						borderColor: 'gray',
						borderWidth: '2px',
						borderStyle: 'solid',
						padding: '10%',
					}}
				>
					<EuiText
						size="m"
						style={{ fontWeight: 'bolder', fontSize: '24px' }}
						textAlign="center"
					>
						{message}
					</EuiText>
					<EuiSpacer />
				</EuiFlexItem>
				<EuiFlexItem grow={false}>
					<EuiButton
						isLoading={isSubmittingNewOrder}
						onClick={() => mutateRenewRx()}
					>
						New Order
					</EuiButton>
				</EuiFlexItem>
			</EuiFlexGroup>
		);
	}

	return (
		<>
			<EuiForm>
				<EuiSpacer />
				<EuiTitle size="s" style={{ margin: 0 }}>
					<h4>Send RX to Pharmacy</h4>
				</EuiTitle>
				<EuiHorizontalRule margin="s" />
				<EuiSpacer />
				{step === 1 && (
					<>
						<EuiFormRow
							error={touched.attachments && errors.attachments}
							isInvalid={
								touched.attachments && !!errors.attachments
							}
							label="Attach your prescription *"
						>
							<EuiFlexGroup direction="column" gutterSize="s">
								<EuiFlexItem>
									<EuiFilePicker
										key={reset}
										autoFocus
										fullWidth
										id="attachmentsFilePicker"
										isInvalid={
											touched.attachments &&
											!!errors.attachments
										}
										multiple
										onChange={handleFilePicker}
									/>
								</EuiFlexItem>
								<EuiFlexItem>
									<FilesSlider
										data={values.attachments}
										isLoading={isLoadingAttachments}
										name="attachments"
										onChange={({ value }) => {
											setFieldValue('attachments', [
												...value,
											]);
											setAttachments([...value]);
										}}
										readOnly
									/>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFormRow>
						{getRxItemsList()}
						<EuiSpacer />

						<PrescriptionItemForm
							formType={ADD_PRESCRIPTION}
							id="prescriptionItems"
							isEditable
							isPatient
							isVisible
							name="prescriptonItems"
							onSubmit={handleRxItemsSubmit}
							prescriptionItems={values.prescriptionItems}
						/>

						<EuiFormRow label="Prescription Fulfillment">
							<RxFulfillmentDropdown
								data-testid="fulfillment"
								id="fulfillment"
								name="fulfillment"
								onChange={formikBag.handleChange}
								value={values.fulfillment}
							/>
						</EuiFormRow>

						<EuiFormRow
							helpText=""
							label="Note to Pharmacist (Optional)"
						>
							<EuiTextArea
								data-testid="notes-input"
								id="patientNotes"
								name="patientNotes"
								onChange={formikBag.handleChange}
								placeholder="For additional items, change in brand or quantity."
								value={values.patientNotes}
							/>
						</EuiFormRow>
						<EuiSpacer />
						<EuiHorizontalRule margin="s" />
					</>
				)}

				{step === 2 && (
					<>
						<EuiFormRow
							error={touched.pickupType && errors.pickupType}
							isInvalid={getInvalid(
								'pickupType',
								errors,
								touched,
							)}
							label="Fulfillment Type *"
						>
							<EuiRadioGroup
								idSelected={values.pickupType}
								name="pickupType"
								onChange={(id) => {
									setFieldValue('pickupType', id);
								}}
								options={pickupTypes}
								style={{
									display: 'flex',
									flexDirection: 'column',
									textAlign: 'left',
								}}
							/>
						</EuiFormRow>
						<EuiSpacer />
						<EuiTitle size="s" style={{ margin: 0 }}>
							<h6>Customer Details</h6>
						</EuiTitle>
						<EuiHorizontalRule margin="s" />

						<EuiFlexGroup>
							<EuiFlexItem grow={false} style={{ width: 500 }}>
								<EuiFormRow
									error={touched.N && errors.N}
									isInvalid={touched.N && !!errors.N}
									label="First Name"
								>
									<EuiFieldText
										data-testid="first-name-input"
										disabled
										id="N"
										isInvalid={touched.N && !!errors.N}
										name="firstName"
										onChange={formikBag.handleChange}
										placeholder="First Name"
										value={values.firstName}
									/>
								</EuiFormRow>
							</EuiFlexItem>
							<EuiFlexItem grow={false} style={{ width: 500 }}>
								<EuiFormRow
									error={touched.lastName && errors.lastName}
									isInvalid={
										touched.lastName && !!errors.lastName
									}
									label="Last Name"
								>
									<EuiFieldText
										data-testid="last-name-input"
										disabled
										id="lastName"
										isInvalid={
											touched.lastName &&
											!!errors.lastName
										}
										name="lastName"
										onChange={formikBag.handleChange}
										placeholder="Last Name"
										value={values.lastName}
									/>
								</EuiFormRow>
							</EuiFlexItem>
						</EuiFlexGroup>

						<EuiSpacer />

						<EuiFlexGroup>
							<EuiFlexItem grow={false} style={{ width: 500 }}>
								<EuiFormRow
									error={touched.email && errors.email}
									isInvalid={touched.email && !!errors.email}
									label="Email Address"
								>
									<EuiFieldText
										data-testid="email-input"
										disabled
										id="email"
										isInvalid={
											touched.email && !!errors.email
										}
										name="email"
										onChange={formikBag.handleChange}
										placeholder="Email Address"
										value={values.email}
									/>
								</EuiFormRow>
							</EuiFlexItem>
							<EuiFlexItem grow={false} style={{ width: 500 }}>
								<EuiFormRow
									error={touched.mobile && errors.mobile}
									isInvalid={
										touched.mobile && !!errors.mobile
									}
									label="Contact Number"
								>
									<EuiFieldText
										data-testid="contact-number-input"
										disabled
										id="mobile"
										isInvalid={
											touched.mobile && !!errors.mobile
										}
										name="mobile"
										onChange={formikBag.handleChange}
										placeholder="Contact Number"
										value={values.mobile}
									/>
								</EuiFormRow>
							</EuiFlexItem>
						</EuiFlexGroup>
						<EuiSpacer />
						{(values.pickupType === 'delivery' ||
							values.pickupType === 'grab') && (
							<>
								<EuiTitle size="s" style={{ margin: 0 }}>
									<h6>Shipping Details</h6>
								</EuiTitle>
								<EuiHorizontalRule margin="s" />
								<EuiFormRow>
									<EuiFlexGroup direction="column">
										<EuiFlexItem>
											<EuiFlexGroup
												direction="column"
												gutterSize="s"
											>
												<EuiFlexItem>
													<EuiCheckbox
														checked={
															isSameCustomerDeets
														}
														id="customerDetails"
														label={
															<span
																style={{
																	fontSize: 12,
																}}
															>
																Copy customer
																details
															</span>
														}
														name="customerDetails"
														onChange={
															handleSameCustomerDeets
														}
													/>
												</EuiFlexItem>
											</EuiFlexGroup>
										</EuiFlexItem>
									</EuiFlexGroup>
								</EuiFormRow>
								<PatientOrderReceiverForm
									formikBag={formikBag}
								/>
								<EuiSpacer size="s" />
								{showMap() && (
									<Map
										key={process.env.REACT_APP_MAPS}
										editDisabled={false}
										mapCenter={mapCenter}
										markerPosition={markerPosition}
										onChange={handleChangeCenter}
									/>
								)}
							</>
						)}

						<EuiSpacer />

						<EuiHorizontalRule margin="s" />
					</>
				)}

				{step === 3 && (
					<>
						<EuiFormRow
							error={touched.cityMunId && errors.cityMunId}
							isInvalid={getInvalid('cityMunId', errors, touched)}
							label="City / Municipality *"
						>
							<CityMunicipalityDropdown
								isInvalid={getInvalid(
									'cityMunId',
									errors,
									touched,
								)}
								name="cityMunId"
								onChange={setCity}
								value={values.cityMunId}
							/>
						</EuiFormRow>
						<EuiSpacer size="m" />
						<EuiFormRow
							error={touched.vendor && errors.vendor}
							isInvalid={getInvalid('vendor', errors, touched)}
							label="Vendor *"
						>
							<VendorDropdown
								cityId={get(values, 'cityMunId', null)}
								isInvalid={getInvalid(
									'vendor',
									errors,
									touched,
								)}
								name="vendor"
								onChange={setVendor}
								pickup="Address"
								selected={
									values.vendor &&
									typeof values.vendor === 'object'
										? get(values.vendor, 'id', null)
										: values.vendor || null
								}
								showBranchInfo
							/>
						</EuiFormRow>

						<EuiFormRow
							error={
								touched.preferredBranch &&
								errors.preferredBranch
							}
							isInvalid={getInvalid(
								'preferredBranch',
								errors,
								touched,
							)}
							label="Preferred Branch *"
						>
							<BranchDropdown
								cityId={get(values, 'cityMunId', null)}
								deliveryAddress={coordinates}
								isInvalid={getInvalid(
									'preferredBranch',
									errors,
									touched,
								)}
								name="preferredBranch"
								onChange={setBranch}
								pickup={values.pickup}
								value={values.preferredBranch}
								vendor={values.vendor}
							/>
						</EuiFormRow>

						<EuiFormRow
							error={
								touched.paymentMethod && errors.paymentMethod
							}
							isInvalid={getInvalid(
								'paymentMethod',
								errors,
								touched,
							)}
							label="Preferred Payment *"
						>
							<PaymentMethodDropdown
								data-testid="paymentMethod"
								id="paymentMethod"
								name="paymentMethod"
								onChange={formikBag.handleChange}
								value={values.paymentMethod}
							/>
						</EuiFormRow>
						<EuiSpacer />
						<EuiHorizontalRule margin="s" />
						<EuiFormRow>
							<EuiFlexGroup direction="column">
								<EuiFlexItem>
									<EuiFlexGroup
										direction="column"
										gutterSize="s"
									>
										<EuiFlexItem>
											<EuiCheckbox
												checked={
													acceptTermsAndAgreement
												}
												id="agreement"
												label={
													<span
														style={{
															fontSize: 12,
														}}
													>
														I confirm that I have
														read and agree to the{' '}
														<EuiLink
															onClick={toggleTerm}
														>
															Terms & Conditions
														</EuiLink>{' '}
														and{' '}
														<EuiLink
															onClick={
																togglePrivacy
															}
														>
															Privacy Policy
														</EuiLink>
													</span>
												}
												name="agreement"
												onChange={
													handleAcceptTermsAndAgreement
												}
											/>
										</EuiFlexItem>
									</EuiFlexGroup>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFormRow>
					</>
				)}

				<EuiFlexGroup
					direction="row"
					gutterSize="s"
					justifyContent="spaceBetween"
					responsive={false}
				>
					{step === 1 ? (
						<EuiFlexItem grow={false}>
							<EuiButton onClick={() => history.push('/')}>
								Back
							</EuiButton>
						</EuiFlexItem>
					) : (
						<EuiFlexItem grow={false}>
							<EuiButton disabled={step === 1} onClick={onPrev}>
								Prev
							</EuiButton>
						</EuiFlexItem>
					)}

					{step < steps.length && (
						<EuiFlexItem grow={false}>
							<EuiButton
								disabled={disabled || steps.length === step}
								onClick={onNext}
							>
								Next
							</EuiButton>
						</EuiFlexItem>
					)}

					{steps.length === step && (
						<EuiFlexItem grow={false}>
							<EuiButton
								disabled={
									!acceptTermsAndAgreement ||
									disabled ||
									isSubmitting ||
									formikBag.isInvalid
								}
								isLoading={isSubmitting}
								onClick={handleSubmit}
							>
								Send to Pharmacy
							</EuiButton>
						</EuiFlexItem>
					)}
				</EuiFlexGroup>
			</EuiForm>
			<TermsModal isOpen={termOpen} onClose={toggleTerm} />
			<PrivacyModal isOpen={privacyOpen} onClose={togglePrivacy} />
		</>
	);
};

PatientOrderForm.propTypes = {
	disabled: PropTypes.bool.isRequired,
	formikBag: PropTypes.instanceOf(Object).isRequired,
	isSameCustomerDeets: PropTypes.bool.isRequired,
	isSubmitting: PropTypes.bool.isRequired,
	isSubmittingNewOrder: PropTypes.bool.isRequired,
	mutateRenewRx: PropTypes.func.isRequired,
	onNext: PropTypes.func.isRequired,
	onPrev: PropTypes.func.isRequired,
	patientInfo: PropTypes.instanceOf(Object).isRequired,
	setAttachments: PropTypes.func.isRequired,
	setIsSameCustomerDeets: PropTypes.func.isRequired,
	step: PropTypes.number.isRequired,
	steps: PropTypes.instanceOf('array').isRequired,
};

PatientOrderForm.defaultProps = {};

export default PatientOrderForm;
