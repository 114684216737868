import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { EuiHealth } from '@elastic/eui';

import PropTypes from 'prop-types';
import { CALL_CENTER, PHARMACIST } from 'components/roles';

const RxStatus = ({ assigned, datetime, role, status }) => {
	const { t } = useTranslation();
	let statusValue = status ? status.toLowerCase() : '';
	let color;

	switch (statusValue) {
		case 'new':
			color = '#89d8d9';
			break;

		case 'received':
			color = 'success';
			break;

		case 'processing':
			color = 'subdued';
			break;

		case 'partial':
			color = '#6699cc';
			break;

		case 'readyForPickup':
			color = '#008000';
			break;

		case 'served':
			color = 'primary';
			break;

		case 'cancelled':
			color = 'red';
			break;

		default:
			color = 'disabled';
	}

	if (role === CALL_CENTER.key) {
		const rxAge = moment.duration(moment().diff(datetime)).asMinutes();

		if (statusValue === 'received') {
			if (rxAge > 15 || !datetime) {
				color = 'red';
			}

			if (rxAge <= 15) {
				color = 'green';
			}

			if (rxAge <= 5) {
				color = 'gray';
			}
		}

		return (
			<EuiHealth color={color}>
				<span
					style={{ color: statusValue === 'received' ? color : '' }}
				>
					{status
						? t(`prescriptions.status-${role}.${statusValue}`)
						: 'No Status'}
				</span>
			</EuiHealth>
		);
	}

	if (role === PHARMACIST.key) {
		// eslint-disable-next-line default-case
		switch (status) {
			case 'received':
				statusValue = assigned ? status : 'open';
				color = assigned ? 'warning' : 'disabled';
				break;
		}

		return (
			<EuiHealth color={color}>
				{status
					? t(`prescriptions.status-${role}.${statusValue}`)
					: 'No Status'}
			</EuiHealth>
		);
	}

	return (
		<EuiHealth color={color}>
			{status ? t(`prescriptions.status.${statusValue}`) : 'No Status'}
		</EuiHealth>
	);
};

RxStatus.propTypes = {
	assigned: PropTypes.bool,
	datetime: PropTypes.string,
	role: PropTypes.string,
	status: PropTypes.oneOf([
		'new',
		'received',
		'processing',
		'partial',
		'readyForPickup',
		'served',
		'cancelled',
	]),
};

RxStatus.defaultProps = {
	assigned: false,
	datetime: null,
	role: null,
	status: null,
};

export default RxStatus;
