import React, { useEffect, useState } from 'react';

import {
	EuiBadge,
	EuiBadgeGroup,
	EuiButton,
	EuiLink,
	EuiLoadingSpinner,
	EuiText,
	EuiShowFor,
	EuiButtonEmpty,
} from '@elastic/eui';
import { FaPhoneSquare } from 'react-icons/fa';

import PropTypes from 'prop-types';

import { getContactDetailsByIds } from './global.fetch';

const ContactDetails = ({ hideEmails, ids, plain, pressToCall, showType }) => {
	const [details, setDetails] = useState([]);
	const [isLoading, setLoading] = useState(false);

	const getColor = (type) => {
		switch (type) {
			case 'email':
				return '#00805e';

			case 'phone':
				return '#d76e10';

			case 'mobile':
				return '#c0e0ff';

			default:
				return '#d2d2d2';
		}
	};

	const getContactDisplayIcon = (href) => (
		<>
			<EuiShowFor sizes={['xl', 'l', 'm']}>
				<EuiButton href={href} size="s" style={{ marginLeft: 5 }}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<FaPhoneSquare
							color="#117aac"
							style={{ marginRight: 5 }}
						/>
						Call Now
					</div>
				</EuiButton>
			</EuiShowFor>
			<EuiShowFor sizes={['xs', 's']}>
				<EuiButtonEmpty href={href} size="xs">
					<FaPhoneSquare color="#117aac" style={{ marginRight: 5 }} />
				</EuiButtonEmpty>
			</EuiShowFor>
		</>
	);

	useEffect(() => {
		async function getContactDetails() {
			setLoading(true);
			let contactTypes;
			if (hideEmails) {
				contactTypes = ['mobile', 'phone'];
			}

			let data = null;
			if (ids?.length && typeof ids[0] === 'string') {
				data = await getContactDetailsByIds(ids, contactTypes).data;
			} else if (ids?.length && typeof ids[0] === 'object') {
				data = ids;
			}

			if (showType && data) {
				data = data.filter((d) => {
					if (showType) {
						return d.type === showType;
					}

					return d;
				});
			}

			setDetails(data);

			setLoading(false);
		}

		getContactDetails();
	}, [ids]);

	if (isLoading) {
		return <EuiLoadingSpinner />;
	}

	if (!isLoading && (!details || !details.length)) {
		return (
			<EuiText color="subdued" size="s">
				No Contact Details Provided.
			</EuiText>
		);
	}

	if (!isLoading && plain) {
		return (
			<>
				{details.map((d) => {
					if (!d) return false;
					const href = `${d.type === 'email' ? 'mailto:' : 'tel:'}${
						d.value
					}`;
					return (
						<div
							key={`d-${d.id}`}
							style={{ verticalAlign: 'middle' }}
						>
							<EuiLink
								color={getColor(d.type)}
								href={pressToCall ? href : null}
							>
								{d.value}
							</EuiLink>
							{pressToCall
								? d.type !== 'email' &&
								  getContactDisplayIcon(href)
								: null}
						</div>
					);
				})}
			</>
		);
	}

	return (
		<EuiBadgeGroup gutterSize="xs">
			{details.map((deet) => {
				if (!deet) return false;
				return (
					<EuiBadge
						key={`deet-${deet.id}`}
						color={getColor(deet.type)}
					>
						{deet.value}
					</EuiBadge>
				);
			})}
		</EuiBadgeGroup>
	);
};

ContactDetails.propTypes = {
	hideEmails: PropTypes.bool,
	ids: PropTypes.arrayOf(PropTypes.string).isRequired,
	plain: PropTypes.bool,
	pressToCall: PropTypes.bool,
	showType: PropTypes.oneOf(['mobile', 'phone', 'email']),
};

ContactDetails.defaultProps = {
	hideEmails: false,
	plain: false,
	pressToCall: true,
	showType: null,
};

export default ContactDetails;
