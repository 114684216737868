import * as yup from 'yup';

const PatientOrderFormValidationSchema = yup.object().shape({
	fulfillment: yup.string().required('Prescription Fulfillment is required'),
	paymentMethod: yup.string().required('Preferred Payment is required'),
	patientNotes: yup.string(),
	pickupType: yup.string().required('Fullfillment Type is required'),
	firstName: yup.string().required('First Name is required'),
	lastName: yup.string().required('Last Name is required'),
	email: yup.string(),
	mobile: yup.string().required('Contact Number is required'),
	receiverFirstName: yup.string().when('pickupType', {
		is: (pickupType) => pickupType === 'delivery' || pickupType === 'grab',
		then: yup.string().required('Receiver First Name is required'),
	}),
	receiverLastName: yup.string().when('pickupType', {
		is: (pickupType) => pickupType === 'delivery' || pickupType === 'grab',
		then: yup.string().required('Receiver Last Name is required'),
	}),
	receiverEmail: yup.string().when('pickupType', {
		is: (pickupType) => pickupType === 'delivery' || pickupType === 'grab',
		then: yup.string().required('Receiver Email Address is required'),
	}),
	receiverContactNumber: yup.string().when('pickupType', {
		is: (pickupType) => pickupType === 'delivery' || pickupType === 'grab',
		then: yup.string().required('Receiver Contact Number is required'),
	}),
	cityId: yup
		.string()
		.when('pickupType', {
			is: (pickupType) =>
				pickupType === 'delivery' || pickupType === 'grab',
			then: yup.string().required('City / Municipality is required'),
		})
		.nullable(),
	brgyId: yup
		.string()
		.when('pickupType', {
			is: (pickupType) =>
				pickupType === 'delivery' || pickupType === 'grab',
			then: yup.string().required('Barangay is required'),
		})
		.nullable(),
	line1: yup.string().when('pickupType', {
		is: (pickupType) => pickupType === 'delivery' || pickupType === 'grab',
		then: yup.string().required('Address Line 1 is required'),
	}),
	line2: yup.string(),
	cityMunId: yup.string().required('City is required').nullable(),
	vendor: yup.string().required('Vendor is required').nullable(),
	preferredBranch: yup
		.string()
		.required('Preferred Branch is required')
		.nullable(),
	attachments: yup
		.array()
		.of(yup.string())
		.max(10, 'You are only allowed to upload a maximum of 10 files'),
	prescriptionItems: yup.array().of(yup.string()),
});

export default PatientOrderFormValidationSchema;
