import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import {
	EuiSpacer,
	EuiTitle,
	EuiModal,
	EuiOverlayMask,
	EuiModalHeader,
	EuiModalHeaderTitle,
	EuiModalBody,
	EuiDescriptionList,
	EuiModalFooter,
	EuiButtonEmpty,
	EuiButton,
	EuiShowFor,
	EuiText,
	EuiFieldNumber,
} from '@elastic/eui';

import PropTypes from 'prop-types';
import { get, isNumber } from 'lodash';

import { addToast } from 'modules/toasts/toasts.actions';
import ConfirmationModal from 'components/ConfirmationModal';

import { createOrder } from '../../orders/orders.fetch';
import { mapRxDataToOrderData } from '../prescriptions.fetch';

const ConvertPrescriptionModal = ({
	confirmationOnly,
	onClose,
	rxData,
	visible,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const [values, setRxValues] = useState(mapRxDataToOrderData());
	const [showConfirmModal, setShowConfirmModal] = useState(false);

	const toggleConfirmationModal = () => setShowConfirmModal((isOn) => !isOn);

	useEffect(() => {
		function getRxValues() {
			setRxValues(mapRxDataToOrderData(rxData));
		}
		if (rxData) getRxValues();
	}, [rxData]);

	const { isLoading, mutate } = useMutation(createOrder, {
		onSuccess: (data) => {
			history.push(`/orders/${data.id}`);

			dispatch(addToast('Success', 'Order created', 'success', 'check'));
		},
		onError: (err) => {
			dispatch(
				addToast(
					'Error',
					err.message || 'Something went wrong',
					'danger',
					'help',
				),
			);
		},
		onSettled: onClose,
	});

	const handleSubmit = async () => {
		mutate(values);
		toggleConfirmationModal();
	};

	const prescriptionItemsList = values.lineItems.map((lineItem) => {
		const { itemName, quantity } = lineItem;

		let title = `${itemName} - ${lineItem.brand}`;

		if (lineItem.brand) {
			title += ` - ${lineItem.brand}`;
		}

		return {
			title,
			description: `
				${lineItem.formulation || ''} 
				x${quantity}`,
		};
	});

	const handleChangeAmount = (e) => {
		const value = parseFloat(e.target.value, 10);
		if (isNumber(value)) {
			setRxValues((prevValues) => ({
				...prevValues,
				amount: value,
			}));
		}
	};

	if ([].length && get(values, 'vendor.totalOnly', true)) {
		prescriptionItemsList.push({
			title: 'Total Amount',
			description: (
				<EuiFieldNumber
					fullWidth
					id="amount"
					isInvalid={!/^[0-9]*$/.test(values.amount)}
					min={0}
					name="amount"
					onChange={handleChangeAmount}
					pattern="^[0-9]*$"
					value={values.amount}
				/>
			),
		});
	}

	const descriptionList = [
		{
			title: 'Prescription Code',
			description: values.code,
		},
		{
			title: 'Patient Name',
			description: `${values.patient.firstName} ${values.patient.lastName}`,
		},
	];

	if (values.doctor) {
		descriptionList.push({
			title: 'Doctor',
			description: `${values.doctor.firstName} ${values.doctor.lastName}`,
		});
	}

	if (values.pickup) {
		descriptionList.push({
			title: 'Pick Up',
			description: values.branch ? values.branch.name : '',
		});
	}

	if (!values.pickup) {
		descriptionList.push({
			title: 'Shipping Address',
			description: values.deliveryAddress?.fullAddress || '',
		});
	}

	const confirmCreateOrderModal = () => {
		let message =
			'This will create an order based on the encoded prescription items';

		if (!values?.vendor?.requireRxEncoding) {
			message = 'This will create an order based on the prescription';
		}

		return (
			<ConfirmationModal
				className="convert-rx-confirm"
				isVisible={confirmationOnly || showConfirmModal}
				message={message}
				onClose={() => {
					toggleConfirmationModal();
					onClose();
				}}
				onConfirm={handleSubmit}
				title="Confirm Create Order"
			/>
		);
	};

	const renderBody = () => {
		if (values?.vendor?.requireRxEncoding) {
			return (
				<>
					<EuiDescriptionList
						listItems={descriptionList}
						style={{ maxWidth: '600px' }}
						type="column"
					/>

					<EuiSpacer size="m" />
					<EuiTitle size="s">
						<h3>Prescription Items</h3>
					</EuiTitle>
					<EuiSpacer size="s" />
					<EuiDescriptionList
						listItems={prescriptionItemsList}
						style={{ maxWidth: '600px' }}
						type="responsiveColumn"
					/>
				</>
			);
		}

		return (
			<EuiText textAlign="center">
				<EuiTitle>
					<h3>
						Disabled for{' '}
						{get(values, 'vendor.name', 'this Pharmacy')}
					</h3>
				</EuiTitle>
				<EuiText size="m">Please proceed to create order</EuiText>
			</EuiText>
		);
	};

	if (!visible) return null;

	return !confirmationOnly ? (
		<EuiOverlayMask>
			<EuiModal onClose={onClose}>
				<EuiModalHeader>
					<EuiModalHeaderTitle>
						Encoded Rx Summary
					</EuiModalHeaderTitle>
				</EuiModalHeader>
				<EuiModalBody>{renderBody()}</EuiModalBody>
				<EuiModalFooter>
					<EuiButtonEmpty color="danger" onClick={onClose}>
						{t('general.cancel')}
					</EuiButtonEmpty>

					<EuiButton
						color="primary"
						fill
						isLoading={isLoading}
						onClick={toggleConfirmationModal}
					>
						<EuiShowFor sizes={['xl', 'l', 'm']}>
							Prepare Order
						</EuiShowFor>

						<EuiShowFor sizes={['s', 'xs']}>Next</EuiShowFor>
					</EuiButton>
				</EuiModalFooter>
			</EuiModal>
			{confirmCreateOrderModal()}
		</EuiOverlayMask>
	) : (
		confirmCreateOrderModal()
	);
};

ConvertPrescriptionModal.propTypes = {
	confirmationOnly: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	// eslint-disable-next-line
	rxData: PropTypes.object.isRequired,
	visible: PropTypes.bool,
};

ConvertPrescriptionModal.defaultProps = {
	confirmationOnly: false,
	visible: false,
};

export default ConvertPrescriptionModal;
