import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	EuiOverlayMask,
	EuiModal,
	EuiModalBody,
	EuiButton,
	EuiFlexGroup,
	EuiFlexItem,
	EuiText,
	EuiImage,
} from '@elastic/eui';
import Logo from 'assets/images/logo_medium.png';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import { registrationRxReference } from 'modules/auth/auth.actions';

const LoginPrompt = () => {
	const history = useHistory();
	const [visible, setVisible] = useState(true);
	const dispatch = useDispatch();

	const redirectToLogin = () => {
		const { location } = history;

		const { search } = location;

		const query = qs.parse(search);

		dispatch(registrationRxReference(query?.['?code'] ?? null));

		history.push({
			pathname: '/login',
			state: {
				transactionData: {
					code: query?.['?code'],
				},
			},
		});
	};

	const onClose = () => {
		setVisible(false);
	};

	if (!visible) return null;

	return (
		<EuiOverlayMask>
			<EuiModal className="fullScreenModal" onClose={onClose}>
				<EuiModalBody
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<EuiFlexGroup direction="column">
						<EuiFlexItem>
							<EuiImage alt="ERX MedAlert" size="m" url={Logo} />
						</EuiFlexItem>
						<EuiFlexItem>
							<EuiText
								style={{
									fontWeight: '700',
									textAlign: 'center',
								}}
							>
								Welcome to MedAlert eRx+
								<br />
								your medicine ordering and delivery portal
							</EuiText>
						</EuiFlexItem>
						<EuiFlexItem>
							<EuiButton fill onClick={redirectToLogin}>
								Login / Register
							</EuiButton>
						</EuiFlexItem>
						<EuiFlexItem>
							<EuiButton onClick={onClose}>
								Continue as Guest
							</EuiButton>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiModalBody>
			</EuiModal>
		</EuiOverlayMask>
	);
};

export default LoginPrompt;
