import React from 'react';
import { useQueries } from 'react-query';
import { useSelector } from 'react-redux';
import {
	EuiStat,
	EuiPanel,
	EuiFlexGroup,
	EuiFlexItem,
	EuiTitle,
	EuiHorizontalRule,
} from '@elastic/eui';

import moment from 'moment';
import _ from 'lodash';

import { ROLE_PATH } from 'constants/protect';
import { ADMIN, CALL_CENTER, PHARMACIST, SUPER_ADMIN } from 'components/roles';
import protectHOC from 'components/protectHOC';

import { countErx, countOrders } from 'modules/dashboard/dashboard.fetch';
import { Link } from 'react-router-dom';

const today = moment().format('L');

const Statistics = () => {
	const { branch, vendor } = useSelector((state) => ({
		vendor: _.get(state, 'auth.user.vendor'),
		branch: state.auth?.user?.details?.user?.branch,
	}));

	const newOrdersStatsEnabled = _.get(vendor, 'orderingEnabled');

	const interval = 2 * 60 * 1000; // every 5 minutes
	const results = useQueries([
		{
			queryKey: ['erx-new', { status: 'received', createdAt_gte: today }],
			queryFn: countErx,
			refetchInterval: interval,
		},
		{
			queryKey: [
				'orders-open',
				{
					status: ['received'],
					createdAt_gte: today,
					branch,
				},
			],
			queryFn: countOrders,
			refetchInterval: interval,
		},
		{
			queryKey: [
				'orders-released',
				{
					status: ['released'],
					createdAt_gte: today,
					branch,
				},
			],
			queryFn: countOrders,
			refetchInterval: interval,
		},
		{
			queryKey: ['erx-total', { branch }],
			queryFn: countErx,
			refetchInterval: interval,
		},
		{
			queryKey: ['orders-total', { branch }],
			queryFn: countOrders,
			refetchInterval: interval,
		},
	]);

	const [newRx, newOrders, releasedOrders, totalRx, totalOrders] = results;

	return (
		<div>
			<EuiTitle>
				<h1>Summary</h1>
			</EuiTitle>

			<EuiHorizontalRule margin="s" />
			<EuiFlexGroup>
				<EuiFlexItem className="eustat-card">
					<EuiPanel>
						<Link
							to={{
								pathname: '/prescriptions',
								state: {
									status: 'received',
									date: today,
								},
							}}
						>
							<EuiStat
								description="New eRx"
								isLoading={newRx.isLoading}
								reverse
								textAlign="center"
								title={newRx.data}
							/>
						</Link>
					</EuiPanel>
				</EuiFlexItem>
				{newOrdersStatsEnabled && (
					<>
						<EuiFlexItem className="eustat-card">
							<EuiPanel>
								<Link
									to={{
										pathname: '/orders',
										state: {
											status: 'received',
											date: today,
										},
									}}
								>
									<EuiStat
										description="New Orders"
										isLoading={newOrders.isLoading}
										reverse
										textAlign="center"
										title={newOrders.data}
									/>
								</Link>
							</EuiPanel>
						</EuiFlexItem>

						<EuiFlexItem className="eustat-card">
							<EuiPanel>
								<Link
									to={{
										pathname: '/orders',
										state: {
											status: 'released',
										},
									}}
								>
									<EuiStat
										description="Released Orders"
										isLoading={releasedOrders.isLoading}
										reverse
										textAlign="center"
										title={releasedOrders.data}
									/>
								</Link>
							</EuiPanel>
						</EuiFlexItem>
					</>
				)}
				<EuiFlexItem className="eustat-card">
					<EuiPanel>
						<Link to="prescriptions">
							<EuiStat
								description="Total eRx"
								isLoading={totalRx.isLoading}
								reverse
								textAlign="center"
								title={totalRx.data}
							/>
						</Link>
					</EuiPanel>
				</EuiFlexItem>
				{newOrdersStatsEnabled && (
					<EuiFlexItem className="eustat-card">
						<EuiPanel>
							<Link to="orders">
								<EuiStat
									description="Total Orders"
									isLoading={totalOrders.isLoading}
									reverse
									textAlign="center"
									title={totalOrders.data}
								/>
							</Link>
						</EuiPanel>
					</EuiFlexItem>
				)}
			</EuiFlexGroup>
		</div>
	);
};

export default protectHOC(
	[SUPER_ADMIN.key, ADMIN.key, PHARMACIST.key, CALL_CENTER.key],
	ROLE_PATH,
)(Statistics);
