import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import Page from 'components/Page';
import PatientHeader from 'modules/patients/PatientHeader';

export default function PatientDashboard() {
	const { REACT_APP_ADS_URL } = process.env;
	const { user } = useSelector((state) => ({
		user: get(state, 'auth.user'),
	}));

	let adsUrl = `${REACT_APP_ADS_URL}/advertisements`;
	const tags = [
		'patient',
		user?.address?.city?.toLowerCase().replace(' ', '-'),
	];
	if (tags?.length) {
		adsUrl = `${adsUrl}?tags=${tags.map((tag) => tag).join(',')}`;
	}

	return (
		<Page>
			<PatientHeader user={user} />
			<iframe
				id="iframe"
				src={adsUrl}
				style={{
					height: 'calc(100vh - 129px)',
					width: '100%',
				}}
				title="Advertisement"
			/>
		</Page>
	);
}
