import initialState from '../../store/initialState';
import {
	LOGIN_SUCCESS,
	LOGOUT_SUCCESS,
	UPDATE_USER_DETAILS,
	ACCEPT_SOFTWARE_CONTRACT,
	REGISTRATION_RX_REFERENCE,
} from '../../constants/actionTypes';

export default (state = initialState.auth, action = null) => {
	switch (action.type) {
		case LOGIN_SUCCESS:
			return {
				...action.data,
				isLoggedIn: true,
			};

		case LOGOUT_SUCCESS:
			return initialState.auth;

		case UPDATE_USER_DETAILS:
			return {
				...state,
				user: action.data,
			};

		case ACCEPT_SOFTWARE_CONTRACT:
			return {
				...state,
				user: {
					...state.user,
					details: {
						...state.user.details,
						erxContractAccepted: true,
					},
				},
			};

		case REGISTRATION_RX_REFERENCE:
			return {
				...state,
				registrationRxReference: action.data,
			};

		default:
			return state;
	}
};
