/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
	EuiFlexGroup,
	EuiButton,
	EuiFlexItem,
	EuiButtonEmpty,
	EuiForm,
	EuiFormRow,
	EuiRadioGroup,
	EuiTitle,
	EuiFilePicker,
	EuiHorizontalRule,
	EuiCheckbox,
	EuiShowFor,
	EuiSpacer,
	EuiButtonIcon,
} from '@elastic/eui';
import { useFormik } from 'formik';
import _, { get } from 'lodash';

import { ADD_PRESCRIPTION } from 'constants/prescription';
import Page from 'components/Page';
import PatientSearch from 'components/forms/PatientSearch';
import PersonalInformationForm from 'components/PersonalInfomationForm';
import AddressForm from 'components/AddressForm';

import ConfirmationModal from 'components/ConfirmationModal';
import PrescriptionItemForm from './components/PrescriptionItemForm';
import { addToast } from '../toasts/toasts.actions';
import {
	createRxSuccess,
	createRxDashboardSuccess,
} from './prescriptions.actions';
import {
	createPrescription,
	AddPrescriptionSchema,
	rxModel,
} from './prescriptions.fetch';
import { openSendRxModal } from './sendRxModal.actions';
import { updatePatient } from '../patients/patients.fetch';

const AddEncodedPrescription = () => {
	const { auth } = useSelector((state) => ({ auth: state.auth }));
	const history = useHistory();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [attachments, setAttachments] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [patient, setPatient] = useState(null);
	const [hasChanges, setHasChanges] = useState(false);
	const [
		isConfirmCancelChangesModalVisible,
		setIsConfirmCancelChangesModalVisible,
	] = useState(false);

	const formikBag = useFormik({
		initialValues: {
			...rxModel,
		},
		validationSchema: AddPrescriptionSchema,
		validateOnBlur: true,
		enableReinitialize: true,
		onSubmit: async (data, { resetForm: reset }) => {
			try {
				setIsLoading(true);
				if (data.patient) {
					await updatePatient(data.patient, {
						contactDetails: data.contactDetails,
					});
				}

				const { data: newPrescription } = await createPrescription({
					attachments,
					...data,
				});

				if (!newPrescription) {
					throw new Error('Failed to create prescription');
				}

				dispatch(
					addToast(
						'Success',
						`Successfully added prescription with code: ${newPrescription.code}`,
						'success',
					),
				);
				dispatch(createRxSuccess(newPrescription));
				dispatch(createRxDashboardSuccess(newPrescription));
				setAttachments([]);
				reset();
				history.push('/prescriptions');
				dispatch(openSendRxModal(newPrescription.id));
			} catch (err) {
				const errorResponse = ((err || {}).response || {}).data || null;
				dispatch(
					addToast('Error', errorResponse.message, 'danger', 'help'),
				);
			} finally {
				setIsLoading(false);
			}
		},
	});

	const {
		errors,
		handleSubmit,
		initialValues,
		resetForm,
		setFieldValue,
		touched,
		values,
	} = formikBag;

	useEffect(() => {
		setHasChanges(!_.isEqual(initialValues, values));
	}, [initialValues, values]);

	useEffect(() => {
		setPatient(null);
		setFieldValue('patient', '');
	}, [values.addNewPatient]);

	useEffect(() => {
		if (touched.prescriptionItems && !!errors.prescriptionItems) {
			dispatch(
				addToast('Error', errors.prescriptionItems, 'danger', 'help'),
			);
		}
	}, [touched.prescriptionItems, errors.prescriptionItems]);

	useEffect(() => {
		if (values.generate) {
			setFieldValue('attachments', []);
		}
	}, [values.generate]);

	useEffect(() => {
		const setPatientData = () => {
			setFieldValue('firstName', _.get(patient, 'firstName', ''));
			setFieldValue('middleName', _.get(patient, 'middleName', ''));
			setFieldValue('lastName', _.get(patient, 'lastName', ''));
			setFieldValue('birthdate', _.get(patient, 'birthdate', null));
			setFieldValue(
				'contactDetails',
				_.get(patient, 'contactDetails', []),
			);
			setFieldValue('line1', _.get(patient, 'address.line1', ''));
			setFieldValue('line2', _.get(patient, 'address.line2', ''));
			setFieldValue('cityId', _.get(patient, 'address.cityId', ''));
			setFieldValue('brgyId', _.get(patient, 'address.brgyId', ''));
		};
		setPatientData();
	}, [patient]);

	const handleFilePicker = (event) => {
		const { length, ...fileItems } = event;
		if (!length) return setAttachments([]);

		const files = Object.keys(fileItems).map((key) => event[key]);
		setFieldValue(
			'attachments',
			files.map((file) => file.name),
		);

		return setAttachments(files);
	};

	const handleConfirmChanges = () => {
		resetForm({ values: initialValues });
		history.push('/prescriptions');
		setIsConfirmCancelChangesModalVisible(false);
	};

	const handleCancelChanges = () => {
		setIsConfirmCancelChangesModalVisible(false);
	};

	const patientOptions = [
		{
			id: 'new',
			label: 'New Patient',
		},
		{
			id: 'existing',
			label: 'Existing Patient',
		},
	];

	const rxTypes = [
		{
			value: 'rx',
			text: 'RX',
		},
	];

	if (_.get(auth, 'user.details.s2Validated', false)) {
		rxTypes.push({
			value: 's2',
			text: 'S2',
		});
	}

	const onPatientSelect = (selected) => {
		if (selected) {
			setPatient(selected);
			return setFieldValue('patient', selected.value);
		}

		setPatient(null);
		return setFieldValue('patient', '');
	};

	const handleNewPatientAddress = () => {
		setFieldValue('newPatientAddress', !values.newPatientAddress);
	};

	const handleCancel = () => {
		if (hasChanges) {
			setIsConfirmCancelChangesModalVisible(true);
		} else {
			history.push('/prescriptions');
		}
	};

	const handleRxItemsSubmit = (data) => {
		setFieldValue('prescriptionItems', data);
	};

	const handleGenerate = () => {
		setFieldValue('generate', !values.generate);
	};

	const sidebar = (
		<>
			<EuiForm>
				<EuiFormRow>
					<EuiRadioGroup
						className="radio-group-row"
						id="addNewPatient"
						idSelected={values.addNewPatient ? 'new' : 'existing'}
						name="addNewPatient"
						onChange={() => {
							if (
								values.addNewPatient !== !values.addNewPatient
							) {
								setFieldValue(
									'addNewPatient',
									!values.addNewPatient,
								);
							}
						}}
						options={patientOptions}
					/>
				</EuiFormRow>
				<EuiFormRow>
					<EuiTitle size="xs">
						<h3>Patient Information</h3>
					</EuiTitle>
				</EuiFormRow>
				<EuiHorizontalRule margin="xs" />
				{!values.addNewPatient && (
					<EuiFormRow
						error={touched.patient && errors.patient}
						fullWidth
						helpText="Enter 3 or more characters to search"
						isInvalid={touched.patient && !!errors.patient}
						label="Patient"
					>
						<PatientSearch
							isInvalid={touched.patient && !!errors.patient}
							onChange={onPatientSelect}
							selected={patient}
						/>
					</EuiFormRow>
				)}
				{(values.addNewPatient || patient) && (
					<EuiFormRow fullWidth>
						<PersonalInformationForm
							editContacts
							formikBag={formikBag}
							readOnly={!values.addNewPatient}
						/>
					</EuiFormRow>
				)}
				{(values.addNewPatient || patient) && (
					<>
						<EuiFormRow>
							<EuiFlexGroup
								alignItems="center"
								gutterSize="s"
								responsive={false}
							>
								<EuiFlexItem grow={false}>
									<EuiTitle size="xs">
										<h3>Address</h3>
									</EuiTitle>
								</EuiFlexItem>
								<EuiFlexItem grow={false}>
									<EuiButtonIcon
										aria-label="Add Contact Detail Entry"
										color="primary"
										iconType={
											values.newPatientAddress
												? 'minusInCircle'
												: 'plusInCircle'
										}
										onClick={handleNewPatientAddress}
									/>
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFormRow>
						<EuiHorizontalRule margin="m" />
						{values.newPatientAddress && (
							<EuiFormRow fullWidth>
								<AddressForm
									formikBag={formikBag}
									readOnly={
										!values.addNewPatient &&
										get(values, 'address.id')
									}
								/>
							</EuiFormRow>
						)}
					</>
				)}
			</EuiForm>
		</>
	);

	return (
		<Page
			headerRight={
				<EuiFlexGroup
					direction="row"
					gutterSize="m"
					justifyContent="spaceBetween"
					responsive={false}
				>
					<EuiShowFor sizes={['xl', 'l', 'm']}>
						<EuiFlexItem>
							<EuiButtonEmpty
								color="danger"
								iconType="crossInACircleFilled"
								onClick={handleCancel}
							>
								{t('general.cancel')}
							</EuiButtonEmpty>
						</EuiFlexItem>
						<EuiFlexItem>
							<EuiButton
								disabled={isLoading}
								fill
								iconType="plusInCircleFilled"
								loading={isLoading}
								onClick={handleSubmit}
							>
								Submit
							</EuiButton>
						</EuiFlexItem>
					</EuiShowFor>
					<EuiShowFor sizes={['s', 'xs']}>
						<EuiFlexItem>
							<EuiButtonEmpty
								color="danger"
								fullWidth
								onClick={handleCancel}
								size="xs"
							>
								{t('general.cancel')}
							</EuiButtonEmpty>
						</EuiFlexItem>
						<EuiFlexItem>
							<EuiButtonEmpty
								disabled={isLoading}
								fullWidth
								loading={isLoading}
								onClick={handleSubmit}
								size="xs"
							>
								Submit
							</EuiButtonEmpty>
						</EuiFlexItem>
					</EuiShowFor>
				</EuiFlexGroup>
			}
			sidebar={sidebar}
			stickyHeader
			title="New Prescription"
		>
			<EuiFormRow>
				<EuiTitle size="xs">
					<h3>Attachments</h3>
				</EuiTitle>
			</EuiFormRow>
			<EuiHorizontalRule margin="s" />
			<EuiFormRow>
				<EuiCheckbox
					checked={values.generate}
					id="generate-erx"
					label="Generate Electronic Prescription"
					name="generate"
					onChange={handleGenerate}
				/>
			</EuiFormRow>
			{!values.generate ? (
				<EuiFormRow
					error={touched.attachments && errors.attachments}
					fullWidth
					isInvalid={touched.attachments && !!errors.attachments}
				>
					<EuiFilePicker
						fullWidth
						isInvalid={touched.attachments && !!errors.attachments}
						multiple
						onChange={handleFilePicker}
					/>
				</EuiFormRow>
			) : null}
			<EuiSpacer />
			<PrescriptionItemForm
				formType={ADD_PRESCRIPTION}
				id="prescriptionItems"
				isEditable
				isVisible
				name="prescriptonItems"
				onSubmit={handleRxItemsSubmit}
				prescriptionItems={values.prescriptionItems}
			/>
			<ConfirmationModal
				isVisible={isConfirmCancelChangesModalVisible}
				message="You Will Lose All Unsaved Progress"
				onClose={handleCancelChanges}
				onConfirm={handleConfirmChanges}
				title="Confirm Cancel"
			/>
		</Page>
	);
};

export default AddEncodedPrescription;
