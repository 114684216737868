import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Helmet } from 'react-helmet-async';
import {
	EuiFlexItem,
	EuiFormRow,
	EuiFieldText,
	EuiFlexGroup,
	EuiText,
	EuiButton,
	EuiSpacer,
	EuiImage,
	EuiCallOut,
	EuiForm,
	EuiDatePicker,
	EuiPageSection,
} from '@elastic/eui';
import { FaFilePrescription } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { formatDate } from 'utils/helpers';
import Logo from 'assets/images/logo_medium.png';
import { getRxByCode } from '../validate-prescription.fetch';
import LoginPrompt from './LoginPrompt';

const ValidatePrescriptionFormSchema = yup.object().shape({
	code: yup.string().required('Code is required'),
	birthdate: yup.string().required('Birthdate is required'),
});

const ValidatePrescriptionForm = ({ code, onValidate }) => {
	const [birthdate, setBirthdate] = useState(null);
	const [error, setError] = useState(null);

	const { isError, isLoading, mutate } = useMutation(getRxByCode, {
		onSuccess: onValidate,
		onError: (err) => {
			let message = null;

			if (get(err, 'response.data.message', false)) {
				message = get(err, 'response.data.message');
			} else if (get(err, 'message')) {
				message = err.message;
			} else {
				message = 'Something went wrong';
			}

			setError(message);
		},
	});
	const formikBag = useFormik({
		initialValues: {
			code,
			birthdate: null,
		},
		validationSchema: ValidatePrescriptionFormSchema,
		validateOnBlur: true,
		enableReinitialize: true,
		onSubmit: mutate,
	});

	const {
		errors,
		handleChange,
		handleSubmit,
		setFieldValue,
		touched,
		values,
	} = formikBag;

	useEffect(() => {
		if (birthdate) {
			setFieldValue('birthdate', formatDate(birthdate));
		}
	}, [birthdate]);

	return (
		<EuiPageSection>
			<Helmet title="Order Confirmation" />
			<EuiText textAlign="center">
				<h3>Order Confirmation</h3>
			</EuiText>
			<EuiSpacer />
			<EuiFlexGroup>
				<EuiFlexItem
					style={{
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<EuiImage alt="ERX MedAlert" size="m" url={Logo} />
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiSpacer size="s" />
					<EuiForm>
						{isError && error && (
							<>
								<EuiCallOut
									color="danger"
									size="s"
									title={error}
								/>
								<EuiSpacer size="s" />
							</>
						)}
						<EuiFormRow
							error={errors.code}
							fullWidth
							isInvalid={!!errors.code}
							label="Code"
						>
							<EuiFieldText
								data-testid="code-input"
								fullWidth
								icon={() => (
									<FaFilePrescription
										color="ghost"
										size={18}
									/>
								)}
								id="code"
								isInvalid={!!errors.code}
								name="code"
								onChange={handleChange}
								placeholder="Enter Code"
								value={values.code}
							/>
						</EuiFormRow>
						<EuiFormRow
							error={touched.birthdate && errors.birthdate}
							fullWidth
							isInvalid={touched.birthdate && !!errors.birthdate}
							label="Birthdate"
						>
							<EuiDatePicker
								fullWidth
								id="birthdate"
								isInvalid={
									touched.birthdate && !!errors.birthdate
								}
								name="birthdate"
								onChange={(e) => setBirthdate(e)}
								placeholder="Birthdate (MM/DD/YYYY)"
								selected={birthdate}
								yearDropdownItemNumber={50}
							/>
						</EuiFormRow>
						<EuiSpacer />
						<EuiButton
							color="primary"
							data-testid="login-button"
							disabled={isLoading}
							fill
							fullWidth
							isLoading={isLoading}
							onClick={handleSubmit}
							type="submit"
						>
							Login
						</EuiButton>
					</EuiForm>
				</EuiFlexItem>
			</EuiFlexGroup>
			<LoginPrompt />
		</EuiPageSection>
	);
};

ValidatePrescriptionForm.propTypes = {
	code: PropTypes.string,
	onValidate: PropTypes.func.isRequired,
};

ValidatePrescriptionForm.defaultProps = {
	code: null,
};

export default ValidatePrescriptionForm;
