import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { NavLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FiMenu } from 'react-icons/fi';

import {
	EuiButtonIcon,
	EuiCollapsibleNav,
	EuiCollapsibleNavGroup,
	EuiHeader,
	EuiHeaderSection,
	EuiHeaderSectionItem,
	EuiShowFor,
	EuiListGroupItem,
	EuiImage,
	EuiLink,
} from '@elastic/eui';
import _ from 'lodash';

import Logo from 'assets/images/logo_horizontal_white_small.png';
import LogoHorizontal from 'assets/images/logo_horizontal.png';
import { ROLE_PATH } from 'constants/protect';
import {
	DOCTOR,
	// PHARMACIST,
	SECRETARY,
	PATIENT,
} from 'components/roles';
import { menu } from 'components/menu';

import { dockAction, undockAction } from 'modules/dashboard/dashboard.actions';
import Toasts from 'modules/toasts/Toasts';
import Lightbox from 'modules/lightbox/Lightbox';
import ValidationModal from 'modules/dashboard/ValidationModal';
import Notifications from 'components/Notifications';
import HeaderUserMenu from 'components/HeaderUserMenu';
import ContactUs from 'modules/_global/ContactUsModal';
import TermsModal from 'modules/auth/TermsModal';
import PrivacyModal from 'modules/auth/PrivacyModal';
import ErxContractModal from 'modules/auth/ErxContractModal';
import SendRxModal from 'modules/prescriptions/SendRxModal';
import AddRxButton from 'modules/prescriptions/components/AddRxButton';
import AddRxPatientButton from 'modules/prescriptions/components/AddRxPatientButton';
import PatientMyInfoModal from 'modules/patients/PatientMyInfoModal';

import Points from 'modules/_global/Points';
import PointsCard from 'modules/dashboard/components/Advertisements/PointsCard';
import usePushNotifications from '../../modules/push-notifications/usePushNotifications';

function DashboardLayout() {
	const { t } = useTranslation();
	const history = useHistory();
	const { handleAskUserPermission } = usePushNotifications();

	const path = history.location.pathname;
	const { auth, dashboard, role, user, vendor } = useSelector((state) => ({
		dashboard: state.dashboard,
		auth: state.auth,
		role: _.get(state, ROLE_PATH),
		user: _.get(state, 'auth.user'),
		vendor: _.get(state, 'auth.user.vendor'),
	}));
	const dispatch = useDispatch();
	const [menuOpen, setMenuOpen] = useState(false);
	const [termOpen, setTermOpen] = useState(false);
	const [privacyOpen, setPrivacyOpen] = useState(false);
	const [isOpenMyInfo, setIsOpenMyInfo] = useState(false);
	const [erxContractOpen, setErxContractOpen] = useState(
		auth.isLoggedIn &&
			role === DOCTOR.key &&
			!_.get(auth, 'user.details.erxContractAccepted'),
	);
	const [addRxButtonVisible, setAddRxButtonVisible] = useState(true);
	const [addRxPatientButtonVisible, setAddRxPatientButtonVisible] =
		useState(true);
	const toggleDock = () => {
		if (dashboard.isNavDocked) return dispatch(undockAction());
		return dispatch(dockAction());
	};
	const toggleTerm = () => setTermOpen(!termOpen);
	const togglePrivacy = () => setPrivacyOpen(!privacyOpen);
	const toggleErxContract = () => setErxContractOpen(!erxContractOpen);
	useEffect(() => {
		function checkPermission() {
			if ('Notification' in window) {
				// ask the user for permission
				if (Notification.permission !== 'denied') {
					handleAskUserPermission();
				}
			}
		}
		if (auth.isLoggedIn) checkPermission();
	}, [auth.isLoggedIn]);

	useEffect(() => {
		setAddRxButtonVisible(
			path.replace(/\s/g, '') !== '/prescriptions/create',
		);
		setAddRxPatientButtonVisible(
			path.replace(/\s/g, '') !== '/my-orders/create',
		);
	}, [path]);

	/**
	 * Adding routes based on roles.
	 */
	const routes = menu(t, vendor).reduce((acc, list) => {
		if (list.users.includes(role)) return acc.concat(list);
		return acc;
	}, []);

	if (!auth.isLoggedIn) {
		return <Redirect to="/login" />;
	}

	const menuButton = (
		<EuiButtonIcon
			aria-label="Toggle main navigation"
			className="main-menu-button"
			color="primary"
			data-testid="main-menu-button"
			iconType={() => <FiMenu color="#fff" size={22} />}
			onClick={() => setMenuOpen(!menuOpen)}
		/>
	);

	const customHeaderStyle = {};

	const bgColor = _.get(auth, 'user.vendor.primaryColor', null);

	if (bgColor) {
		customHeaderStyle.backgroundColor = bgColor;
	}

	return (
		<>
			{role === SECRETARY.key && <Redirect to="/" />}
			<EuiHeader
				position="fixed"
				style={customHeaderStyle}
				theme={role === PATIENT.key ? 'default' : 'dark'}
			>
				<EuiHeaderSection>
					<EuiHeaderSectionItem>
						{role !== PATIENT.key && (
							<AddRxButton visible={addRxButtonVisible} />
						)}
						{role === PATIENT.key && (
							<AddRxPatientButton
								visible={addRxPatientButtonVisible}
							/>
						)}

						<EuiCollapsibleNav
							button={menuButton}
							isDocked={dashboard.isNavDocked}
							isOpen={menuOpen}
							onClose={() => setMenuOpen(false)}
						>
							{role === DOCTOR.key ? (
								<EuiCollapsibleNavGroup>
									<PointsCard />
								</EuiCollapsibleNavGroup>
							) : null}

							{routes
								.filter(({ inMenu = true }) => inMenu)
								.map((route) => (
									<NavLink
										key={`main-menu-${route.name}`}
										onClick={() => setMenuOpen(false)}
										to={route.path}
									>
										<EuiCollapsibleNavGroup
											iconType={route.icon}
											isCollapsible={false}
											title={route.title}
										/>
									</NavLink>
								))}
							<EuiCollapsibleNavGroup>
								<EuiListGroupItem
									color="subdued"
									label={
										<span>
											<EuiLink
												color="subdued"
												onClick={toggleTerm}
											>
												Terms & Conditions
											</EuiLink>{' '}
											and{' '}
											<EuiLink
												color="subdued"
												onClick={togglePrivacy}
											>
												Privacy Policy
											</EuiLink>
										</span>
									}
									size="xs"
								/>
							</EuiCollapsibleNavGroup>
							<EuiShowFor sizes={['l', 'xl']}>
								<EuiCollapsibleNavGroup>
									<EuiListGroupItem
										color="subdued"
										iconType={
											dashboard.isNavDocked
												? 'lock'
												: 'lockOpen'
										}
										label={`${
											dashboard.isNavDocked
												? 'Undock'
												: 'Dock'
										} navigation`}
										onClick={toggleDock}
										size="xs"
									/>
								</EuiCollapsibleNavGroup>
							</EuiShowFor>
						</EuiCollapsibleNav>
					</EuiHeaderSectionItem>

					<EuiHeaderSectionItem className="brand-container">
						<NavLink to="/">
							{role !== PATIENT.key && (
								<EuiImage
									alt="Go Back to Home Page"
									url={_.get(vendor, 'logo.url', Logo)}
								/>
							)}
							{role === PATIENT.key && (
								<EuiImage
									alt="Go Back to Home Page"
									url={_.get(
										vendor,
										'logo.url',
										LogoHorizontal,
									)}
								/>
							)}
						</NavLink>
					</EuiHeaderSectionItem>
				</EuiHeaderSection>

				<EuiHeaderSection side="right">
					{role === DOCTOR.key && (
						<EuiHeaderSectionItem>
							<Points />
						</EuiHeaderSectionItem>
					)}
					<EuiHeaderSectionItem>
						<ContactUs />
					</EuiHeaderSectionItem>
					<EuiHeaderSectionItem>
						<Notifications />
					</EuiHeaderSectionItem>
					<EuiHeaderSectionItem>
						<HeaderUserMenu setIsOpenMyInfo={setIsOpenMyInfo} />
					</EuiHeaderSectionItem>
				</EuiHeaderSection>
			</EuiHeader>
			<div className="dashboard">
				<Switch>
					{routes.map((route) => (
						// eslint-disable-next-line react/jsx-props-no-spreading
						<Route key={`dashboard-${route.name}`} {...route} />
					))}
				</Switch>
			</div>

			<Toasts />
			<Lightbox />
			<ValidationModal
				pending={_.get(auth, 'user.details.validationPending', false)}
				visible={
					role === DOCTOR.key &&
					!_.get(auth, 'user.details.prcValidated')
				}
			/>
			<TermsModal isOpen={termOpen} onClose={toggleTerm} />
			<PrivacyModal isOpen={privacyOpen} onClose={togglePrivacy} />
			<ErxContractModal
				isOpen={erxContractOpen}
				onClose={toggleErxContract}
			/>
			<SendRxModal />
			<PatientMyInfoModal
				isOpenMyInfo={
					(!user.firstName ||
						!user.lastName ||
						!user.contactDetails ||
						!user.birthdate ||
						!user.address ||
						!user.address.city ||
						!user.address.brgy) &&
					role === PATIENT.key
						? true
						: isOpenMyInfo
				}
				onClose={() => setIsOpenMyInfo(!isOpenMyInfo)}
				user={user}
			/>
		</>
	);
}

DashboardLayout.propTypes = {};

DashboardLayout.defaultProps = {};

export default DashboardLayout;
