import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
	EuiFlexGroup,
	EuiFlexItem,
	EuiPopover,
	EuiButton,
	EuiButtonEmpty,
	EuiSelect,
} from '@elastic/eui';
import { isEmpty, filter } from 'lodash';
import Page from 'components/Page';
import Table from 'components/Table';
import PatientModal from 'modules/patients/PatientModal';
import { getOrdersbyPatient } from 'modules/patients/patients.fetch';
import { addToast } from '../toasts/toasts.actions';

export default function PatientOrders() {
	const history = useHistory();
	const dispatch = useDispatch();
	const [order, setOrder] = useState({});
	const [viewOrderFilters, setViewOrderFilters] = useState(false);
	const [sortedOrders, setSortedOrders] = useState({
		all: [],
		pendingPayment: [],
		processing: [],
		readyForPickup: [],
		released: [],
		cancelled: [],
	});

	const { handleChange, values } = useFormik({
		initialValues: { pendingOrderSelection: 'all' },
	});

	const { pendingOrderSelection } = values;

	const snakeCaseToTitleCase = (string) => {
		if (!string) return '';
		return string
			.replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
			.replace(/[-_]+(.)/g, (_, c) => ` ${c.toUpperCase()}`);
	};

	const camelCaseToTitleCase = (string) => {
		if (!string) return '';
		return string
			.replace(/([A-Z])/g, ' $1')
			.replace(/^./, (str) => str.toUpperCase());
	};

	const columnsOrder = [
		{
			field: 'orderId',
			name: 'Code',
			render: (val) => <EuiFlexItem>{val}</EuiFlexItem>,
		},
		{
			field: 'branch.name',
			name: 'Fulfilling Branch',
			render: (val) => <EuiFlexItem>{val}</EuiFlexItem>,
		},
		{
			field: 'fulfillment',
			name: 'Fulfillment Type',
			render: (val) => (
				<EuiFlexItem>{snakeCaseToTitleCase(val)}</EuiFlexItem>
			),
		},
		{
			field: 'status',
			name: 'Order Status',
			render: (val) => (
				<EuiFlexItem>{camelCaseToTitleCase(val)}</EuiFlexItem>
			),
		},
		{
			field: 'createdAt',
			name: 'Date and Time',
			render: (val) => (
				<EuiFlexItem>{moment(val).format('MM-DD-YYYY')}</EuiFlexItem>
			),
		},
		{
			field: 'actions',
			name: 'Actions',
			render: (val, data) => (
				<EuiFlexGroup
					className="table-actions"
					gutterSize="s"
					responsive={false}
				>
					<EuiFlexItem>
						<EuiButton
							fill
							isDisabled={[
								'processing',
								'cancelled',
								'released',
							].includes(data.status)}
							onClick={() => setOrder(data)}
							size="s"
						>
							View QR Code
						</EuiButton>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiButton
							onClick={() => {
								history.push({
									pathname: `/transactions/${data.id}`,
								});
							}}
							size="s"
						>
							View Transaction
						</EuiButton>
					</EuiFlexItem>
				</EuiFlexGroup>
			),
		},
	];

	const { data: orders } = useQuery(
		['orders', { _sort: 'createdAt:DESC' }],
		getOrdersbyPatient,
		{
			placeholderData: [],
			onError: (err) => {
				dispatch(
					addToast(
						'Error',
						err.message || 'Something went wrong',
						'danger',
						'help',
					),
				);
			},
			refetchInterval: 30 * 1000, // 30 seconds
		},
	);

	const sortOrders = () => {
		if (orders && orders.data) {
			const all = orders.data;
			const pendingPayment = filter(orders.data, {
				status: 'pendingPayment',
			});
			const processing = filter(orders.data, {
				status: 'processing',
			});
			const readyForPickup = filter(orders.data, {
				status: 'readyForPickup',
			});
			const released = filter(orders.data, {
				status: 'released',
			});
			const cancelled = filter(orders.data, {
				status: 'cancelled',
			});
			setSortedOrders({
				all,
				processing,
				pendingPayment,
				readyForPickup,
				released,
				cancelled,
			});
		}
	};

	useEffect(() => {
		sortOrders();
	}, [orders]);

	const statusList = [
		{
			value: 'all',
			text: 'All',
		},
		{
			value: 'processing',
			text: 'Processing',
		},
		{
			value: 'pendingPayment',
			text: 'Pending Payment',
		},
		{
			value: 'readyForPickup',
			text: 'Ready For Pickup',
		},
		{
			value: 'released',
			text: 'Released',
		},
		{
			value: 'cancelled',
			text: 'Cancelled',
		},
	];

	return (
		<Page title="Orders">
			<EuiFlexGroup justifyContent="flexEnd">
				<EuiFlexItem grow={false}>
					<EuiPopover
						button={
							<EuiButtonEmpty
								iconSide="right"
								iconSize="l"
								iconType="filter"
								onClick={() => {
									setViewOrderFilters(!viewOrderFilters);
								}}
							/>
						}
						closePopover={() => setViewOrderFilters(false)}
						isOpen={viewOrderFilters}
					>
						<EuiSelect
							id="pendingOrderSelection"
							name="pendingOrderSelection"
							onChange={handleChange}
							options={statusList}
							value={pendingOrderSelection}
						/>
					</EuiPopover>
				</EuiFlexItem>
			</EuiFlexGroup>
			<Table
				className="patient-order-list-table"
				columns={columnsOrder}
				isSelectable
				itemId="orderId"
				items={sortedOrders?.[pendingOrderSelection]}
			/>
			<PatientModal
				isOpen={!isEmpty(order)}
				onClose={() => setOrder({})}
				order={order}
			/>
		</Page>
	);
}
