/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';

import {
	EuiFieldText,
	EuiFlexGroup,
	EuiFlexItem,
	EuiFormRow,
	EuiDatePicker,
} from '@elastic/eui';

import PropTypes from 'prop-types';
import moment from 'moment';
import { formatDate } from 'utils/helpers';

const PersonalInfomationForm = ({
	editContacts,
	emailRequired,
	formikBag,
	readOnly,
}) => {
	const { errors, handleBlur, handleChange, setFieldValue, touched, values } =
		formikBag;
	const [birthdate, setBirthdate] = useState(null);

	useEffect(() => {
		if (birthdate && formatDate(birthdate) !== values.birthdate) {
			setFieldValue('birthdate', moment(birthdate).format('YYYY-MM-DD'));
		}
	}, [birthdate]);

	return (
		<>
			<EuiFlexGroup
				style={{
					flexDirection: 'column',
				}}
			>
				<EuiFlexItem>
					<EuiFormRow
						error={touched.firstName && errors.firstName}
						isInvalid={touched.firstName && !!errors.firstName}
						label="First Name *"
					>
						<EuiFieldText
							id="firstName"
							isInvalid={touched.firstName && !!errors.firstName}
							name="firstName"
							onBlur={handleBlur}
							onChange={handleChange}
							readOnly={readOnly}
							value={values.firstName}
						/>
					</EuiFormRow>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiFormRow label="Middle Name">
						<EuiFieldText
							id="middleName"
							name="middleName"
							onBlur={handleBlur}
							onChange={handleChange}
							readOnly={readOnly}
							value={values.middleName}
						/>
					</EuiFormRow>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiFormRow
						error={touched.lastName && errors.lastName}
						isInvalid={touched.lastName && !!errors.lastName}
						label="Last Name *"
					>
						<EuiFieldText
							id="lastName"
							isInvalid={touched.lastName && !!errors.lastName}
							name="lastName"
							onBlur={handleBlur}
							onChange={handleChange}
							readOnly={readOnly}
							value={values.lastName}
						/>
					</EuiFormRow>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiFormRow
						error={touched.birthdate && errors.birthdate}
						isInvalid={touched.birthdate && !!errors.birthdate}
						label="Birthdate *"
					>
						<EuiDatePicker
							id="birthdate"
							isInvalid={touched.birthdate && !!errors.birthdate}
							name="birthdate"
							onChange={(e) => setBirthdate(e)}
							placeholder="Birthdate (MM/DD/YYYY)"
							selected={
								values.birthdate
									? moment(values.birthdate)
									: birthdate
							}
							yearDropdownItemNumber={50}
						/>
					</EuiFormRow>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiFormRow
						error={touched.email && errors.email}
						isInvalid={touched.email && errors.email}
						label={emailRequired ? 'Email *' : 'Email'}
					>
						<EuiFieldText
							id="email"
							isInvalid={touched.email && errors.email}
							name="email"
							onBlur={handleBlur}
							onChange={handleChange}
							readOnly={readOnly && !editContacts}
							value={values.email}
						/>
					</EuiFormRow>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiFormRow
						error={touched.mobile && errors.mobile}
						isInvalid={touched.mobile && errors.mobile}
						label="Mobile Number *"
					>
						<EuiFieldText
							id="mobile"
							isInvalid={touched.mobile && errors.mobile}
							name="mobile"
							onBlur={handleBlur}
							onChange={handleChange}
							readOnly={readOnly && !editContacts}
							value={values.mobile}
						/>
					</EuiFormRow>
				</EuiFlexItem>
			</EuiFlexGroup>
		</>
	);
};

PersonalInfomationForm.propTypes = {
	editContacts: PropTypes.bool,
	emailRequired: PropTypes.bool,
	formikBag: PropTypes.shape({
		errors: PropTypes.object,
		handleBlur: PropTypes.func,
		handleChange: PropTypes.func,
		setFieldValue: PropTypes.func,
		touched: PropTypes.object,
		values: PropTypes.object,
	}).isRequired,
	readOnly: PropTypes.bool,
};

PersonalInfomationForm.defaultProps = {
	editContacts: false,
	emailRequired: false,
	readOnly: false,
};
export default PersonalInfomationForm;
