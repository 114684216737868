import React from 'react';
import PropType from 'prop-types';
import {
	EuiFlexGroup,
	EuiFlexItem,
	EuiHorizontalRule,
	EuiModal,
	EuiModalHeader,
	EuiModalHeaderTitle,
	EuiOverlayMask,
} from '@elastic/eui';
import { Rating } from 'react-simple-star-rating';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { addToast } from 'modules/toasts/toasts.actions';
import { getErrorMessage } from 'utils/helpers';
import {
	postRateAdvertisement,
	postAccumulatePts,
} from './advertisement.fetch';

const AdsRate = ({
	advertisementId,
	onRefetchData,
	onSetVisibility,
	pointsForRating,
	title,
	visible,
}) => {
	const dispatch = useDispatch();

	const { mutate: mutateAccumulatePoints } = useMutation(postAccumulatePts, {
		onSuccess: () => {
			dispatch(
				addToast(
					'You have accumulated points by rating this advertisement.',
					null,
					'success',
					'check',
				),
			);
		},
		onError: (err) => {
			if (err?.response?.status !== 409) {
				const errorMessage = getErrorMessage(err);
				dispatch(addToast('Error', errorMessage, 'danger', 'help'));
			}
		},
	});

	const handleHideModal = () => {
		onSetVisibility(false);
	};

	const handleSuccess = () => {
		dispatch(
			addToast(
				'Thank you for rating this advertisement.',
				null,
				'success',
				'check',
			),
		);
		mutateAccumulatePoints({
			advertisementId,
			action: 'RATING',
			points: pointsForRating,
		});
		onSetVisibility(false);
		onRefetchData();
	};

	const handleError = (err) => {
		const errorMessage = getErrorMessage(err);
		dispatch(addToast('Error', errorMessage, 'danger', 'help'));
	};

	const { isLoading, mutate } = useMutation(postRateAdvertisement, {
		onSuccess: handleSuccess,
		onError: handleError,
	});

	const handleRating = (rating) => {
		mutate({ advertisementId, rating });
	};

	if (!visible) {
		return null;
	}

	return (
		<EuiOverlayMask key="ads-rate">
			<EuiModal onClose={handleHideModal}>
				<EuiModalHeader>
					<EuiModalHeaderTitle size="s">{title}</EuiModalHeaderTitle>
				</EuiModalHeader>
				<EuiHorizontalRule />
				<EuiFlexGroup
					justifyContent="spaceAround"
					style={{ margin: '10px', marginBottom: '30px' }}
				>
					<EuiFlexItem grow={false} style={{ padding: '24px' }}>
						<Rating onClick={handleRating} readonly={isLoading} />
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiModal>
		</EuiOverlayMask>
	);
};

AdsRate.propTypes = {
	advertisementId: PropType.number.isRequired,
	onRefetchData: PropType.func.isRequired,
	onSetVisibility: PropType.func.isRequired,
	pointsForRating: PropType.number.isRequired,
	title: PropType.string.isRequired,
	visible: PropType.bool.isRequired,
};

export default AdsRate;
