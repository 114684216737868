import {
	EuiFlexGroup,
	EuiFlexItem,
	EuiHorizontalRule,
	EuiPanel,
	EuiStat,
	EuiTitle,
} from '@elastic/eui';
import { countErx, countPatients } from 'modules/dashboard/dashboard.fetch';
import React from 'react';
import { useQueries } from 'react-query';
import { Link } from 'react-router-dom';

const DoctorStatistics = () => {
	const interval = 2 * 60 * 1000; // every 5 minutes

	const results = useQueries([
		{
			queryKey: ['patients-total'],
			queryFn: countPatients,
			refetchInterval: interval,
		},
		{
			queryKey: ['erx-total'],
			queryFn: countErx,
			refetchInterval: interval,
		},
		{
			queryKey: ['erx-total-sent', { sentToPatient: true }],
			queryFn: countErx,
			refetchInterval: interval,
		},
	]);

	const [totalPatients, totalRx, totalRxSent] = results;

	return (
		<div style={{ marginBottom: '10px' }}>
			<EuiTitle>
				<h1>Summary</h1>
			</EuiTitle>

			<EuiHorizontalRule margin="s" />

			<EuiFlexGroup>
				<EuiFlexItem className="eustat-card">
					<EuiPanel>
						<Link to="/patients">
							<EuiStat
								description="All Patients"
								isLoading={totalPatients.isLoading}
								reverse
								textAlign="center"
								title={totalPatients.data}
							/>
						</Link>
					</EuiPanel>
				</EuiFlexItem>
				<EuiFlexItem className="eustat-card">
					<EuiPanel>
						<Link to="prescriptions">
							<EuiStat
								description="Total ERX Sent"
								isLoading={totalRx.isLoading}
								reverse
								textAlign="center"
								title={totalRx.data}
							/>
						</Link>
					</EuiPanel>
				</EuiFlexItem>
				<EuiFlexItem className="eustat-card">
					<EuiPanel>
						<Link to="prescriptions">
							<EuiStat
								description="ERX Sent Today"
								isLoading={totalRxSent.isLoading}
								reverse
								textAlign="center"
								title={totalRxSent.data}
							/>
						</Link>
					</EuiPanel>
				</EuiFlexItem>
			</EuiFlexGroup>
		</div>
	);
};

export default DoctorStatistics;
