/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
	EuiTitle,
	EuiSpacer,
	EuiHorizontalRule,
	EuiForm,
	EuiFormRow,
	EuiSelect,
	EuiFieldText,
	EuiFlexGroup,
	EuiFlexItem,
	EuiFieldNumber,
	EuiRadio,
	EuiDatePicker,
	EuiButton,
} from '@elastic/eui';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Calendar, DateObject } from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';

const PatientOrderScheduleForm = ({ formikBag, prescription, readOnly }) => {
	const [repeatEnd, setRepeatEnd] = useState('count');
	const { errors, handleBlur, handleChange, setFieldValue, touched, values } =
		formikBag;
	const {
		dayOfMonth,
		dayOfWeek,
		email,
		intervalUnit,
		intervalValue,
		mobile,
		modeOfContact,
		repeatCount,
		repeatEndDate,
		schedule,
	} = values;

	useEffect(() => {
		if (prescription) {
			if (prescription.patient?.contactDetails?.length) {
				const { email: emailValue, mobile: mobileValue } =
					prescription.patient;
				setFieldValue('email', emailValue ?? '');
				setFieldValue('mobile', mobileValue ?? '');
			}
		} else {
			setFieldValue('email', '');
			setFieldValue('mobile', '');
		}
	}, [prescription]);

	useEffect(() => {
		const dates = [];
		if (
			((intervalUnit === 'month' && dayOfMonth) ||
				(intervalUnit === 'week' && dayOfWeek !== null)) &&
			intervalValue
		) {
			const getNextDate = () => {
				let addInterval = null;
				if (dates.length) {
					addInterval = moment(dates[dates.length - 1]).add(
						intervalValue,
						intervalUnit,
					);
				} else {
					addInterval = moment().add(intervalValue, intervalUnit);
				}

				let setDay = moment(addInterval);
				if (intervalUnit === 'month') {
					setDay.date(dayOfMonth);

					if (!addInterval.isSame(setDay, 'month')) {
						if (dayOfMonth < 1) {
							setDay = addInterval.startOf('month');
						} else {
							setDay = addInterval.endOf('month');
						}
					}
				} else if (intervalUnit === 'week') {
					setDay.day(dayOfWeek);
				}

				dates.push(setDay.format('L'));
			};

			if (repeatEnd === 'count') {
				for (let i = 0; i < repeatCount; i += 1) {
					getNextDate();
				}
			} else if (repeatEnd === 'date') {
				do {
					getNextDate();
				} while (
					moment(dates[dates.length - 1]).isBefore(repeatEndDate)
				);

				if (moment(dates[dates.length - 1]).isAfter(repeatEndDate)) {
					dates.pop();
				}
			}
		}
		setFieldValue('schedule', dates);
	}, [
		dayOfMonth,
		dayOfWeek,
		intervalUnit,
		intervalValue,
		repeatCount,
		repeatEndDate,
		repeatEnd,
	]);

	useEffect(() => {
		setFieldValue('schedule', schedule);
	}, [schedule]);

	return (
		<EuiForm>
			<EuiSpacer />
			<EuiTitle size="s">
				<h4>Recurring Order Details</h4>
			</EuiTitle>
			<EuiHorizontalRule margin="s" />
			<EuiFormRow label="Order Every">
				<EuiFlexGroup>
					<EuiFlexItem>
						<EuiFieldNumber
							id="intervalValue"
							isInvalid={
								touched.intervalValue && !!errors.intervalValue
							}
							min={1}
							name="intervalValue"
							onChange={handleChange}
							value={intervalValue}
						/>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiSelect
							onChange={(e) => {
								setFieldValue('intervalUnit', e.target.value);
							}}
							options={[
								{
									value: 'month',
									text:
										intervalValue === 1
											? 'Month'
											: 'Months',
								},
								{
									value: 'week',
									text:
										intervalValue === 1 ? 'Week' : 'Weeks',
								},
							]}
							value={intervalUnit}
						/>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiFormRow>
			<EuiFormRow label="Order on Day">
				<EuiFlexGroup>
					{intervalUnit === 'month' && (
						<EuiFlexItem>
							<EuiFieldNumber
								id="dayOfMonth"
								isInvalid={
									touched.dayOfMonth && !!errors.dayOfMonth
								}
								max={31}
								min={1}
								name="dayOfMonth"
								onBlur={handleBlur}
								onChange={handleChange}
								placeholder="Choose a day of the month, i.e. 1-31."
								value={dayOfMonth}
							/>
						</EuiFlexItem>
					)}
					{intervalUnit === 'week' && (
						<EuiFlexItem>
							<EuiSelect
								onChange={(e) => {
									setFieldValue('dayOfWeek', e.target.value);
								}}
								options={[
									'Sunday',
									'Monday',
									'Tuesday',
									'Wednesday',
									'Thursday',
									'Friday',
									'Saturday',
								].map((day, index) => {
									return {
										value: index,
										text: day,
									};
								})}
								value={dayOfWeek}
							/>
						</EuiFlexItem>
					)}
				</EuiFlexGroup>
			</EuiFormRow>
			<EuiFormRow label="Ends On">
				<>
					<EuiFlexGroup className="radioGroupItem">
						<EuiFlexItem grow={false}>
							<EuiRadio
								checked={repeatEnd === 'count'}
								onChange={() => {
									setRepeatEnd('count');
								}}
							/>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<span>After</span>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<EuiFieldNumber
								id="repeatCount"
								isInvalid={
									touched.repeatCount && !!errors.repeatCount
								}
								min={1}
								name="repeatCount"
								onBlur={handleBlur}
								onChange={handleChange}
								value={repeatCount}
							/>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<span>occurrences.</span>
						</EuiFlexItem>
					</EuiFlexGroup>
					<EuiFlexGroup className="radioGroupItem">
						<EuiFlexItem grow={false}>
							<EuiRadio
								checked={repeatEnd === 'date'}
								onChange={() => {
									setRepeatEnd('date');
								}}
							/>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<span>On</span>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<EuiDatePicker
								id="repeatEndDate"
								isInvalid={
									touched.repeatEndDate &&
									!!errors.repeatEndDate
								}
								minDate={moment().add(
									intervalValue,
									intervalUnit,
								)}
								name="repeatEndDate"
								onChange={(e) => {
									setFieldValue('repeatEndDate', e);
								}}
								placeholder="MM/DD/YYYY"
								selected={repeatEndDate}
								yearDropdownItemNumber={50}
							/>
						</EuiFlexItem>
					</EuiFlexGroup>
				</>
			</EuiFormRow>
			<EuiFormRow>
				<Calendar
					minDate={new DateObject().add(1, 'days')}
					multiple
					onChange={(dates) => {
						const formatDates = dates.map((date) => {
							return moment(date.format(), 'YYYY/MM/DD');
						});
						setFieldValue('schedule', formatDates);
					}}
					plugins={[<DatePanel key="date-panel" sort="date" />]}
					value={schedule.map((date) => {
						const formatDate = moment(date).format('YYYY/MM/DD');
						return new DateObject(formatDate);
					})}
				/>
			</EuiFormRow>
			<EuiSpacer />
			<EuiFormRow label="Next Schedule Dates:">
				<>
					{schedule.map((scheduledDate, index) => (
						<span
							key={scheduledDate}
							style={{
								marginRight: '5px',
							}}
						>
							{moment(scheduledDate).format('LL')}
							{index < schedule.length - 1 ? ',' : ''}
						</span>
					))}
				</>
			</EuiFormRow>
			<EuiHorizontalRule />
			<EuiFormRow label="Mode of Contact">
				<EuiSelect
					onChange={(e) => {
						setFieldValue('modeOfContact', e.target.value);
					}}
					options={[
						{ value: 'all', text: 'Text and Email' },
						{ value: 'text', text: 'Text' },
						{ value: 'email', text: 'Email' },
					]}
					value={modeOfContact}
				/>
			</EuiFormRow>
			<EuiFormRow
				error={touched.mobile && errors.mobile}
				isInvalid={touched.mobile && !!errors.mobile}
				label="Mobile Number"
			>
				<EuiFieldText
					id="mobile"
					isInvalid={touched.mobile && !!errors.mobile}
					name="mobile"
					onBlur={handleBlur}
					onChange={handleChange}
					readOnly={readOnly}
					value={mobile}
				/>
			</EuiFormRow>
			<EuiFormRow
				error={touched.email && errors.email}
				isInvalid={touched.email && !!errors.email}
				label="Email Address"
			>
				<EuiFieldText
					id="email"
					isInvalid={touched.email && !!errors.email}
					name="email"
					onBlur={handleBlur}
					onChange={handleChange}
					readOnly={readOnly}
					value={email}
				/>
			</EuiFormRow>
		</EuiForm>
	);
};

PatientOrderScheduleForm.propTypes = {
	formikBag: PropTypes.instanceOf(Object).isRequired,
	prescription: PropTypes.instanceOf(Object).isRequired,
	readOnly: PropTypes.bool,
};

PatientOrderScheduleForm.defaultProps = {
	readOnly: true,
};

export default PatientOrderScheduleForm;
