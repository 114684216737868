import { isMobile } from 'react-device-detect';

export default {
	dashboard: {
		isNavDocked: !isMobile,
		prescriptions: [],
	},
	auth: {
		isLoggedIn: false,
		rememberMe: false,
		registrationRxReference: null,
	},
	prescriptions: [],
	toasts: [],
	lightbox: null,
	sendRxModal: {
		rxId: null,
		visible: false,
	},
};
