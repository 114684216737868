/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	EuiTitle,
	EuiFlexGroup,
	EuiFlexItem,
	EuiTabbedContent,
	EuiHorizontalRule,
	EuiImage,
	EuiLoadingSpinner,
	EuiButton,
	EuiButtonEmpty,
	EuiSpacer,
} from '@elastic/eui';

import PropTypes from 'prop-types';

import Page from 'components/Page';

import NoProfilePhoto from 'assets/images/no-profile-photo.png';

const ProfilePage = ({
	data,
	hasChanges,
	initialSelectedTab,
	isLoading,
	onCancel,
	onSubmit,
	onTabClick,
	// selectedTab,
	tabs,
	title,
}) => {
	const { t } = useTranslation();
	const { attachments, firstName, lastName, middleName } = data;
	const displayName = `${firstName}  ${middleName || ''} ${lastName}`;
	const displayPhoto =
		attachments && attachments.length ? attachments.url[0] : NoProfilePhoto;

	return (
		<Page stickyHeader title={title}>
			{isLoading ? (
				<EuiLoadingSpinner />
			) : (
				<EuiFlexItem>
					<EuiTitle size="xs">
						<h3>{displayName}</h3>
					</EuiTitle>
				</EuiFlexItem>
			)}
			<EuiHorizontalRule />
			<EuiFlexGroup>
				<EuiFlexItem
					grow={false}
					style={{
						padding: 7,
						alignItems: 'middle',
						alignSelf: 'flex-start',
					}}
				>
					<EuiSpacer />
					<EuiImage
						allowFullScreen
						alt="Accessible image alt goes here"
						hasShadow
						size="m"
						url={displayPhoto}
					/>
					<EuiSpacer />
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiFlexItem>
						<EuiTabbedContent
							// autoFocus="selected"
							className="euiTabs--expandedTabs"
							initialSelectedTab={tabs[initialSelectedTab]}
							onTabClick={onTabClick}
							// selectedTab={selectedTab}
							// eslint-disable-next-line no-unused-vars
							style={{ minHeight: 'auto' }}
							tabs={tabs}
						/>
					</EuiFlexItem>
					<EuiFlexGroup
						// alignItems="center"
						// direction="row"
						gutterSize="s"
						justifyContent="flexEnd"
						responsive={false}
					>
						<EuiFlexItem grow={false}>
							<EuiButtonEmpty
								color="danger"
								disabled={isLoading || !hasChanges}
								iconType="crossInACircleFilled"
								onClick={onCancel}
							>
								{t('general.cancel')}
							</EuiButtonEmpty>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<EuiButton
								disabled={isLoading || !hasChanges}
								fill
								iconType="checkInCircleFilled"
								isLoading={isLoading}
								onClick={onSubmit}
							>
								{t('general.saveChanges')}
							</EuiButton>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
			</EuiFlexGroup>
		</Page>
	);
};

ProfilePage.propTypes = {
	data: PropTypes.shape({
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		middleName: PropTypes.string,
		attachments: PropTypes.array,
	}),
	hasChanges: PropTypes.bool,
	initialSelectedTab: PropTypes.number,
	isLoading: PropTypes.bool,
	onCancel: PropTypes.func,
	onSubmit: PropTypes.func,
	onTabClick: PropTypes.func,
	// selectedTab: PropTypes.func,
	tabs: PropTypes.array,
	title: PropTypes.string,
};

ProfilePage.defaultProps = {
	data: {},
	hasChanges: false,
	initialSelectedTab: 0,
	isLoading: false,
	onCancel: () => {},
	onSubmit: () => {},
	onTabClick: () => {},
	// selectedTab: () => {},
	tabs: [],
	title: '',
};

export default ProfilePage;
