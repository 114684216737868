import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Document, Page as DocumentPage, pdfjs } from 'react-pdf';
import {
	EuiFlexGroup,
	EuiButton,
	EuiFlexItem,
	EuiButtonEmpty,
	EuiDescriptionList,
	EuiLink,
	EuiSpacer,
	EuiImage,
	EuiFlexGrid,
	EuiTabbedContent,
	EuiShowFor,
	EuiTextArea,
	EuiText,
	EuiPageSection,
} from '@elastic/eui';
import { useFormik } from 'formik';
import { get, keys, pickBy, identity, isEmpty } from 'lodash';
import moment from 'moment';
import { getErrorMessage } from 'utils/helpers';
import { ADD_PRESCRIPTION } from 'constants/prescription';
import FilesSlider from 'components/FilesSlider';
import ContactDetails from 'modules/_global/ContactDetails';
import DoctorQuickView from 'modules/_global/DoctorQuickView';
import BranchInfo from 'modules/_global/BranchInfo';
import {
	ADMIN,
	DOCTOR,
	SECRETARY,
	PHARMACIST,
	SUPER_ADMIN,
	CALL_CENTER,
} from 'components/roles';
import Page from 'components/Page';
import Print from 'components/Print';
import ConfirmationModal from 'components/ConfirmationModal';
import BranchDropdown from 'components/forms/BranchDropdown';
// import RangeSelector from 'components/RangeSelector';
import CityMunicipalityDropdown from 'components/forms/CityMunicipalityDropdown';
import PrescriptionItemForm from './components/PrescriptionItemForm';
import RxStatus from './components/RxStatus';
import PrescriptionOrders from './components/PrescriptionOrders';
import TransferHistory from './components/TransferHistory';
import PrescriptionStatusDropdown from './components/PrescriptionStatusDropdown';
import CreateOrderButton from './components/CreateOrderButton';
import { addToast } from '../toasts/toasts.actions';
import RxFulfillmentDropdown from './components/RxFulfillmentDropdown';
import PaymentMethodDropdown from './components/PaymentMethodDropdown';
import {
	getRx,
	updateRx,
	updateRxStatus,
	mapRxData,
	rxModel,
	mapRxItemFormData,
} from './prescriptions.fetch';
import ChatBox from '../comments/ChatBox';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Prescription = () => {
	const { t } = useTranslation();
	const { prescriptionId } = useParams();
	const dispatch = useDispatch();
	const { role, vendor } = useSelector((s) => ({
		vendor: get(s, 'auth.user.vendor'),
		role: get(s, 'auth.user.role.type'),
	}));
	const [hasChanges, setHasChanges] = useState(false);
	const [numPages, setNumPages] = useState(1);
	const [city, setCity] = useState(null);
	const [contactDetails, setContactDetails] = useState([]);
	const [
		isConfirmCancelChangesModalVisible,
		setIsConfirmCancelChangesModalVisible,
	] = useState(false);
	const [prescriptionStatus, setPrescriptionStatus] = useState({
		value: null,
		isConfirmationModalOpen: false,
	});

	const {
		data: rxData,
		isLoading,
		refetch,
	} = useQuery(['get-rx', prescriptionId], getRx, {
		enabled: !hasChanges,
		onSuccess: () => {
			setHasChanges(false);
		},
	});

	const { isLoading: isUpdating, mutate: mutateRx } = useMutation(updateRx, {
		onSuccess: () => {
			setHasChanges(false);
			dispatch(
				addToast(
					'Prescription Updated',
					'Changes Saved',
					'success',
					'check',
				),
			);
		},
		onError: (err) => {
			dispatch(addToast('Error', err.message, 'warning', 'help'));
		},
		onSettled: () => {
			refetch();
		},
	});

	const { isLoading: isUpdatingStatus, mutate: mutateRxStatus } = useMutation(
		updateRxStatus,
		{
			onSuccess: () => {
				setHasChanges(false);
				dispatch(
					addToast(
						'Prescription Status Updated',
						'Changes Saved',
						'success',
						'check',
					),
				);
			},
			onError: (err) => {
				dispatch(
					addToast('Error', getErrorMessage(err), 'warning', 'help'),
				);
			},
			onSettled: () => {
				refetch();
			},
		},
	);

	const {
		handleChange,
		handleSubmit,
		initialValues,
		resetForm,
		setFieldTouched,
		setFieldValue,
		setTouched,
		touched,
		values,
	} = useFormik({
		initialValues: {
			...rxModel,
			...mapRxData(rxData),
		},
		validateOnBlur: true,
		enableReinitialize: true,
		onSubmit: async ({ prescriptionItems, ...data }) =>
			mutateRx({
				id: prescriptionId,
				prescriptionItems: prescriptionItems.map((rxItem) =>
					mapRxItemFormData(rxItem),
				),
				...data,
			}),
	});

	const {
		attachments: unprocessedAttachments,
		code,
		doctor,
		encoded,
		patient,
		prescriptionItems,
		status,
	} = values;

	const prescription = {
		...values.prescription,
		prescriptionItems,
	};

	useEffect(() => {
		if (!hasChanges) {
			setHasChanges(keys(pickBy(touched, identity)).length > 0);
		}
	}, [touched]);

	const isServed = status === 'served';
	const isCancelled = status === 'cancelled';
	const isEncoded = encoded;
	let attachments = [];

	if (unprocessedAttachments && unprocessedAttachments.length) {
		attachments = unprocessedAttachments.map((att) => {
			if (att.provider === 'local') {
				const newAtt = { ...att };
				Object.keys(att.formats).forEach((key) => {
					newAtt.formats[
						key
					].url = `${process.env.REACT_APP_API_URL}${att.formats[key].url}`;
				});

				newAtt.url = `${process.env.REACT_APP_API_URL}${att.url}`;

				return newAtt;
			}

			return att;
		});
	}

	const handlePrescriptionStatus = (data) => {
		setFieldTouched('status', true);
		return setPrescriptionStatus({
			value: data,
			isConfirmationModalOpen: true,
		});
	};

	const handleConfirmChangePrescriptionStatus = () => {
		mutateRxStatus({ id: prescriptionId, value: prescriptionStatus.value });
		setPrescriptionStatus({
			status,
			isConfirmationModalOpen: false,
		});
	};

	const handleCancelChangePrescriptionStatus = () => {
		setPrescriptionStatus({
			value: status,
			isConfirmationModalOpen: false,
		});
	};

	const onDocumentLoadSuccess = ({ pages }) => {
		setNumPages(pages);
	};

	const handleRxItemsSubmit = (data) => {
		if (!touched.prescriptionItems) {
			setFieldTouched('prescriptionItems', true);
		}
		setFieldValue('prescriptionItems', data);
	};

	const pdfAttachment =
		attachments.length > 0 ? attachments[0].mime === 'application/pdf' : '';
	const imageAttachment =
		attachments.length > 0
			? attachments[0].mime === 'image/jpeg' ||
			  attachments[0].mime === 'image/png'
			: '';

	const onBranchSelect = (selectedOption) => {
		setFieldTouched('branch', true);
		if (selectedOption) {
			return setFieldValue('branch', selectedOption.value);
		}

		return setFieldValue('branch', null);
	};

	const getCityDisplay = () => {
		return (
			<CityMunicipalityDropdown
				onChange={setCity}
				value={values.cityId}
			/>
		);
	};

	const getBranchDisplay = () => {
		let deliveryAddress = null;
		let cityId = null;
		if (values.deliveryAddress) {
			deliveryAddress = {
				lat: values.deliveryAddress.geo.coordinates[1],
				lng: values.deliveryAddress.geo.coordinates[0],
			};
			cityId = values.deliveryAddress.cityId;
		} else if (patient.address) {
			deliveryAddress = {
				lat: patient.address.geo.coordinates[1],
				lng: patient.address.geo.coordinates[0],
			};
			cityId = patient.address.cityId;
		}

		if (city) {
			cityId = city.value;
		}

		if (
			role === ADMIN.key ||
			role === SUPER_ADMIN.key ||
			role === CALL_CENTER.key
		) {
			return (
				<>
					<BranchDropdown
						cityId={cityId}
						deliveryAddress={deliveryAddress}
						forceCity={Boolean(city)}
						onChange={onBranchSelect}
						value={get(values, 'branch', null)}
						vendor={vendor.id}
					/>
				</>
			);
		}

		return <BranchInfo id={values.branch} />;
	};

	let displayAddress = '';

	if (patient && patient.address) {
		displayAddress = get(patient.address, 'line1', '');
		displayAddress += `${
			get(patient.address, 'brgy', '') ? ' Brgy. ' : ''
		}${get(patient.address, 'brgy', '')}`;
		displayAddress += displayAddress !== '' ? ', ' : '';
		displayAddress += get(patient.address, 'city', '');
	}

	const title = t('prescriptions.code', {
		code,
	});

	// if (prescription.packingCode) {
	// 	title += ` - ${prescription.packingCode}`;
	// }

	const renderRxStatus = () => {
		if (
			(!vendor.orderingEnabled && role === PHARMACIST.key) ||
			role === CALL_CENTER.key
		) {
			return (
				<PrescriptionStatusDropdown
					assigned={values.branch}
					datetime={values.receivedDate}
					onChange={handlePrescriptionStatus}
					role={role}
					selected={status}
				/>
			);
		}
		return (
			<RxStatus
				assigned={values.branch}
				datetime={values.receivedDate}
				role={role}
				status={status}
			/>
		);
	};

	const renderShippingDetails = () => {
		if (
			prescription.preferredPickupType === 'delivery' ||
			prescription.preferredPickupType === 'grab'
		) {
			let listItems = [];

			if (prescription.receiver) {
				listItems = [
					{
						title: 'Name',
						description: (
							<>
								{prescription.receiver.firstName}{' '}
								{prescription.receiver.lastName}
							</>
						),
					},
					{
						title: 'Contact Number',
						description: prescription.receiver.mobile,
					},
					{
						title: 'Address',
						description: (
							<>
								{prescription.receiver.address?.line1 ?? ''}{' '}
								{prescription.receiver.address?.line2 ?? ''}
								{prescription.receiver.address?.brgy ?? ''}{' '}
								{prescription.receiver.address?.city ?? ''}
								{prescription.receiver.address?.province ?? ''}
								{prescription.receiver.address?.region ?? ''}
							</>
						),
					},
				];
			} else {
				listItems = [
					{
						title: 'Name',
						description: (
							<>
								{patient.firstName} {patient.lastName}
							</>
						),
					},
					{
						title: 'Contact Number',
						description: patient.mobile,
					},
					{
						title: 'Address',
						description: (
							<>
								{patient.address?.line1 ?? ''}{' '}
								{patient.address?.line2 ?? ''}
								{patient.address?.brgy ?? ''}{' '}
								{patient.address?.city ?? ''}
								{patient.address?.province ?? ''}{' '}
								{patient.address?.region ?? ''}
							</>
						),
					},
				];
			}

			return (
				<>
					<EuiText>
						<strong>Shipping Details</strong>
					</EuiText>
					<EuiPageSection>
						<EuiDescriptionList
							listItems={listItems}
							type="responsiveColumn"
						/>
					</EuiPageSection>
				</>
			);
		}

		return '';
	};

	const handleChangePaymentMethod = (data) => {
		setFieldTouched('paymentMethod', true);
		handleChange(data);
	};

	useEffect(() => {
		if (patient?.email) {
			setContactDetails(() => [
				...contactDetails,
				{
					type: 'email',
					value: patient?.email,
				},
			]);
		}

		if (patient?.mobile) {
			setContactDetails(() => [
				...contactDetails,
				{
					type: 'mobile',
					value: patient?.mobile,
				},
			]);
		}
	}, [patient]);

	let prescriptionDetails = [
		{
			title: 'Patient',
			description:
				(patient &&
					(role === SECRETARY.key ? (
						<Link to={`/patients/${patient.id}`}>
							<EuiLink>{`${patient.firstName} ${patient.lastName}`}</EuiLink>
						</Link>
					) : (
						`${patient.firstName} ${patient.lastName}`
					))) ||
				null,
		},
		{
			title: 'Address',
			description: patient.address
				? get(patient, 'address.localAddress', displayAddress)
				: 'N/A',
		},
		{
			title: 'Patient Contact Details',
			description: !isEmpty(contactDetails) ? (
				<ContactDetails ids={contactDetails} plain />
			) : (
				'N/A'
			),
		},
	];

	if (prescription.requestor !== 'patient') {
		prescriptionDetails.push({
			title: 'Doctor Contact Details',
			description:
				doctor && doctor.contactDetails ? (
					<ContactDetails
						hideEmails={
							role !== DOCTOR.key || role === SECRETARY.key
						}
						ids={doctor.contactDetails}
						plain
					/>
				) : (
					'N/A'
				),
		});
	}

	prescriptionDetails = prescriptionDetails.concat([
		{
			title: 'Date',
			description: moment(
				values.receivedDate ? values.receivedDate : values.createdAt,
			).format('lll'),
		},
		{
			title: 'Status',
			description: renderRxStatus(),
		},
		{
			title: 'Preferred Branch',
			description: <BranchInfo id={values.preferredBranch} />,
		},
		// {
		// 	title: 'Filter',
		// 	description: <RangeSelector />,
		// },
	]);

	if (role === CALL_CENTER.key) {
		prescriptionDetails.push({
			title: 'Filter by City',
			description: getCityDisplay(),
		});
	}

	prescriptionDetails.push(
		{
			title: 'Assigned Branch',
			description: getBranchDisplay(),
		},
		{
			title: 'Prescription Fulfillment',
			description: (
				<RxFulfillmentDropdown readOnly value={values.fulfillment} />
			),
		},
		{
			title: 'Payment Method',
			description: (
				<PaymentMethodDropdown
					data-testid="paymentMethod"
					id="paymentMethod"
					name="paymentMethod"
					onChange={handleChangePaymentMethod}
					readOnly={role !== CALL_CENTER.key}
					value={values.paymentMethod}
				/>
			),
		},
		{
			title: 'Pickup Type',
			description:
				prescription.preferredPickupType?.charAt(0).toUpperCase() +
				prescription.preferredPickupType?.slice(1),
		},
	);

	if (values.patientNotes) {
		prescriptionDetails.push({
			title: 'Note to Pharmacist',
			description: <EuiTextArea readOnly value={values.patientNotes} />,
		});
	}

	if (prescription.requestor !== 'patient' && role === PHARMACIST.key) {
		prescriptionDetails = [
			{
				title: 'Doctor',
				description: doctor ? (
					<DoctorQuickView doctorId={doctor.id} />
				) : null,
			},
			...prescriptionDetails,
		];
	}

	if (role === SECRETARY.key) {
		prescriptionDetails = [
			{
				title: 'Pharmacy',
				description: vendor.name || null,
			},
			...prescriptionDetails,
		];
	}

	const renderFilesSlider = (data, name, label) => {
		if (data && data.length) {
			return (
				<>
					<EuiText>
						<strong>{label}</strong>
					</EuiText>
					<EuiSpacer />
					<FilesSlider
						data={data}
						name={name}
						onChange={({ value }) =>
							setFieldValue(name, [...value])
						}
					/>
				</>
			);
		}
		return null;
	};
	const tabs = [
		{
			id: 'info',
			name: 'Info',
			content: (
				<div>
					<EuiSpacer />
					<EuiDescriptionList
						listItems={prescriptionDetails}
						type="responsiveColumn"
					/>
					<EuiSpacer />
					{renderShippingDetails()}
					{renderFilesSlider(
						values.attachments,
						'attachments',
						'Attachments',
					)}
					{renderFilesSlider(
						values.additionalAttachments,
						'additionalAttachments',
						'AdditionalAttachments',
					)}
				</div>
			),
		},
		{
			id: 'rx-chatbox',
			name: 'Chat Box',
			content: <ChatBox params={{ prescription: prescription.id }} />,
		},
	];

	if (role === PHARMACIST.key && vendor.orderingEnabled) {
		tabs.push({
			id: 'order',
			name: 'Order History',
			disabled: !prescription.orders || !prescription.orders.length,
			content: <PrescriptionOrders rxId={prescription.id} />,
		});
	}

	if (role === CALL_CENTER.key) {
		tabs.push({
			id: 'prescription-transfer-history',
			name: 'Transfer History',
			content: <TransferHistory rxId={prescription.id} />,
		});
	}

	const handleConfirmCancelChanges = () => {
		resetForm({ values: initialValues });
		setTouched({});
		setHasChanges(false);
		setIsConfirmCancelChangesModalVisible(false);
	};

	const renderPrescriptionItemForm = () => {
		if (
			isServed ||
			(role === PHARMACIST.key &&
				!values.branch &&
				values.preferredBranch)
		) {
			return null;
		}

		return (
			<PrescriptionItemForm
				formType={ADD_PRESCRIPTION}
				id="prescriptionItems"
				isEditable={!isServed}
				isVisible
				name="prescriptonItems"
				onSubmit={handleRxItemsSubmit}
				prescriptionItems={prescriptionItems}
			/>
		);
	};

	const getCreateOrderButtonDisabledMessage = () => {
		if (isServed) {
			return 'Order has been served';
		}

		if (vendor.requireRxEncoding && !isEncoded) {
			return 'You have no Prescription Items';
		}

		if (!values.branch) {
			return 'No branch assigned';
		}

		if (hasChanges) {
			return 'Finish unsaved changes';
		}

		return 'Prepare Order';
	};

	if (!prescription) {
		return null;
	}

	return (
		<Page
			headerRight={
				<>
					<EuiShowFor sizes={['xl', 'l', 'm']}>
						<EuiFlexGroup direction="row" gutterSize="l">
							{(role === PHARMACIST.key ||
								role === CALL_CENTER.key) && (
								<EuiFlexItem>
									<Print>
										{imageAttachment && (
											<EuiFlexItem
												style={{
													alignItems: 'center',
												}}
											>
												<EuiImage
													allowFullScreen
													alt={attachments[0].name}
													hasShadow
													url={attachments[0].url}
												/>
											</EuiFlexItem>
										)}
										{pdfAttachment && (
											<Document
												externalLinkTarget
												file={attachments[0].url}
												onLoadSuccess={
													onDocumentLoadSuccess
												}
											>
												<DocumentPage
													pageNumber={numPages}
													width="100%"
												/>
											</Document>
										)}
									</Print>
								</EuiFlexItem>
							)}
							{!isServed && (
								<EuiFlexItem>
									<EuiButtonEmpty
										color="danger"
										disabled={isLoading || !hasChanges}
										iconType="crossInACircleFilled"
										onClick={() =>
											setIsConfirmCancelChangesModalVisible(
												true,
											)
										}
									>
										{t('general.cancel')}
									</EuiButtonEmpty>
								</EuiFlexItem>
							)}
							{(!isServed && !vendor.orderingEnabled) || (
								<>
									<EuiFlexItem>
										<EuiButton
											disabled={
												isLoading ||
												isUpdating ||
												isUpdatingStatus ||
												!hasChanges
											}
											fill
											iconType="checkInCircleFilled"
											isLoading={
												isUpdating || isUpdatingStatus
											}
											onClick={handleSubmit}
										>
											{t('general.saveChanges')}
										</EuiButton>
									</EuiFlexItem>
									<CreateOrderButton
										disabled={
											isServed ||
											isCancelled ||
											isLoading ||
											isUpdating ||
											hasChanges ||
											(vendor.requireRxEncoding &&
												!isEncoded) ||
											!values.branch ||
											(!values.branch &&
												values.preferredBranch)
										}
										onClick={() => {
											if (status === 'received') {
												mutateRxStatus({
													id: prescriptionId,
													value: 'processing',
												});
											}
										}}
										prescription={prescription}
										toolTipContent={getCreateOrderButtonDisabledMessage()}
									/>
								</>
							)}
						</EuiFlexGroup>
					</EuiShowFor>
					<EuiShowFor sizes={['s', 'xs']}>
						<EuiFlexGrid columns={4} responsive={false}>
							{(role === PHARMACIST.key ||
								role === CALL_CENTER.key) && (
								<EuiFlexItem style={{ flexGrow: 1 }}>
									<Print size="s">
										{imageAttachment && (
											<EuiFlexItem
												style={{ alignItems: 'center' }}
											>
												<EuiImage
													allowFullScreen
													alt={attachments[0].name}
													hasShadow
													url={attachments[0].url}
												/>
											</EuiFlexItem>
										)}
										{pdfAttachment && (
											<Document
												externalLinkTarget
												file={attachments[0].url}
												onLoadSuccess={
													onDocumentLoadSuccess
												}
											>
												<DocumentPage
													pageNumber={numPages}
													width="500"
												/>
											</Document>
										)}
									</Print>
								</EuiFlexItem>
							)}
							{!isServed && (
								<>
									<EuiFlexItem style={{ flexGrow: 1 }}>
										<EuiButtonEmpty
											color="danger"
											disabled={isLoading || hasChanges}
											onClick={() =>
												setIsConfirmCancelChangesModalVisible(
													true,
												)
											}
											size="xs"
										>
											{t('general.cancel')}
										</EuiButtonEmpty>
									</EuiFlexItem>
									<EuiFlexItem style={{ flexGrow: 1 }}>
										<EuiButtonEmpty
											disabled={isLoading || !hasChanges}
											isLoading={isLoading}
											onClick={handleSubmit}
											size="xs"
										>
											{t('general.saveChanges')}
										</EuiButtonEmpty>
									</EuiFlexItem>
									<EuiFlexItem style={{ flexGrow: 1 }}>
										<CreateOrderButton
											disabled={
												isServed ||
												isLoading ||
												isUpdating ||
												hasChanges ||
												(vendor.requireRxEncoding &&
													!isEncoded) ||
												!values.branch ||
												(!values.branch &&
													values.preferredBranch)
											}
											prescription={prescription}
											size="s"
											toolTipContent={getCreateOrderButtonDisabledMessage()}
										/>
									</EuiFlexItem>
								</>
							)}
						</EuiFlexGrid>
					</EuiShowFor>
				</>
			}
			sidebar={
				<EuiTabbedContent
					autoFocus="selected"
					expand
					initialSelectedTab={tabs[0]}
					tabs={tabs}
				/>
			}
			stickyHeader
			title={title}
		>
			{renderPrescriptionItemForm()}
			<ConfirmationModal
				isVisible={prescriptionStatus.isConfirmationModalOpen}
				message={
					<EuiText>
						Are you sure you want to change the status into{' '}
						<RxStatus
							assigned={values.branch}
							datetime={values.receivedDate}
							role={role}
							status={prescriptionStatus.value}
						/>
					</EuiText>
				}
				onClose={handleCancelChangePrescriptionStatus}
				onConfirm={handleConfirmChangePrescriptionStatus}
				title="Update Status"
			/>
			<ConfirmationModal
				isVisible={isConfirmCancelChangesModalVisible}
				message="Cancel Changes?"
				onClose={() => {
					setIsConfirmCancelChangesModalVisible(false);
				}}
				onConfirm={handleConfirmCancelChanges}
				title="Confirm"
			/>
		</Page>
	);
};

export default Prescription;
