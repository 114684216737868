import api from 'utils/api';

export const countErx = (ctx) => {
	const { queryKey } = ctx;
	const [, params] = queryKey;

	return api
		.get('/prescriptions/count', {
			params,
		})
		.then(({ data }) => data);
};

export const countOrders = (ctx) => {
	const { queryKey } = ctx;
	const [, params] = queryKey;

	return api
		.get('/orders/count', {
			params,
		})
		.then(({ data }) => data);
};

export const countPatients = (ctx) => {
	const { queryKey } = ctx;
	const [, params] = queryKey;

	return api
		.get('/patients/count', {
			params,
		})
		.then(({ data }) => data);
};

export const countAccumulatedPoints = () => {
	return api.get('/accumulate-points/user/total').then(({ data }) => data);
};
