import api from 'utils/api';

export const getAverageRating = (advertisementId) => {
	return api.get(`/advertisement-ratings/${advertisementId}`);
};

export const postRateAdvertisement = async (data) =>
	api.post(`/advertisement-ratings`, data);

export const getRatingByUser = (advertisementId) => {
	return api.get(`/advertisement-ratings/${advertisementId}/user-rating`);
};

export const postAccumulatePts = async (data) => {
	return api.post('/accumulate-points', data);
};

export const getTotalUserAccumulatedPoints = async () => {
	return api.get('/accumulate-points/user/total');
};
