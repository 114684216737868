import { EuiCard, EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import React from 'react';
import { useQuery } from 'react-query';
import { countAccumulatedPoints } from 'modules/dashboard/dashboard.fetch';
import PointsLogo from '../../../../assets/images/points-logo.png';
import NowNaLogo from '../../../../assets/images/nowna-logo.png';

const PointsCard = () => {
	const { data: accumulatedPoints } = useQuery([''], countAccumulatedPoints, {
		placeholderData: {
			totalPoints: 0,
		},
	});

	return (
		<EuiFlexGroup gutterSize="l" justifyContent="center" responsive={false}>
			<EuiFlexItem>
				<a
					alt="redeem link"
					href="https://nowna.com.ph/"
					rel="noreferrer"
					target="_blank"
				>
					<EuiCard
						onClick={() => {}}
						style={{
							backgroundColor: '#fde4c7',
							paddingTop: 0,
						}}
					>
						<img
							alt="points logo"
							src={PointsLogo}
							style={{
								height: '30px',
								width: '30px',
								marginTop: 0,
								marginBottom: '5px',
							}}
						/>
						<div
							style={{
								color: '#f89826',
								marginBottom: '5px',
								textDecoration: 'underline',
							}}
						>
							{accumulatedPoints?.totalPoints} <br />
							Points Earned
						</div>
					</EuiCard>
				</a>
			</EuiFlexItem>
			<EuiFlexItem>
				<a
					alt="shop link"
					href="https://nowna.com.ph/"
					rel="noreferrer"
					target="_blank"
				>
					<EuiCard
						onClick={() => {}}
						style={{
							backgroundColor: '#bfe4f7',
							paddingTop: 0,
						}}
					>
						<img
							alt="now na logo"
							src={NowNaLogo}
							style={{
								height: '30px',
								width: '30px',
								marginTop: 0,
								marginBottom: '5px',
							}}
						/>
						<div
							style={{
								color: '#016ba3',
								marginBottom: '5px',
								textDecoration: 'underline',
							}}
						>
							Shop Now at <br />
							NowNa Store
						</div>
					</EuiCard>
				</a>
			</EuiFlexItem>
		</EuiFlexGroup>
	);
};

export default PointsCard;
