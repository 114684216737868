import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import { useTranslation } from 'react-i18next';

import { EuiPage, EuiPageBody, EuiPanel } from '@elastic/eui';
import _ from 'lodash';

import Page from 'components/Page';
import AddRxButton from 'modules/prescriptions/components/AddRxButton';
import Statistic from 'modules/_global/Statistics';
import { ROLE_PATH, TAGS_PATH, SECRETARY_TAGS_PATH } from 'constants/protect';
import { DOCTOR, SECRETARY, PHARMACIST, CALL_CENTER } from 'components/roles';
import DoctorStatistics from 'modules/_global/DoctorStatistics';
import DoctorDashboardContent from './DoctorDashboardContent';
import AdsModal from './components/Advertisements/AdsModal';

export default function Dashboard() {
	const { t } = useTranslation();
	const role = useSelector((state) => _.get(state, ROLE_PATH));
	const tagsPath = role === DOCTOR.key ? TAGS_PATH : SECRETARY_TAGS_PATH;
	const tags = useSelector((state) => _.get(state, tagsPath));

	const { REACT_APP_ADS_URL } = process.env;

	const renderDashboardDisplay = () => {
		let content = null;
		if (role === DOCTOR.key || role === SECRETARY.key) {
			window.addEventListener(
				'message',
				(e) => {
					const data = e && e.data !== null ? e.data : '';

					if (
						data.event_id !== undefined &&
						(data.event_id === 'adsData' ||
							data.event_id === 'adsDataRating')
					) {
						const itemName =
							data.event_id === 'adsData'
								? 'data'
								: 'dataForRating';

						window.localStorage.setItem(
							itemName,
							JSON.stringify(data),
						);

						window.location.reload(true);
					}
				},
				false,
			);
		}

		if (
			(role === DOCTOR.key || SECRETARY.key) &&
			role !== PHARMACIST.key &&
			role !== CALL_CENTER.key
		) {
			let adsUrl = `${REACT_APP_ADS_URL}/advertisements`;
			if (tags?.length) {
				adsUrl = `${adsUrl}?tags=${tags
					.map((tag) => tag.tag)
					.join(',')}`;
			}

			content = (
				<EuiPage>
					<EuiPageBody>
						{role === DOCTOR.key ? <DoctorStatistics /> : null}
						<EuiPanel>
							<iframe
								id="iframe"
								src={adsUrl}
								style={{
									height: 'calc(100vh - 129px)',
									width: '100%',
								}}
								title="Advertisement"
							/>
						</EuiPanel>
					</EuiPageBody>
					<AdsModal />
				</EuiPage>
			);
		}

		if (!content) {
			content = (
				<Page
					headerRight={<AddRxButton dashboard />}
					title={t('dashboard.title')}
				>
					<DoctorDashboardContent />
					<Statistic />
				</Page>
			);
		}

		return content;
	};

	return (
		<>
			<Helmet title="Home" />
			{renderDashboardDisplay()}
		</>
	);
}
