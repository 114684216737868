/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { EuiFlexItem } from '@elastic/eui';
import { FaFilePrescription } from 'react-icons/fa';
import { ROLE_PATH } from 'constants/protect';
import { PATIENT } from 'components/roles';
import protectHOC from 'components/protectHOC';
import PropTypes from 'prop-types';

const AddRxPatientButton = ({ visible }) => {
	const history = useHistory();

	if (!visible) {
		return null;
	}

	return (
		<>
			<EuiFlexItem>
				<a
					className="floating-rx-button"
					href="#"
					id="rxButton"
					onClick={() => {
						history.push({
							pathname: '/my-orders/create',
						});
					}}
					style={{
						backgroundColor: 'primaryColor',
						marginBottom: '30px',
					}}
				>
					<FaFilePrescription
						className="add-rx-button"
						color="ghost"
						size={30}
					/>
				</a>
			</EuiFlexItem>
		</>
	);
};

AddRxPatientButton.propTypes = {
	visible: PropTypes.bool,
};

AddRxPatientButton.defaultProps = {
	visible: true,
};

export default protectHOC([PATIENT.key], ROLE_PATH)(AddRxPatientButton);
