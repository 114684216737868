import React, { useCallback, useEffect, useState } from 'react';
import { Rating } from 'react-simple-star-rating';
import PropType from 'prop-types';
import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import { getAverageRating, getRatingByUser } from './advertisement.fetch';
import AdsRate from './AdsRate';

const AdsRating = ({ advertisementData }) => {
	const [userRateVisible, setUserRateVisible] = useState(false);
	const [userRatingValue, setUserRatingValue] = useState(null);
	const [averageRating, setAverageRating] = useState(null);

	const accumulatePoints = advertisementData?.accumulatePoints
		? JSON.parse(advertisementData?.accumulatePoints)
		: null;

	const fetchAverageRating = useCallback(async () => {
		try {
			const averageRatingData = await getAverageRating(
				advertisementData?.advertisementId,
			);

			setAverageRating(averageRatingData?.data?.averageRating);
		} catch (err) {
			// ignore
		}
	}, [advertisementData?.advertisementId]);

	const fetchRatingByUser = useCallback(async () => {
		try {
			const userRatingData = await getRatingByUser(
				advertisementData?.advertisementId,
			);

			setUserRatingValue(userRatingData?.data?.rating);
		} catch (err) {
			// ignore
		}
	}, [advertisementData?.advertisementId]);

	const handleRefetchData = () => {
		fetchAverageRating();
		fetchRatingByUser();
	};

	useEffect(() => {
		handleRefetchData();
	}, []);

	return (
		<>
			<EuiFlexGroup alignItems="center" grow={false} gutterSize="s">
				<EuiFlexItem>
					<Rating
						allowHalfIcon
						disableFillHover
						initialValue={averageRating}
						readonly
						showTooltip
						size={25}
						tooltipDefaultText={`${averageRating ?? 0}`}
					/>
				</EuiFlexItem>
				<EuiFlexItem grow={false}>
					{userRatingValue ? (
						`You rated it with ${userRatingValue} star${
							userRatingValue > 1 ? 's' : ''
						}`
					) : (
						<EuiButtonEmpty
							grow={false}
							onClick={() => {
								setUserRateVisible(true);
							}}
							size="s"
						>
							How would you rate this?
						</EuiButtonEmpty>
					)}
				</EuiFlexItem>
			</EuiFlexGroup>
			<AdsRate
				advertisementId={advertisementData?.advertisementId}
				onRefetchData={handleRefetchData}
				onSetVisibility={setUserRateVisible}
				pointsForRating={accumulatePoints?.rating ?? 0}
				title={advertisementData?.title}
				visible={userRateVisible}
			/>
		</>
	);
};

AdsRating.propTypes = {
	advertisementData: PropType.instanceOf(Object).isRequired,
};

export default AdsRating;
