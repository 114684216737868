import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useParams } from 'react-router';

import { EuiSpacer, EuiHorizontalRule } from '@elastic/eui';

import { get } from 'lodash';
import * as yup from 'yup';
import moment from 'moment';

import NoProfilePhoto from 'assets/images/no-profile-photo.png';
import ConfirmationModal from 'components/ConfirmationModal';
import PersonalInfomationForm from 'components/PersonalInfomationForm';
import AddressForm from 'components/AddressForm';
import ProfilePage from 'components/ProfilePage';
import TabLabel from 'components/TabLabel';

import { addToast } from '../toasts/toasts.actions';
import { getPatient, updatePatient } from './patients.fetch';

const PatientSchema = yup.object().shape(
	{
		firstName: yup.string().required('First Name required'),
		lastName: yup.string().required('Last Name required'),
		email: yup.string().email('Invalid email'),
		mobile: yup
			.number()
			.typeError('Invalid mobile number')
			.required('Mobile number is required'),
		addressTab: yup.string().when(['line1', 'brgyId', 'cityId'], {
			is: (line1, brgyId, cityId) => !!cityId !== true,
			then: yup
				.string()
				.when('line1', {
					is: (line1) => !!line1 === true,
					then: yup.string().required('Street address is required'),
				})
				.when('brgyId', {
					is: (brgyId) => !!brgyId === true,
					then: yup.string().required('Brgy. address is required'),
				}),
		}),
		line1: yup.string().when(['brgyId'], {
			is: (brgyId) => !!brgyId === true,
			then: yup.string().required('Address Line 1 is required'),
		}),
		brgyId: yup
			.string()
			.when(['line1', 'cityId'], {
				is: (line1) => !!line1 === true,
				then: yup.string().required('Barangay is required'),
			})
			.nullable(),
		cityId: yup
			.string()
			.when(['line1', 'brgyId'], {
				is: (line1, brgyId) => !!line1 === true || !!brgyId === true,
				then: yup.string().required('City is required'),
			})
			.nullable(),
	},
	[
		['line1', 'brgyId'],
		['line1', 'cityId'],
		['brgyId', 'cityId'],
	],
);

const Patient = () => {
	// const { t } = useTranslation();
	const { patientId } = useParams();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(true);
	const [patient, setPatient] = useState({});

	const [isConfirmationModalVisible, setConfirModalVisible] = useState(false);

	const formikBag = useFormik({
		initialValues: {
			firstName: patient.firstName,
			middleName: patient.middleName,
			lastName: patient.lastName,
			birthdate: patient.birthdate || null,
			email:
				(typeof patient?.email === 'object'
					? patient?.email?.value
					: patient?.email) || '',
			mobile:
				(typeof patient?.mobile === 'object'
					? patient?.mobile?.value
					: patient?.mobile) || '',
			attachments: patient.attachments || {
				url: NoProfilePhoto,
			},
			addressId: get(patient, 'address.id', ''),
			line1: get(patient, 'address.line1', ''),
			line2: get(patient, 'address.line2', ''),
			city: get(patient, 'address.city', ''),
			cityId: get(patient, 'address.cityId', ''),
			brgy: get(patient, 'address.brgy', ''),
			brgyId: get(patient, 'address.brgyId', ''),
		},
		validationSchema: PatientSchema,
		validateOnBlur: true,
		enableReinitialize: true,
		onSubmit: async (data, { resetForm: reset }) => {
			setIsLoading(true);
			try {
				const payload = {
					firstName: data.firstName,
					middleName: data.middleName,
					lastName: data.lastName,
					birthdate: moment(data.birthdate).format('YYYY-MM-DD'),
					address: {
						line1: data.line1,
						line2: data.line2,
						city: data.city,
						cityId: data.cityId,
						brgy: data.brgy,
						brgyId: data.brgyId,
					},
					email: data.email,
					mobile: data.mobile,
				};

				if (data.addressId) {
					payload.address.id = data.addressId;
				}

				const result = await updatePatient(patientId, payload);

				setPatient(result.data);

				dispatch(
					addToast(
						'Profile updated',
						'Changes Saved',
						'success',
						'check',
					),
				);
			} catch (err) {
				dispatch(
					addToast(
						'Error',
						err.message || 'Something went wrong/',
						'danger',
						'help',
					),
				);

				reset();
			} finally {
				setIsLoading(false);
			}
		},
	});

	const { dirty, errors, handleSubmit, initialValues, resetForm, touched } =
		formikBag;

	useEffect(() => {
		async function fetchPatient() {
			try {
				const { data } = await getPatient(patientId);
				setPatient(data);
			} catch (err) {
				dispatch(addToast('Error', err, 'danger', 'help'));
			} finally {
				setIsLoading(false);
			}
		}
		fetchPatient();
	}, [patientId]);

	const handleToggleConfirmModal = () => {
		setConfirModalVisible((isOpen) => !isOpen);
	};

	const handleConfirmModal = () => {
		resetForm({ values: initialValues });
		handleToggleConfirmModal();
	};

	const tabs = [
		{
			id: 'profileTab',
			name: (
				<TabLabel
					isInvalid={
						(touched.firstName && !!errors.firstName) ||
						(touched.lastName && !!errors.lastName)
					}
					text="Profile"
				/>
			),
			content: (
				<>
					<EuiSpacer />
					<PersonalInfomationForm formikBag={formikBag} />
					<EuiHorizontalRule margin="l" />
				</>
			),
		},
		{
			id: 'addressTab',
			name: (
				<TabLabel
					isInvalid={
						(touched.line1 && !!errors.line1) ||
						(touched.brgyId && !!errors.brgyId) ||
						(touched.cityId && !!errors.cityId)
					}
					text="Address"
				/>
			),
			content: (
				<>
					<EuiSpacer />
					<AddressForm formikBag={formikBag} />
					<EuiHorizontalRule margin="l" />
				</>
			),
		},
	];

	return (
		<>
			<ProfilePage
				data={patient}
				hasChanges={dirty}
				initialSelectedTab={0}
				isLoading={isLoading}
				onCancel={handleToggleConfirmModal}
				onSubmit={handleSubmit}
				tabs={tabs}
				title="Patient"
			/>
			<ConfirmationModal
				isVisible={isConfirmationModalVisible}
				message="Cancel Changes?"
				onClose={handleToggleConfirmModal}
				onConfirm={handleConfirmModal}
				title="Confirm"
			/>
		</>
	);
};

export default Patient;
