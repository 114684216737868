import React from 'react';
import { useHistory } from 'react-router';
import {
	EuiOverlayMask,
	EuiModal,
	EuiModalBody,
	EuiButton,
	EuiFlexGroup,
	EuiFlexItem,
	EuiTitle,
	EuiSpacer,
	EuiText,
	EuiHorizontalRule,
} from '@elastic/eui';
import PropTypes from 'prop-types';

const RemindersModal = ({ isOpen, rxCode }) => {
	const history = useHistory();

	const setSchedule = () => {
		history.push({
			pathname: '/my-orders/create/schedule',
			state: {
				rxCode,
			},
		});
	};

	const backToDashboard = () => {
		history.push('/');
	};

	if (!isOpen) return null;

	return (
		<EuiOverlayMask>
			<EuiModal onClose={backToDashboard}>
				<EuiModalBody>
					<EuiSpacer />
					<EuiTitle size="m">
						<span>Order Placed!</span>
					</EuiTitle>
					<EuiSpacer />
					<EuiFlexGroup direction="column">
						<EuiText>
							Thank you for placing your order with us. Our
							partner pharmacy will conduct a thorough check of
							product availability and inform you accordingly.
						</EuiText>
						<EuiText>
							If all the requested products are available, you
							will receive a notification with payment
							instructions.
						</EuiText>
						<EuiText>
							However, if some or all products are unavailable,
							our team will promptly contact you to discuss
							possible alternatives.
						</EuiText>
						<EuiText style={{ color: 'gray', fontStyle: 'italic' }}>
							Would you like to set up a Maintainance Medicine /
							Recurring Order Reminder?
						</EuiText>
					</EuiFlexGroup>
					<EuiHorizontalRule />
					<EuiFlexGroup>
						<EuiFlexItem>
							<EuiButton onClick={backToDashboard}>
								Go Home
							</EuiButton>
						</EuiFlexItem>
						<EuiFlexItem>
							<EuiButton fill onClick={setSchedule}>
								Set Reminder
							</EuiButton>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiModalBody>
			</EuiModal>
		</EuiOverlayMask>
	);
};

RemindersModal.propTypes = {
	isOpen: PropTypes.bool,
	rxCode: PropTypes.string.isRequired,
};

RemindersModal.defaultProps = {
	isOpen: false,
};

export default RemindersModal;
