/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
	EuiButton,
	EuiCallOut,
	EuiForm,
	EuiFormRow,
	EuiFieldText,
	EuiSpacer,
	EuiDatePicker,
	EuiTitle,
	EuiHorizontalRule,
	EuiTextColor,
	EuiAccordion,
} from '@elastic/eui';

import { useFormik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import AddressForm from 'components/AddressForm';
import { updatePatient } from 'modules/patients/patients.fetch';

import { getUserDetails } from 'modules/auth/auth.fetch';
import { updateUserDetails } from 'modules/auth/auth.actions';
import { formatDate } from 'utils/helpers';
import { addToast } from '../toasts/toasts.actions';

const SetupAccountSchema = Yup.object().shape(
	{
		firstName: Yup.string().required('First Name is required'),
		lastName: Yup.string().required('Last Name is required'),
		mobile: Yup.string()
			.matches(/^(09|\+639)\d{9}$/gm, 'Invalid Mobile Number.')
			.required('Mobile Number is required'),
		birthdate: Yup.date().required('Birth date is required'),
		cityId: Yup.string().required('City is required'),
		brgyId: Yup.string().nullable().required('Barangay is required'),
		line1: Yup.string().when('line2', {
			is: (line2) => !line2 || line2.trim() === '',
			then: Yup.string().required(
				'At least one address line is required',
			),
			otherwise: Yup.string(),
		}),
		line2: Yup.string().when('line1', {
			is: (line1) => !line1 || line1.trim() === '',
			then: Yup.string().required(
				'At least one address line is required',
			),
			otherwise: Yup.string(),
		}),
	},
	[['line1', 'line2']],
);

const PatientMyInfoForm = ({ onClose, user }) => {
	const dispatch = useDispatch();

	const [error, setError] = useState(null);
	const [birthdate, setBirthdate] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const onSubmit = async (values) => {
		setIsLoading(true);
		try {
			const payload = {
				firstName: values.firstName,
				middleName: values.middleName,
				lastName: values.lastName,
				mobile: values.mobile,
				birthdate: moment(values.birthdate).format('YYYY-MM-DD'),
				address:
					values.line1 || values.line2 || values.city || values.brgy
						? {
								line1: values.line1,
								line2: values.line2,
								city: values.city,
								cityId: values.cityId,
								brgy: values.brgy,
								brgyId: values.brgyId,
						  }
						: null,
			};

			if (values.addressId) {
				payload.address.id = values.addressId;
			}

			await updatePatient(user.id, payload);
			const updatedUser = await getUserDetails();
			dispatch(
				updateUserDetails({
					...updatedUser.data,
					...updatedUser.data.details,
				}),
			);

			dispatch(
				addToast(
					'Success',
					'Successfully saved changes',
					'success',
					'check',
				),
			);
		} catch (err) {
			setError(err);
			dispatch(
				addToast(
					'Error',
					err.message || 'Something went wrong/',
					'danger',
					'help',
				),
			);
			onClose();
		} finally {
			setIsLoading(false);
		}
	};

	// Setup Formik
	const formik = useFormik({
		initialValues: {
			firstName: get(user, 'firstName') || '',
			lastName: get(user, 'lastName') || '',
			mobile: user?.mobile,
			birthdate: get(user, 'birthdate', null) || null,
			brgy: get(user, 'address.brgy') || '',
			brgyId: get(user, 'address.brgyId') || '',
			city: get(user, 'address.city') || '',
			cityId: get(user, 'address.cityId') || '',
			line1: get(user, 'address.line1') || '',
			line2: get(user, 'address.line2') || '',
		},
		validationSchema: SetupAccountSchema,
		validateOnBlur: true,
		onSubmit,
	});

	const {
		dirty,
		errors,
		handleChange,
		handleSubmit,
		setFieldValue,
		touched,
		values,
	} = formik;

	useEffect(() => {
		// eslint-disable-next-line consistent-return
		const listener = (event) => {
			if (event.code === 'Enter' || event.code === 'NumpadEnter') {
				return handleSubmit();
			}
		};
		document.addEventListener('keydown', listener);
		return () => {
			document.removeEventListener('keydown', listener);
		};
	}, []);

	useEffect(() => {
		if (birthdate && formatDate(birthdate) !== values.birthdate) {
			setFieldValue('birthdate', moment(birthdate).format('YYYY-MM-DD'));
		}
	}, [birthdate]);

	return (
		<>
			<EuiForm>
				<EuiTextColor color="subdued">
					<small>
						Required to confirm ownership of this account.
					</small>
				</EuiTextColor>
				{error && (
					<>
						<EuiCallOut color="danger" size="s" title={error} />
						<EuiSpacer size="s" />
					</>
				)}
				<EuiSpacer size="s" />
				<EuiTitle size="xxs">
					<h4>PERSONAL INFO</h4>
				</EuiTitle>
				<EuiSpacer size="s" />

				<EuiFormRow
					error={errors.firstName}
					fullWidth
					isInvalid={touched.firstName && !!errors.firstName}
					label="First Name *"
				>
					<EuiFieldText
						data-testid="first-name-input"
						fullWidth
						id="firstName"
						isInvalid={touched.firstName && !!errors.firstName}
						name="firstName"
						onChange={handleChange}
						value={values.firstName}
					/>
				</EuiFormRow>
				<EuiFormRow
					error={errors.lastName}
					fullWidth
					isInvalid={touched.lastName && !!errors.lastName}
					label="Last Name *"
				>
					<EuiFieldText
						data-testid="last-name-input"
						fullWidth
						id="lastName"
						isInvalid={touched.lastName && !!errors.lastName}
						name="lastName"
						onChange={handleChange}
						value={values.lastName}
					/>
				</EuiFormRow>
				<EuiFormRow
					error={errors.mobile}
					fullWidth
					isInvalid={touched.mobile && !!errors.mobile}
					label="Mobile Number *"
				>
					<EuiFieldText
						data-testid="mobile-number-input"
						fullWidth
						id="mobile"
						isInvalid={touched.mobile && !!errors.mobile}
						name="mobile"
						onChange={handleChange}
						value={values.mobile}
					/>
				</EuiFormRow>
				<EuiFormRow
					error={errors.birthdate}
					fullWidth
					isInvalid={touched.birthdate && !!errors.birthdate}
					label="Birthdate *"
				>
					<EuiDatePicker
						data-testid="birthdate-input"
						id="birthdate"
						isInvalid={touched.birthdate && !!errors.birthdate}
						name="birthdate"
						onChange={(e) => setBirthdate(e)}
						selected={
							values.birthdate
								? moment(values.birthdate)
								: birthdate
						}
						yearDropdownItemNumber={50}
					/>
				</EuiFormRow>
				<EuiSpacer size="l" />

				<EuiHorizontalRule margin="s" />
				<EuiSpacer size="s" />
				<EuiAccordion
					buttonContent={
						<EuiTitle size="xxs">
							<h4>ADDRESS</h4>
						</EuiTitle>
					}
					id="accordion1"
					initialIsOpen={user && true}
				>
					<EuiSpacer size="s" />

					<AddressForm formikBag={formik} />
				</EuiAccordion>

				<EuiSpacer size="l" />
				<EuiFormRow fullWidth>
					<EuiButton
						color="primary"
						data-testid="login-button"
						disabled={!dirty}
						fill
						fullWidth
						isLoading={isLoading}
						onClick={handleSubmit}
						type="submit"
					>
						Save
					</EuiButton>
				</EuiFormRow>
			</EuiForm>
		</>
	);
};

PatientMyInfoForm.propTypes = {
	onClose: PropTypes.func.isRequired,
	user: PropTypes.instanceOf(Object).isRequired,
};

export default PatientMyInfoForm;
