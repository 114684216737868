// Auth Types
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const ACCEPT_SOFTWARE_CONTRACT = 'ACCEPT_SOFTWARE_CONTRACT';
export const REGISTER_PATIENT_SUCCESS = 'REGISTER_PATIENT_SUCCESS';
export const REGISTRATION_RX_REFERENCE = 'REGISTRATION_RX_REFERENCE';

// App Settings
export const APP_DOCK_NAVIGATION = 'APP_DOCK_NAVIGATION';
export const APP_UNDOCK_NAVIGATION = 'APP_UNDOCK_NAVIGATION';

// Toasts
export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';
export const CLEAR_TOASTS = 'CLEAR_TOASTS';

// Lightbox
export const SET_LIGHTBOX_DATA = 'SET_LIGHTBOX_DATA';
export const CLEAR_LIGHTBOX_DATA = 'CLEAR_LIGHTBOX_DATA';

// Prescriptions
export const CREATE_RX_SUCCESS = 'CREATE_RX_SUCCESS';
export const READ_RX_SUCCESS = 'READ_RX_SUCCESS';
export const UPDATE_RX_SUCCESS = 'UPDATE_RX_SUCCESS';
export const DELETE_RX_SUCCESS = 'DELETE_RX_SUCCESS';
export const DELETE_MULTIPLE_RX_SUCCESS = 'DELETE_MULTIPLE_RX_SUCCESS';
export const FIND_RX_SUCCESS = 'FIND_RX_SUCCESS';

// Send Prescription Modal
export const OPEN_SEND_RX_MODAL = 'OPEN_SEND_RX_MODAL';
export const CLOSE_SEND_RX_MODAL = 'CLOSE_SEND_RX_MODAL';

// Dashboard
export const GET_LATEST_RX_SUCCESS = 'GET_LATEST_RX_SUCCESS';
export const CREATE_RX_DASHBOARD_SUCCESS = 'CREATE_RX_DASHBOARD_SUCCESS';

// Patient
export const PATIENT_ME = 'PATIENT_ME';
export const PATIENT_SETUP_ACCOUNT = 'PATIENT_SETUP_ACCOUNT';
