import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	EuiButton,
	EuiForm,
	EuiFormRow,
	EuiFieldText,
	EuiLink,
	EuiModal,
	EuiModalBody,
	EuiModalFooter,
	EuiModalHeader,
	EuiModalHeaderTitle,
	EuiOverlayMask,
	EuiText,
	EuiComboBox,
	EuiFlexGroup,
	EuiFlexItem,
	EuiCheckbox,
	EuiSpacer,
	EuiTitle,
	// EuiRadioGroup,
	EuiHorizontalRule,
} from '@elastic/eui';
import { useFormik } from 'formik';
import * as yup from 'yup';
import _ from 'lodash';

import { addToast } from 'modules/toasts/toasts.actions';
import VendorDropdown from 'components/forms/VendorDropdown';
import BranchDropdown from 'components/forms/BranchDropdown';
import { buildLocalAddress } from 'utils/helpers';

import {
	getPrescription,
	sendRxToPatient,
	// updatePrescription,
} from './prescriptions.fetch';
import { updateRxSuccess } from './prescriptions.actions';
import { closeSendRxModal } from './sendRxModal.actions';

const SendRxModal = () => {
	const dispatch = useDispatch();
	const { auth, rxId, visible } = useSelector((state) => ({
		auth: state.auth,
		rxId: state.sendRxModal.rxId,
		visible: state.sendRxModal.visible,
	}));
	const { details } = auth.user;

	const [loading, setLoading] = useState(false);
	const [prescription, setPrescription] = useState(null);
	const [emailOptions, setEmailOptions] = useState([]);
	const [mobileOptions, setMobileOptions] = useState([]);
	const [selectedEmail, setSelectedEmail] = useState([]);
	const [selectedMobile, setSelectedMobile] = useState([]);
	const [email, setEmail] = useState(null);
	const [mobile, setMobile] = useState(null);
	const defaultVendor =
		_.get(details, 'defaultVendor.id') ||
		_.get(details, 'doctor.defaultVendor');
	// const orderOptions = [
	// 	{
	// 		id: 'delivery',
	// 		label: 'For Delivery',
	// 	},
	// 	{
	// 		id: 'pickup',
	// 		label: 'For Pick-up',
	// 	},
	// ];

	useEffect(() => {
		const getPrescriptionRecord = async () => {
			try {
				const { data } = await getPrescription(rxId);
				setPrescription(data);
			} catch (err) {
				dispatch(closeSendRxModal());
				dispatch(
					addToast('Prescription record not found', null, 'danger'),
				);
			}
		};

		if (rxId && visible) {
			getPrescriptionRecord();
		}
	}, [visible, rxId]);

	useEffect(() => {
		const getContactRecords = async () => {
			try {
				const patient = _.get(prescription, 'patient');

				let emailOpts = [];

				let mobileOpts = [];

				if (patient?.email) {
					emailOpts = [
						{
							label: patient?.email,
							value: patient?.email,
						},
					];
				}

				if (patient?.mobile) {
					mobileOpts = [
						{
							label: patient?.mobile,
							value: patient?.mobile,
						},
					];
				}

				setEmailOptions(emailOpts);
				setMobileOptions(mobileOpts);
			} catch (err) {
				dispatch(closeSendRxModal());
				dispatch(
					addToast('Prescription record not found', null, 'danger'),
				);
			}
		};

		if (rxId && visible && prescription) {
			getContactRecords();
		}
	}, [prescription, rxId, visible]);

	useEffect(() => {
		if (emailOptions.length) {
			setSelectedEmail([emailOptions[0]]);
			setEmail(emailOptions[0].value);
		}
		if (mobileOptions.length) {
			setSelectedMobile([mobileOptions[0]]);
			setMobile(mobileOptions[0].value);
		}
	}, [emailOptions, mobileOptions]);

	const getSuccessMessage = (patient) => {
		// const pharmacyName = _.get(rx, 'vendor.name', 'pharmacy');
		const patientName = `${patient.firstName} ${patient.middleName || ''} ${
			patient.lastName
		}`;

		// if (patient && pharma) {
		// 	return `Prescription sent to ${patientName} and ${pharmacyName}`;
		// }

		return `Prescription sent to ${patientName}`;

		// return `Prescription sent to ${pharmacyName}`;
	};

	const {
		errors,
		handleChange,
		handleSubmit,
		resetForm,
		setFieldValue,
		touched,
		values,
	} = useFormik({
		initialValues: {
			branch: null,
			email,
			mobile,
			pickup: false,
			sendToPharmacy: true,
			sendToPatient: false,
			vendor: null,
		},
		enableReinitialize: true,
		validationSchema: yup.object().shape({
			email: yup
				.string()
				.when('sendToPatient', {
					is: (value) => value === true,
					then: yup
						.string()
						.email('Invalid email')
						.required('Required'),
				})
				.nullable(),
			mobile: yup.string().when('sendToPharmacy', {
				is: (value) => value === true,
				then: yup
					.string()
					.matches(/^09\d{9}$/, 'Invalid Mobile Number')
					.required('Mobile is required'),
			}),
			pickup: yup.boolean().default(false),
			sendToPharmacy: yup.boolean().default(true),
			sendToPatient: yup.boolean().default(false),
		}),
		onSubmit: async (payload, { resetForm: reset }) => {
			if (!payload.sendToPharmacy && !payload.sendToPatient) {
				dispatch(closeSendRxModal());
				return;
			}

			try {
				setLoading(true);

				const {
					email: emailValue,
					mobile: mobileValue,
					sendToPatient,
					sendToPharmacy,
				} = payload;

				// let updatePayload = null;
				let emailPayload = null;
				let mobilePayload = null;
				let updatedRx = null;

				if (sendToPharmacy) {
					mobilePayload = { mobile: mobileValue };
				}

				if (sendToPatient) {
					emailPayload = { email: emailValue };
				}

				const { data: patientResult } = await sendRxToPatient(rxId, {
					...emailPayload,
					...mobilePayload,
					sendToPharmacy: payload.sendToPharmacy,
					sendToPatient: payload.sendToPatient,
				});
				updatedRx = patientResult;

				setLoading(false);
				reset();
				const successMessage = getSuccessMessage(updatedRx.patient);
				dispatch(updateRxSuccess(updatedRx));
				dispatch(addToast('Success', successMessage, 'success'));
				dispatch(closeSendRxModal());
				if (localStorage.getItem('data')) {
					localStorage.removeItem('data');
					localStorage.removeItem('sharePrescription');
					window.location.reload();
				}
				return;
			} catch (err) {
				dispatch(
					addToast(
						'Failed to Send Prescription',
						err.message || '',
						'danger',
					),
				);
			} finally {
				setLoading(false);
			}
		},
	});

	const hideModal = () => {
		setEmail(null);
		setMobile(null);
		resetForm();
		dispatch(closeSendRxModal());
	};

	const cancel = () => {
		dispatch(closeSendRxModal());
		if (localStorage.getItem('data')) {
			localStorage.removeItem('data');
			localStorage.removeItem('sharePrescription');
			window.location.reload();
		}
		hideModal();
	};

	const handleEmailDropDown = (e) => {
		const [selectedOption] = e;

		if (!selectedOption) {
			setFieldValue('email', '');
			return setSelectedEmail([]);
		}

		setFieldValue('email', selectedOption.value);

		return setSelectedEmail(e);
	};

	const handleMobileDropdown = (e) => {
		const [selectedOption] = e;

		if (!selectedOption) {
			setFieldValue('mobile', '');
			return setSelectedMobile([]);
		}

		setFieldValue('mobile', selectedOption.value);
		return setSelectedMobile(e);
	};

	const handleVendorChange = (value) => {
		if (value) {
			return setFieldValue('vendor', value);
		}

		return setFieldValue('vendor', '');
	};

	const setBranch = ({ value }) => setFieldValue('branch', value);

	// const togglePharmacy = () => {
	// 	if (!values.sendToPharmacy) {
	// 		setFieldValue('vendor', null);
	// 		setFieldValue('branch', null);
	// 	}

	// 	setFieldValue('sendToPharmacy', !values.sendToPharmacy);
	// };

	const toggleSendPharmacy = () => {
		setFieldValue('sendToPharmacy', !values.sendToPharmacy);
	};

	const toggleSendPatient = () => {
		setFieldValue('sendToPatient', !values.sendToPatient);
	};

	// const handlePickup = () => {
	// 	setFieldValue('pickup', !values.pickup);
	// };

	const renderMobileForm = () => {
		if (!values.sendToPharmacy) return null;

		return (
			<>
				<EuiFormRow label="Select Contact Info">
					<EuiComboBox
						onChange={handleMobileDropdown}
						options={[
							...mobileOptions,
							{
								label: 'Use Alternate Number',
								value: '',
							},
						]}
						selectedOptions={selectedMobile}
						singleSelection={{ asPlainText: true }}
					/>
				</EuiFormRow>
				{selectedMobile[0]?.value === '' && (
					<EuiFormRow
						error={touched.mobile && errors.mobile}
						// helpText="Change value for an alternative mobile"
						isInvalid={touched.mobile && !!errors.mobile}
						label="Mobile Number"
					>
						<EuiFieldText
							isInvalid={touched.mobile && !!errors.mobile}
							name="mobile"
							onChange={handleChange}
							type="mobile"
							value={values.mobile}
						/>
					</EuiFormRow>
				)}
			</>
		);
	};

	const renderEmailForm = () => {
		if (!values.sendToPatient) return null;

		return (
			<>
				<EuiHorizontalRule margin="xs" />
				<EuiFormRow label="Select E-mail">
					<EuiComboBox
						onChange={handleEmailDropDown}
						options={emailOptions}
						selectedOptions={selectedEmail}
						singleSelection={{ asPlainText: true }}
					/>
				</EuiFormRow>
				<EuiFormRow
					error={touched.email && errors.email}
					helpText="Change value for an alternative email address"
					isInvalid={touched.email && !!errors.email}
					label="E-mail Address"
				>
					<EuiFieldText
						isInvalid={touched.email && !!errors.email}
						name="email"
						onChange={handleChange}
						type="email"
						value={values.email}
					/>
				</EuiFormRow>
			</>
		);
	};

	// eslint-disable-next-line no-unused-vars
	const renderPharmacyForm = () => {
		if (!values.sendToPharmacy) return null;

		return (
			<>
				<EuiFormRow>
					<EuiTitle size="xs">
						<h3>Pharmacy</h3>
					</EuiTitle>
				</EuiFormRow>
				<EuiHorizontalRule margin="xs" />
				<EuiFormRow label="Delivery Address">
					<EuiText>
						{buildLocalAddress(prescription.patient.address)}
					</EuiText>
				</EuiFormRow>
				<EuiFormRow>
					<EuiText size="xs">
						<em>ONLY DELIVERY IS AVAILABLE AT THIS MOMENT</em>
					</EuiText>
				</EuiFormRow>
				<EuiFormRow
					error={touched.vendor && errors.vendor}
					fullWidth
					isInvalid={touched.vendor && !!errors.vendor}
					label="Pharmacy"
				>
					<VendorDropdown
						cityId={prescription.patient.address.cityId}
						fullWidth
						id="vendor"
						isInvalid={touched.vendor && !!errors.vendor}
						name="vendor"
						onChange={handleVendorChange}
						pickup={values.pickup}
						preferred={defaultVendor}
						selected={values.vendor}
						showBranchInfo
					/>
				</EuiFormRow>
				{/* <EuiFormRow>
					<EuiRadioGroup
						className="radio-group-row"
						id="pickup"
						idSelected={values.pickup ? 'pickup' : 'delivery'}
						name="pickup"
						onChange={handlePickup}
						options={orderOptions}
					/>
				</EuiFormRow> */}
				<EuiFormRow
					error={touched.branch && errors.branch}
					fullWidth
					isInvalid={touched.branch && !!errors.branch}
					label="Select Branch"
				>
					<BranchDropdown
						cityId={prescription.patient.address.cityId}
						fullWidth
						onChange={setBranch}
						pickup={values.pickup}
						value={values.branch}
						vendor={values.vendor}
					/>
				</EuiFormRow>
			</>
		);
	};

	if (!visible || !prescription || !rxId) return null;

	return (
		<EuiOverlayMask key="send-rx-modal">
			<EuiModal onClose={hideModal}>
				<EuiModalHeader>
					<EuiModalHeaderTitle>
						Send Prescription
						{prescription ? ` #${prescription.code}` : ''}
						{values.mobile
							? ` to ${values.mobile}, Please verify.`
							: ''}
					</EuiModalHeaderTitle>
				</EuiModalHeader>
				<EuiModalBody>
					<EuiForm>
						<EuiSpacer size="m" />
						<EuiFlexGroup>
							<EuiFlexItem grow={false}>
								<EuiCheckbox
									checked={values.sendToPharmacy}
									id="sendToPharmacy"
									label="Send ERX"
									onChange={toggleSendPharmacy}
								/>
							</EuiFlexItem>
						</EuiFlexGroup>
						<EuiSpacer size="m" />
						{renderMobileForm()}
						{/* {renderPharmacyForm()} */}
						<EuiSpacer size="m" />
						<EuiFlexGroup>
							<EuiFlexItem grow={false}>
								<EuiCheckbox
									checked={values.sendToPatient}
									id="sendToPatient"
									label="Send Email Only`"
									onChange={toggleSendPatient}
								/>
							</EuiFlexItem>
						</EuiFlexGroup>
						<EuiSpacer size="m" />
						{renderEmailForm()}
						<EuiSpacer size="m" />
					</EuiForm>
				</EuiModalBody>
				<EuiModalFooter>
					<EuiLink color="danger" onClick={cancel}>
						<EuiText textAlign="center">Cancel</EuiText>
					</EuiLink>

					<EuiButton
						disabled={loading}
						fill
						isLoading={loading}
						onClick={handleSubmit}
					>
						{values.sendToPharmacy || values.sendToPatient
							? 'Send'
							: 'Skip'}
					</EuiButton>
				</EuiModalFooter>
			</EuiModal>
		</EuiOverlayMask>
	);
};

export default SendRxModal;
