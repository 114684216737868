import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import {
	EuiLink,
	EuiIcon,
	EuiButton,
	EuiFlexItem,
	EuiDatePicker,
	EuiText,
	EuiLoadingSpinner,
} from '@elastic/eui';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import moment from 'moment';
import { get } from 'lodash';

import { ROLE_PATH } from 'constants/protect';
import {
	DOCTOR,
	ADMIN,
	SUPER_ADMIN,
	SECRETARY,
	CALL_CENTER,
	PHARMACIST,
} from 'components/roles';
import Page from 'components/Page';
import Table from 'components/Table';
import ConfirmationModal from 'components/ConfirmationModal';
import ViewPrescription from './ViewPrescription';
import {
	getRxs,
	deleteMultiplePrescriptions,
	getSignedAttachments,
} from './prescriptions.fetch';
import RxStatus from './components/RxStatus';
// import { getRxSuccess, deleteMultipleRxSuccess } from './prescriptions.actions';
import { addToast } from '../toasts/toasts.actions';
import SendToPatientButton from './components/SendToPatientButton';

const Prescriptions = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const location = useLocation();
	const { role } = useSelector((state) => ({
		role: get(state, ROLE_PATH),
		prescriptions: state.prescriptions,
	}));
	const [
		isConfirmMultipleDeleteModalVisisble,
		setIsConfirmMultipleDeleteModalVisisble,
	] = useState(false);
	const auth = useSelector((state) => state.auth);
	const [selectedRx, setSelectedRx] = useState({
		data: null,
		visible: false,
	});
	const [selectedItems, setSelectedItems] = useState([]);
	const [columns, setColumns] = useState([]);
	const [date, setDate] = useState(null);
	const [filter, setFilter] = useState('');
	const [signedAttachments, setSignedAttachments] = useState([]);
	// _sort createdAt will not work for call center and pharmacy it will be modified by in api based on receivedDate
	const [params, setParams] = useState({ _sort: 'createdAt:DESC' });

	useEffect(() => {
		if (location.state) {
			const { date: filterDate, status } = location.state;
			if (status) setFilter(`status:${status}`);
			if (filterDate) setDate(moment(filterDate));
		}
	}, []);

	const {
		data: prescriptions,
		isFetching,
		refetch,
	} = useQuery(['prescriptions', params], getRxs, {
		placeholderData: [],
		onError: (err) => {
			dispatch(
				addToast(
					'Error',
					err.message || 'Something went wrong',
					'danger',
					'help',
				),
			);
		},
		refetchInterval: 30000,
	});

	const { isLoading: isLoadingMultipleDelete, mutate: mutateMultiDeleteRx } =
		useMutation(deleteMultiplePrescriptions, {
			onSuccess: (data) => {
				const deletedLengthMsg =
					data.length > 1
						? `${data.length}  Prescriptions`
						: 'Prescription';
				dispatch(
					addToast(
						'Success',
						`${deletedLengthMsg} Successfully deleted`,
						'success',
						'check',
					),
				);
			},
			onError: (err) => {
				dispatch(
					addToast(
						'Error',
						err.message || 'Something went wrong',
						'danger',
						'help',
					),
				);
			},
			onSettled: () => {
				refetch();
				setIsConfirmMultipleDeleteModalVisisble(false);
			},
		});

	useEffect(() => {
		function refetchRxs() {
			const initParams = { _sort: 'createdAt:DESC' };

			if (date) {
				initParams.createdAt_gte = moment(date).startOf('day').format();
				initParams.createdAt_lte = moment(date).endOf('day').format();
			}
			setParams(initParams);
		}

		refetchRxs();
		refetch();
	}, [date]);

	useEffect(() => {
		async function fetchSignedAttachments(ids) {
			const { data: signedAttachmentsData } = await getSignedAttachments(
				ids,
			);
			setSignedAttachments(signedAttachmentsData);
		}
		if (get(selectedRx.data, 'id') && selectedRx.visible) {
			setSignedAttachments([]);
			fetchSignedAttachments(
				get(selectedRx.data, 'attachments', []).map(({ id }) => id),
			);
		}
		if (!selectedRx.visible) {
			setSignedAttachments([]);
		}
	}, [get(selectedRx.data, 'id'), selectedRx.visible]);

	useEffect(() => {
		if (columns.length) return;

		const cols = [
			{
				field: 'code',
				name: 'Prescription Code',
				sortable: true,
				render: (code, data) => (
					<Link
						onClick={(e) => {
							if (role === DOCTOR.key) {
								e.preventDefault();
								setSelectedRx({ data, visible: true });

								return false;
							}

							return true;
						}}
						to={`/prescriptions/${code}`}
					>
						<EuiLink>{code}</EuiLink>
					</Link>
				),
			},
			{
				field: 'patient.firstName',
				name: 'Patient',
				sortable: true,
				render: (firstName, details) => {
					const lastName = get(details, 'patient.lastName');

					return `${firstName} ${lastName}`;
				},
			},
		];

		const actions = [];

		if (role === DOCTOR.key || role === SECRETARY.key) {
			cols.push({
				field: 'vendor.name',
				name: 'Pharmacy',
				render: (vendor) => vendor || 'None',
				sortable: true,
			});
		}

		if (role === DOCTOR.key || role === SECRETARY.key) {
			cols.push({
				field: 'sentToPatient',
				name: 'Sent to Patient(Email)',
				align: 'center',
				render: (sentToPatient) => {
					if (sentToPatient) {
						return (
							<EuiIcon
								color="success"
								type="checkInCircleFilled"
							/>
						);
					}
					return null;
				},
				sortable: true,
			});
		}

		if (
			role === ADMIN.key ||
			role === SUPER_ADMIN.key ||
			role === CALL_CENTER.key
		) {
			cols.push({
				field: 'branch',
				name: 'Branch',
				sortable: true,
				width: '30%',
				render: (branch) => get(branch, 'name', 'Call Center'),
			});
		}

		cols.push({
			field: 'maintenance',
			name: 'Maintenance Med',
			align: 'center',
			render: (isMaintenance) => {
				if (isMaintenance) {
					return (
						<EuiIcon color="success" type="checkInCircleFilled" />
					);
				}
				return null;
			},
		});

		cols.push({
			field: 'status',
			name: t('general.status'),
			sortable: true,
			render: (status, data) => (
				<RxStatus
					assigned={data.branch}
					datetime={data.receivedDate}
					role={role}
					status={status}
				/>
			),
		});

		cols.push({
			field:
				role === CALL_CENTER.key || role === PHARMACIST.key
					? 'receivedDate'
					: 'createdAt',
			name: 'Date Created',
			dataType: 'date',
			sortable: true,
			width: '15%',
			render: (timedate, data) =>
				`${moment(timedate || data.createdAt).format('ll')} | ${moment(
					timedate || data.createdAt,
				).format('LT')}`,
		});

		cols.push({
			field: 'updatedAt',
			name: 'Date Updated',
			dataType: 'date',
			sortable: true,
			width: '15%',
			render: (timedate, data) =>
				`${moment(timedate || data.updatedAt).format('ll')} | ${moment(
					timedate || data.updatedAt,
				).format('LT')}`,
		});

		if (role === DOCTOR.key || role === SECRETARY.key) {
			actions.push({
				name: 'Sent to Patient(Email)',
				description: 'Sent to Patient(Email)',
				render: (data) => (
					<SendToPatientButton
						rxId={data.id}
						sent={data.sentToPatient}
					/>
				),
				isPrimary: true,
			});
		}

		actions.push({
			name: 'View',
			description: 'View',
			onClick: async (data) => {
				setSelectedRx({ data, visible: true });
			},
			icon: 'eye',
			type: 'icon',
		});

		cols.push({
			actions,
		});

		setColumns(cols);
	}, [columns, auth]);

	const clearFilters = () => {
		setDate(null);
		setFilter('');
	};

	const selection = {
		selectable: () => true,
		onSelectionChange: (select) => {
			setSelectedItems(select);
		},
		selectableMessage: () => undefined,
		initialSelected: selectedItems,
	};

	const deleteMultipleRx = async () => mutateMultiDeleteRx(selectedItems);

	const handleMultipleDelete = () => {
		if (!selectedItems.length > 0) return;
		setIsConfirmMultipleDeleteModalVisisble(true);
	};

	const renderToolsLeft = () => {
		if (!selectedItems.length) {
			return null;
		}

		return (
			<EuiButton
				color="danger"
				disabled={isLoadingMultipleDelete || isFetching}
				fill={false}
				iconType="trash"
				onClick={handleMultipleDelete}
			>
				Delete
			</EuiButton>
		);
	};

	const renderToolsRight = () => [
		<EuiFlexItem key="datePicker" grow={false}>
			<EuiDatePicker
				onChange={setDate}
				placeholder={t('general.date')}
				selected={date}
				yearDropdownItemNumber={50}
			/>
		</EuiFlexItem>,
		<EuiFlexItem key="clearFilter" grow={false}>
			<EuiButton
				color="secondary"
				iconType="broom"
				onClick={clearFilters}
			>
				Clear Filters
			</EuiButton>
		</EuiFlexItem>,
	];

	const statuses = [
		'received',
		'processing',
		'readyForPickup',
		'partial',
		'served',
		'cancelled',
		'new',
	];

	const search = {
		query: filter,
		box: {
			incremental: true,
		},
		onChange: (v) => {
			setFilter(v.queryText);
		},
		toolsLeft: renderToolsLeft(),
		toolsRight: renderToolsRight(),
		filters: [
			{
				type: 'field_value_selection',
				field: 'status',
				name: 'Status',
				multiSelect: false,
				options: statuses.map((status) => ({
					value: status,
					name: status,
					view: <RxStatus role={role} status={status} />,
				})),
			},
		],
	};

	return (
		<>
			<Helmet title={t('prescriptions.title')} />
			<Page title={t('prescriptions.title')}>
				<Table
					columns={columns}
					executeQueryOptions={{
						defaultFields: [
							'code',
							'patient.firstName',
							'patient.lastName',
							'vendor.name',
							'status',
						],
					}}
					isSelectable
					itemId="id"
					items={prescriptions}
					search={search}
					selection={role === DOCTOR.key ? selection : null}
				/>
				<ViewPrescription
					data={{
						...selectedRx.data,
						attachments: signedAttachments,
					}}
					onClose={() => {
						setSelectedRx({ data: null, visible: false });
					}}
					visible={selectedRx.visible}
				/>
			</Page>
			<ConfirmationModal
				confirmButtonDisabled={isLoadingMultipleDelete}
				isVisible={isConfirmMultipleDeleteModalVisisble}
				message={
					<span>
						<EuiText>
							Are you sure you want to do this{' '}
							{isLoadingMultipleDelete && <EuiLoadingSpinner />}
						</EuiText>
					</span>
				}
				onClose={() => {
					setIsConfirmMultipleDeleteModalVisisble(false);
				}}
				onConfirm={() => {
					deleteMultipleRx();
				}}
				title="Delete Items"
			/>
		</>
	);
};

export default Prescriptions;
