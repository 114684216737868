import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import {
	EuiFlexGroup,
	EuiFlexItem,
	EuiTitle,
	EuiHorizontalRule,
	EuiForm,
	EuiDescriptionList,
	EuiFormRow,
	EuiFormLabel,
	EuiButton,
	EuiButtonEmpty,
	EuiLoadingSpinner,
	EuiCallOut,
	EuiRadioGroup,
	EuiPageSection,
	EuiSpacer,
	EuiText,
} from '@elastic/eui';
import { MdOpenInNew } from 'react-icons/md';
import { isEmpty, keys, pickBy, identity } from 'lodash';
import { buildLocalAddress, getInvalid, getErrorMessage } from 'utils/helpers';
import ConfirmationModal from 'components/ConfirmationModal';
import BookingModal from 'components/BookingModal';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useFormik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import GrabModal from './GrabModal';
import { addToast } from '../../toasts/toasts.actions';
import {
	mapValidateRxData,
	validateRxModel,
	putPaymentRx,
	createCheckout,
} from '../validate-prescription.fetch';
import { getFailedGrabOrders } from '../../orders/orders.fetch';

// GRAB API
import {
	cancelDelivery,
	createDeliveryRequest,
	getDeliveryStatus,
	getGrabOrder,
	getQuote,
	updateGrabEntry,
} from '../../grab/grab';

const RxPaymentForm = ({ handleChanges, hasChanges, onClose, rx, token }) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const queryClient = useQueryClient();

	const onError = (err) => {
		const errorMessage = getErrorMessage(err);
		dispatch(addToast('Error', errorMessage, 'danger', 'help'));
	};

	const [grabQuote, setGrabQuote] = useState(null);
	const [convenienceFee, setConvenienceFee] = useState(null);
	const [totalAmount, setTotalAmount] = useState(null);
	const [isLoading] = useState(false);

	const [grabModalVisible, setGrabModalVisible] = useState(false);
	const [confirmGrabVisible, setConfirmGrabVisible] = useState(false);
	const [cancelGrabVisible, setCancelGrabVisible] = useState(false);
	const [cancelPaymentVisible, setCancelPaymentVisible] = useState(false);
	const [bookingVisible, setBookingVisible] = useState(false);

	const [seconds, setSeconds] = useState(60);
	const [isActive, setIsActive] = useState(false);
	const [grabModalTitle, setGrabModalTitle] = useState('');
	const [grabModalContent, setGrabModalContent] = useState('');
	const [riderBooked, setRiderBooked] = useState(false);
	const [deliveryStatus, setDeliveryStatus] = useState(null);
	const [noGrabOrder, setHasNoGrabOrder] = useState(true);

	const { isLoading: isSubmitting, mutate } = useMutation(putPaymentRx, {
		onSuccess: () => {
			handleChanges(false);
			dispatch(
				addToast('Order updated', 'Changes Saved', 'success', 'check'),
			);
		},
		onError: () => {
			dispatch(
				addToast(
					'Error: Something went wrong',
					'No Changes Saved',
					'danger',
					'help',
				),
			);
		},
	});

	const formikBag = useFormik({
		initialValues: {
			...validateRxModel,
			...mapValidateRxData(rx),
		},
		validateOnBlur: true,
		enableReinitialize: true,
		onSubmit: async (data) => {
			mutate({
				...data,
				token,
			});
		},
	});

	const { errors, setFieldValue, touched, values } = formikBag;

	const { data: grabOrder, refetch: refetchGrab } = useQuery(
		[`grab-${values.order.orderId}`, values.order.id],
		getGrabOrder,
		{
			placeholderData: {},
			initialData: {},
			onError: (err) => {
				if (err.response.status === 404) {
					console.log('no grab order for this order...'); // eslint-disable-line no-console
					setHasNoGrabOrder(false);
				}
			},
			enabled: noGrabOrder,
		},
	);

	const { data: failedGrabOrders, refetch: refetchFailed } = useQuery(
		[`getFailedOrders-${values.order.orderId}`, `${values.order.id}`],
		getFailedGrabOrders,
		{
			placeholderData: {},
			onError: (err) => {
				dispatch(
					addToast(
						'Error: Could not retrieve grab order history',
						err.message,
						'danger',
						'help',
					),
				);
			},
			enabled:
				values.order.status === 'readyForPickup' ||
				values.order.status === 'released',
		},
	);

	const getGrabQuote = async (order) => {
		try {
			let codAmount = values.order.vendor.convenienceFee;
			if (values.order.paymentMethod === 'magpie') codAmount = 0;
			const { data } = await getQuote(codAmount, order);
			const quote = data.quotes[0].amount;
			setGrabQuote(quote);
			setTotalAmount(quote + convenienceFee);
		} catch (err) {
			onError(err);
		}
	};

	useEffect(() => {
		if (!values.order.isPaid && !values.order.paymentMethod)
			setFieldValue('order.paymentMethod', 'magpie');
	}, []);

	useEffect(() => {
		if (!values.order.isPaid && values.order.paymentMethod === '')
			values.order.paymentMethod = values.paymentMethod;
	}, [values.paymentMethod]);

	useEffect(() => {
		setConvenienceFee(values.order.vendor.convenienceFee);
	}, [values.order.vendor.convenienceFee]);

	useEffect(() => {
		if (convenienceFee !== null) {
			getGrabQuote(values.order);
		}
	}, [values.order.paymentMethod, convenienceFee]);

	const openBookingModal = () => {
		setSeconds(60);
		setBookingVisible(true);
		setIsActive(true);
	};

	const closeBookingModal = () => {
		setBookingVisible(false);
		setIsActive(false);
	};

	// function for getting delivery status
	const getGrabStatus = async (grabOrderStat) => {
		// get delivery status
		const newGrabOrder = await getDeliveryStatus(grabOrderStat.deliveryId);
		setDeliveryStatus(newGrabOrder);

		let payload = {
			deliveryId: newGrabOrder.deliveryID,
			status: newGrabOrder.status,
			// origin: newGrabOrder.quote.origin.address,
			// destination: newGrabOrder.quote.destination.address,
			// allocateTime: newGrabOrder.timeline.create,
			// pickupTime: newGrabOrder.timeline.pickup,
			// dropoffTime: newGrabOrder.timeline.dropoff,
			// amount: newGrabOrder.quote.amount,
			//   order:order.id,
			//   branch:order.branch.id,
			//   patient:order.patient.id,
			// trackingURL: newGrabOrder.trackingURL,
		};

		if (newGrabOrder.trackingURL) {
			payload.trackingURL = newGrabOrder.trackingURL;
		}

		if (newGrabOrder.invoiceNo) {
			payload.invoiceNo = newGrabOrder.invoiceNo;
		}

		if (
			newGrabOrder.status === 'ALLOCATING' ||
			newGrabOrder.status === 'QUEUEING'
		) {
			if (!bookingVisible) {
				openBookingModal();
			}
			setRiderBooked(false);
		} else if (
			newGrabOrder.status === 'CANCELED' ||
			newGrabOrder.status === 'FAILED' ||
			newGrabOrder.status === 'RETURNED'
		) {
			setRiderBooked(false);
			setDeliveryStatus(null);
			closeBookingModal();
		} else {
			if (!riderBooked) {
				setRiderBooked(true);
				setGrabModalTitle('Request for Rider Success');
				setGrabModalContent('We found a Grab driver!');
				setGrabModalVisible(true);
			}
			closeBookingModal();
		}

		if (newGrabOrder.courier) {
			payload = {
				...payload,
				licensePlate: newGrabOrder.courier.vehicle.licensePlate,
				courierName: newGrabOrder.courier.name,
				courierPhone: newGrabOrder.courier.phone,
			};
		}

		if (newGrabOrder.timeline) {
			payload = {
				...payload,
				allocateTime: newGrabOrder.timeline.create,
				pickupTime: newGrabOrder.timeline.pickup,
				dropoffTime: newGrabOrder.timeline.dropoff,
			};
		}

		await updateGrabEntry(payload, grabOrder.id);
		await queryClient.resetQueries([
			`grab-${values.order.orderId}`,
			values.order.id,
		]);
	};

	const refreshStatus = async () => {
		if (!isEmpty(grabOrder)) {
			try {
				await getGrabStatus(grabOrder);
				await refetchFailed();
				await refetchGrab();
			} catch (err) {
				onError(err);
			}
		}
	};

	// status refresh
	useEffect(() => {
		let interval = null;
		if (riderBooked && deliveryStatus !== null) {
			interval = setInterval(() => {
				refreshStatus();
			}, 7000);
		}
		return () => clearInterval(interval);
	}, [riderBooked]);

	const cancelRider = async () => {
		try {
			await cancelDelivery(grabOrder.deliveryId);
			closeBookingModal();
			refreshStatus();
		} catch (err) {
			onError(err);
		}
	};

	// booking timer
	useEffect(() => {
		let interval = null;
		if (isActive) {
			interval = setInterval(() => {
				if (seconds <= 0) {
					if (!riderBooked && bookingVisible) {
						cancelRider();
						setGrabModalTitle('Request for Rider Failed');
						setGrabModalContent(
							'We could not find a grab rider. Please try again.',
						);
						setGrabModalVisible(true);
					}
					closeBookingModal();
				} else if (seconds % 5 === 0) {
					getGrabStatus(grabOrder);
				}
				setSeconds((sec) => Math.max(sec - 1, 0));
			}, 1000);
		} else if (!isActive) {
			clearInterval(interval);
		}
		return () => clearInterval(interval);
	}, [isActive, seconds]);

	useEffect(() => {
		if (!isEmpty(grabOrder) && !deliveryStatus) {
			refreshStatus();
		}
	}, [grabOrder]);

	useEffect(() => {
		if (!hasChanges) {
			handleChanges(keys(pickBy(touched, identity)).length > 0);
		}
	}, [touched]);

	const requestRider = async () => {
		try {
			await createDeliveryRequest(
				values.order,
				values.mobile,
				values.email,
				values.order.paymentMethod === 'magpie' ? 0 : totalAmount,
				refetchGrab,
			);
			refreshStatus();
		} catch (err) {
			onError(err);
		}
	};

	const renderAddress = () => {
		return {
			title: <EuiFormLabel>Address</EuiFormLabel>,
			description: (
				<>
					{(!!getInvalid('line1', errors, touched) ||
						!!getInvalid('brgyId', errors, touched) ||
						!!getInvalid('cityId', errors, touched)) && (
						<EuiCallOut
							color="danger"
							size="s"
							title="Complete address is Required"
						/>
					)}
					<div>
						{values?.deliveryAddress?.address ??
							buildLocalAddress({
								line1: values.deliveryAddress?.line1,
								line2: values.deliveryAddress?.line2,
								brgy: values.deliveryAddress?.brgy,
								city: values.deliveryAddress?.city,
							})}
					</div>
				</>
			),
		};
	};

	const renderAmounts = () => {
		let formList1 = [
			{
				title: <EuiFormLabel>Code</EuiFormLabel>,
				description: <b>{values.order.orderId}</b>,
			},
			{
				title: <EuiFormLabel>Delivery Mode</EuiFormLabel>,
				description: (
					<strong
						style={{
							color:
								values.order.pickupType === 'grab'
									? 'forestgreen'
									: 'black',
						}}
					>
						{values.order.pickupType === 'grab'
							? 'GRAB DELIVERY'
							: 'BRANCH PICKUP'}
					</strong>
				),
			},
		];

		if (!riderBooked) {
			formList1.push({
				title: <EuiFormLabel>Pickup</EuiFormLabel>,
				description: `${values.order.branch.name}`,
			});
		}

		if (values.order.isPaid) {
			formList1.push({
				title: <EuiFormLabel>Payment Method</EuiFormLabel>,
				description: `${values.order.paymentMethod}`,
			});
		}

		formList1.push(renderAddress());

		const formList2 = [
			{
				title: <EuiFormLabel>Medicine/s Total Amount</EuiFormLabel>,
				description: `₱${values.order.amount ?? '---'}`,
			},
			{
				title: <EuiFormLabel>Convenience & Delivery Fee</EuiFormLabel>,
				description: `₱${totalAmount ?? '---'}`,
			},
		];

		const formList3 = [
			{
				title: <EuiFormLabel>TOTAL AMOUNT</EuiFormLabel>,
				description: (
					<strong style={{ color: 'goldenrod' }}>
						₱
						{!isEmpty(grabOrder)
							? grabOrder.amount
							: totalAmount + values.order.amount ?? '---'}
					</strong>
				),
			},
			{
				title: <EuiFormLabel>Status</EuiFormLabel>,
				description: (
					<i
						style={{
							color: values.order.isPaid ? 'forestgreen' : 'gray',
						}}
					>
						{values.order.isPaid ? 'Paid' : 'Unpaid'}
					</i>
				),
			},
		];

		if (values.order.isPaid && values.order.payment) {
			formList3.push({
				title: <EuiFormLabel>Reference Number</EuiFormLabel>,
				description: <div>{values.order.payment.referenceNumber}</div>,
			});
		}

		let formListExtra = [];
		if (!isEmpty(failedGrabOrders)) {
			formListExtra = failedGrabOrders.map((gOrder) => {
				return {
					title: (
						<EuiFormLabel>
							{moment(gOrder.createdAt).format('LLL')}
						</EuiFormLabel>
					),
					description: `${gOrder.status}`,
				};
			});
		}

		const paymentMethods = [
			{
				id: 'magpie',
				label: (
					<span>
						{' '}
						Pay Now{' '}
						<span style={{ color: 'gray' }}>
							(Using GCash, Paymaya, BPI, or UnionBank)
						</span>
					</span>
				),
			},
			{
				id: 'cod',
				label: (
					<span>
						{' '}
						COD{' '}
						<span style={{ color: 'gray' }}>
							(Total amount will be collected by the rider)
						</span>
					</span>
				),
			},
		];

		if (!deliveryStatus) {
			return (
				<div>
					<EuiFormRow label="">
						<EuiDescriptionList
							compressed
							listItems={formList1}
							responsive="false"
							type="column"
						/>
					</EuiFormRow>

					{!values.order.isPaid && (
						<EuiFormRow label="">
							<EuiRadioGroup
								idSelected={
									values.order.paymentMethod ??
									values.paymentMethod
								}
								legend={{
									children: <strong>Select Payment</strong>,
								}}
								name="paymentMethod"
								onChange={(id) => {
									setFieldValue('order.paymentMethod', id);
								}}
								options={paymentMethods}
								style={{
									display: 'flex',
									flexDirection: 'column',
									textAlign: 'left',
								}}
							/>
						</EuiFormRow>
					)}

					<EuiHorizontalRule margin="s" />

					{!riderBooked && (
						<>
							<EuiFormRow label="">
								<EuiTitle size="xs">
									<h6>SUMMARY</h6>
								</EuiTitle>
							</EuiFormRow>
							<EuiFormRow label="">
								<EuiDescriptionList
									compressed
									listItems={formList2}
									responsive="false"
									type="column"
								/>
							</EuiFormRow>
						</>
					)}

					<EuiHorizontalRule margin="s" />

					<EuiFormRow label="">
						<EuiDescriptionList
							compressed
							listItems={formList3}
							responsive="false"
							type="column"
						/>
					</EuiFormRow>

					{formListExtra.length > 0 && (
						<EuiFormRow label="">
							<EuiTitle size="xs">
								<h6>Grab Delivery History</h6>
							</EuiTitle>
						</EuiFormRow>
					)}

					{formListExtra.length > 0 && (
						<EuiFormRow label="">
							<EuiDescriptionList
								compressed
								listItems={formListExtra}
								responsive="false"
							/>
						</EuiFormRow>
					)}
				</div>
			);
		}

		if (!riderBooked) {
			return <></>;
		}
		const isPickingUpStatus = deliveryStatus.status === 'PICKING_UP';
		const isInDeliveryStatus = deliveryStatus.status === 'IN_DELIVERY';
		const deliveryTitle = isInDeliveryStatus ? 'Dropoff' : 'Completed';
		const etaTitle = isPickingUpStatus ? 'Pick Up' : deliveryTitle;

		const etaTimelinePickup = moment(deliveryStatus.timeline.pickup).format(
			'LLLL',
		);
		const etaTimelineDropoff = moment(
			deliveryStatus.timeline.dropoff,
		).format('LLLL');
		const etaTimelineCompleted = moment(
			deliveryStatus.timeline.completed,
		).format('LLLL');

		const deliveryDescription = isInDeliveryStatus
			? etaTimelineDropoff
			: etaTimelineCompleted;
		const etaDescription = isPickingUpStatus
			? etaTimelinePickup
			: deliveryDescription;

		if (riderBooked && deliveryStatus != null) {
			formList1 = [
				{
					title: <EuiFormLabel>Code</EuiFormLabel>,
					description: <b>{values.order.orderId}</b>,
				},
				{
					title: <EuiFormLabel>Grab Rider Name</EuiFormLabel>,
					description: `${deliveryStatus.courier.name}`,
				},
				{
					title: <EuiFormLabel>Contact Number</EuiFormLabel>,
					description: `${deliveryStatus.courier.phone}`,
				},
				{
					title: <EuiFormLabel>Status</EuiFormLabel>,
					description: `${deliveryStatus.status}`,
				},
				{
					title: <EuiFormLabel>ETA ({etaTitle})</EuiFormLabel>,
					description: etaDescription,
				},
				{
					title: <EuiFormLabel>Mode of Payment</EuiFormLabel>,
					description: 'Cash on Delivery',
				},
			];
		}

		const formList4 = [];
		if (riderBooked && deliveryStatus != null) {
			formList4.push({
				title: <EuiFormLabel>Pickup</EuiFormLabel>,
				description: `${deliveryStatus?.quote.origin.address}`,
			});
			formList4.push({
				title: <EuiFormLabel>Drop off</EuiFormLabel>,
				description: `${deliveryStatus?.quote.destination.address}`,
			});
			formList4.push({
				title: <EuiFormLabel>Tracking URL</EuiFormLabel>,
				description: (
					<a
						className="underlined"
						href={deliveryStatus?.trackingURL}
						rel="noreferrer"
						target="_blank"
					>
						Open in new tab
						<MdOpenInNew />
					</a>
				),
			});
		}

		return (
			<div>
				<EuiFormRow label="">
					<EuiDescriptionList
						listItems={formList1}
						responsive="false"
						type="column"
					/>
				</EuiFormRow>

				{!riderBooked && (
					<EuiFormRow label="">
						<EuiDescriptionList
							listItems={formList2}
							responsive="false"
							type="column"
						/>
					</EuiFormRow>
				)}

				<EuiHorizontalRule margin="s" />

				<EuiFormRow label="">
					<EuiDescriptionList
						listItems={formList3}
						responsive="false"
						type="column"
					/>
				</EuiFormRow>

				{riderBooked && (
					<>
						<EuiHorizontalRule margin="s" />

						<EuiFormRow label="">
							<EuiDescriptionList
								listItems={formList4}
								responsive="false"
								type="column"
							/>
						</EuiFormRow>
					</>
				)}
			</div>
		);
	};

	if (isLoading && !grabQuote) {
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: 200,
					minWidth: 254,
				}}
			>
				<EuiLoadingSpinner />
			</div>
		);
	}

	const openMagpiePortal = async () => {
		const customerEmail = values.patient?.email;

		const prescriptionCode =
			values.code && values.code !== ''
				? values.code
				: values.order?.prescription?.code;

		const res = await createCheckout({
			amount: totalAmount + values.order.amount,
			customer_email: customerEmail ? customerEmail.value : '',
			lineItems: values.order.lineItems,
			orderId: values.order.orderId,
			code: prescriptionCode,
			token,
			returnPath: location.pathname,
		});

		const { id: magpieSessionId, payment_url: paymentUrl } = res;
		window.localStorage.setItem('magpieSessionId', magpieSessionId);
		window.open(paymentUrl, '_blank');
	};

	const renderProcessingOrder = () => {
		return (
			<>
				<EuiHorizontalRule margin="s" />

				<EuiFormRow label="">
					<EuiTitle size="xs">
						<EuiText textAlign="center">
							YOUR ORDER IS BEING PROCESSED
						</EuiText>
					</EuiTitle>
				</EuiFormRow>
			</>
		);
	};

	const renderReadyForPickup = () => {
		return (
			<>
				<EuiHorizontalRule margin="s" />

				<EuiFormRow label="">
					<EuiTitle size="xs">
						<EuiText textAlign="center">
							YOUR ORDER IS READY FOR PICK UP
						</EuiText>
					</EuiTitle>
				</EuiFormRow>
			</>
		);
	};

	const renderOrderReleased = () => {
		return (
			<>
				<EuiHorizontalRule margin="s" />

				<EuiFormRow label="">
					<EuiTitle size="xs">
						<EuiText textAlign="center">
							YOUR ORDER HAS BEEN RELEASED. <br />
						</EuiText>
					</EuiTitle>
				</EuiFormRow>

				<EuiFormRow label="">
					<EuiText size="s" textAlign="center">
						Thank you for using MedAlert eRX! <br />
					</EuiText>
				</EuiFormRow>
			</>
		);
	};

	return (
		<EuiPageSection>
			<EuiForm>
				<EuiFlexGroup>
					<EuiFlexItem grow={false}>
						<EuiTitle size="s">
							<h4>Payment & Delivery Confirmation</h4>
						</EuiTitle>
					</EuiFlexItem>
				</EuiFlexGroup>

				<EuiHorizontalRule margin="s" />

				{renderAmounts()}

				{values.order.status === 'processing'
					? renderProcessingOrder()
					: ''}

				{values.order.status === 'readyForPickup'
					? renderReadyForPickup()
					: ''}

				{values.order.status === 'released'
					? renderOrderReleased()
					: ''}

				<EuiHorizontalRule margin="s" />

				<EuiSpacer size="s" />

				<EuiFormRow>
					<EuiFlexGroup gutterSize="s" responsive={false}>
						{!riderBooked && (
							<>
								<EuiFlexItem>
									<EuiButtonEmpty
										className="danger-button-outlined"
										color="danger"
										disabled={isSubmitting}
										isLoading={isSubmitting}
										onClick={() => {
											setCancelPaymentVisible(true);
										}}
									>
										Cancel
									</EuiButtonEmpty>
								</EuiFlexItem>
								<ConfirmationModal
									isVisible={cancelPaymentVisible}
									message="Are you sure you want to cancel?"
									onClose={() => {
										setCancelPaymentVisible(false);
									}}
									onConfirm={onClose}
									title="Confirm Cancellation"
								/>
							</>
						)}

						{!riderBooked && values.order.pickupType !== 'branch' && (
							<EuiFlexItem>
								{values.order.paymentMethod === 'magpie' &&
									values.order.status ===
										'pendingPayment' && (
										<EuiButton
											color="primary"
											disabled={
												!values.order.paymentMethod ||
												!grabQuote ||
												isSubmitting
											}
											fill
											onClick={openMagpiePortal}
										>
											Pay
										</EuiButton>
									)}

								{(values.order.paymentMethod === 'cod' ||
									(values.order.paymentMethod === 'magpie' &&
										values.order.isPaid)) && (
									<EuiButton
										color="primary"
										disabled={
											!values.order.paymentMethod ||
											!grabQuote ||
											isSubmitting ||
											(values.order.paymentMethod ===
												'magpie' &&
												values.order.status !==
													'readyForPickup')
										}
										fill
										onClick={() => {
											setConfirmGrabVisible(true);
										}}
									>
										Request a Rider
									</EuiButton>
								)}
							</EuiFlexItem>
						)}

						{riderBooked && deliveryStatus != null && (
							<>
								<EuiFlexItem>
									<EuiButton
										className="danger-button-outlined"
										color="danger"
										disabled={
											deliveryStatus.status !==
											'PICKING_UP'
										}
										isLoading={null}
										onClick={() => {
											setCancelGrabVisible(true);
										}}
									>
										Cancel Delivery
									</EuiButton>
								</EuiFlexItem>

								<EuiFlexItem>
									<EuiButton
										color="primary"
										disabled={
											deliveryStatus.status === 'RELEASED'
										}
										fill
										isLoading={null}
										onClick={() => {
											window.open(
												`tel:+${deliveryStatus.courier.phone}`,
											);
										}}
									>
										Call Rider
									</EuiButton>
								</EuiFlexItem>
							</>
						)}
					</EuiFlexGroup>
				</EuiFormRow>
			</EuiForm>

			<BookingModal
				cancelDisable={isEmpty(grabOrder)}
				dropoff={buildLocalAddress({
					line1: values.line1,
					line2: values.line2,
					brgy: values.brgy,
					city: values.city,
				})}
				isVisible={bookingVisible}
				message="We are finding you a rider"
				onCancel={() => {
					setCancelGrabVisible(true);
				}}
				pickup={values.order.branch.name}
				seconds={seconds}
				title="Please wait..."
			/>

			<ConfirmationModal
				isVisible={confirmGrabVisible}
				message="Do you want to request for a Grab Rider to deliver your order?"
				onClose={() => {
					setConfirmGrabVisible(false);
				}}
				onConfirm={() => {
					requestRider();
					setConfirmGrabVisible(false);
				}}
				title="Confirm"
			/>

			<ConfirmationModal
				isVisible={cancelGrabVisible}
				message="Are you sure you want to cancel your order?"
				onClose={() => {
					setCancelGrabVisible(false);
				}}
				onConfirm={() => {
					cancelRider();
					setCancelGrabVisible(false);
				}}
				title="Confirm Cancellation"
			/>

			<GrabModal
				isVisible={grabModalVisible}
				message={grabModalContent}
				onClose={() => {
					setGrabModalVisible(false);
				}}
				title={grabModalTitle}
			/>
		</EuiPageSection>
	);
};

RxPaymentForm.propTypes = {
	handleChanges: PropTypes.func,
	hasChanges: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	rx: PropTypes.shape({}).isRequired,
	token: PropTypes.string.isRequired,
};

RxPaymentForm.defaultProps = {
	handleChanges: () => {},
	hasChanges: false,
};

export default RxPaymentForm;
