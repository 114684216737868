const NEW = {
	value: 'new',
	text: 'New',
	pickupAllowedNextStatus: [],
	deliveryAllowedNextStatus: [],
};
const PENDING_PAYMENT = {
	value: 'pendingPayment',
	text: 'Pending Payment',
	pickupAllowedNextStatus: [],
	deliveryAllowedNextStatus: [],
};
const PROCESSING = {
	value: 'processing',
	text: 'Processing',
	pickupAllowedNextStatus: [],
	deliveryAllowedNextStatus: [],
};
const READY_FOR_PICKUP = {
	value: 'readyForPickup',
	text: 'Ready for Pickup',
	pickupAllowedNextStatus: [],
	deliveryAllowedNextStatus: [],
};
const RELEASED = {
	value: 'released',
	text: 'Released',
	pickupAllowedNextStatus: [],
	deliveryAllowedNextStatus: [],
};
const CANCELLED = {
	value: 'cancelled',
	text: 'Cancelled',
	pickupAllowedNextStatus: [],
	deliveryAllowedNextStatus: [],
};

/* PICKUP ALLOWED STATUS */
NEW.pickupAllowedNextStatus = [PROCESSING.value, CANCELLED.value];
PROCESSING.pickupAllowedNextStatus = [READY_FOR_PICKUP.value, CANCELLED.value];
READY_FOR_PICKUP.pickupAllowedNextStatus = [RELEASED.value, CANCELLED.value];

/* DELIVERY ALLOWED STATUS */
NEW.deliveryAllowedNextStatus = [PENDING_PAYMENT.value, CANCELLED.value];
PENDING_PAYMENT.deliveryAllowedNextStatus = [CANCELLED.value];
PROCESSING.deliveryAllowedNextStatus = [
	READY_FOR_PICKUP.value,
	CANCELLED.value,
];
READY_FOR_PICKUP.deliveryAllowedNextStatus = [RELEASED.value, CANCELLED.value];

export {
	NEW,
	PENDING_PAYMENT,
	PROCESSING,
	READY_FOR_PICKUP,
	RELEASED,
	CANCELLED,
};
