/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { useFormik } from 'formik';
import { get, isEmpty } from 'lodash';

import CustomStep from 'components/Step';
import Page from 'components/Page';
import { getUserDetails } from 'modules/auth/auth.fetch';
import { updateUserDetails } from 'modules/auth/auth.actions';
import { getPrescription } from 'modules/prescriptions/prescriptions.fetch';
import PatientOrderForm from './PatientOrderForm';

import { renewRX } from '../validate-prescription/validate-prescription.fetch';
import PatientOrderFormValidationSchema from './PatientOrder.validator';
import { addToast } from '../toasts/toasts.actions';
import {
	validatePatientOrderModel,
	createPrescriptionsbyPatient,
	updatePrescriptionByPatient,
} from './patients.fetch';
import RemindersModal from './RemindersModal';

export default function PatientOrder() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const { location } = history;

	const { code: prescriptionCode } = useParams();

	const [step, setStep] = useState(1);
	const [attachments, setAttachments] = useState([]);
	const [disabled, setDisabled] = useState(true);
	const [isSameCustomerDeets, setIsSameCustomerDeets] = useState(false);
	const [formData, setFormData] = useState({});
	const [remindersOpen, setRemindersOpen] = useState(false);
	const [rxCode, setRxCode] = useState(null);
	const [prescriptionDetails, setPrescriptionDetails] = useState(null);

	const pickupTypes = ['delivery', 'grab'];

	const handleFindContactsByType = (type, contacts) => {
		const contactDetail = contacts.find(
			(contact) => contact?.type === type,
		);
		return contactDetail?.value;
	};

	const patientInfo = useSelector((state) => state.auth.user);

	const getStepStatus = (index) => {
		if (index === step) return 'current';
		if (step > index) return 'complete';
		return 'incomplete';
	};

	const steps = [
		{
			key: 1,
			title: 'Prescription Details',
			description: 'Choose medicines and upload Rx',
			status: getStepStatus(1),
		},
		{
			key: 2,
			title: ' Customer Information',
			description: 'Customer and Shipping Details ',
			status: getStepStatus(2),
		},
		{
			key: 3,
			title: 'Branch and Payment',
			description: 'Select preferred branch and billing',
			status: getStepStatus(3),
		},
	];

	const fetchPrescription = async () => {
		try {
			const { data } = await getPrescription(prescriptionCode);
			setPrescriptionDetails(data);
		} catch (err) {
			dispatch(
				addToast(
					'Error',
					'Unable to retrieve prescription!',
					'danger',
					'help',
				),
			);
		}
	};

	useEffect(() => {
		if (prescriptionCode) {
			fetchPrescription();
		}
	}, [prescriptionCode]);

	const refreshUserDetails = async () => {
		const { data: user } = await getUserDetails();

		dispatch(updateUserDetails({ ...patientInfo, ...user?.details }));
	};

	const handleSuccessRedirect = (res) => {
		refreshUserDetails();

		const locationStateData = location?.state?.data || {};
		const code = res?.data?.code;

		if (locationStateData?.maintenance && code) {
			setRxCode(code);
			setRemindersOpen(true);
		} else {
			history.push('/');
		}
	};

	const { isLoading: isSubmitting, mutate } = useMutation(
		createPrescriptionsbyPatient,
		{
			onSuccess: (res) => {
				dispatch(
					addToast(
						'Success',
						'Prescription sent!',
						'success',
						'check',
					),
				);

				handleSuccessRedirect(res);
			},
			onError: (err) => {
				let error = {
					title: 'Error: Something went wrong',
					...err,
				};
				if (
					err.message === 'Network Error' &&
					Object.keys(get(error, 'config.data')).length === 0
				) {
					error = {
						title: 'Error',
						message:
							'Please capture and save photo into your gallery first and choose file saved photos to upload',
					};
				}

				dispatch(
					addToast(error.title, error.message, 'warning', 'help'),
				);
			},
		},
	);

	const { isLoading: isUpdatingPrescription, mutate: mutateUpdate } =
		useMutation(updatePrescriptionByPatient, {
			onSuccess: (res) => {
				dispatch(
					addToast(
						'Success',
						'Prescription sent!',
						'success',
						'check',
					),
				);

				handleSuccessRedirect(res);
			},
			onError: (err) => {
				let error = {
					title: 'Error: Something went wrong',
					...err,
				};
				if (
					err.message === 'Network Error' &&
					Object.keys(get(error, 'config.data')).length === 0
				) {
					error = {
						title: 'Error',
						message:
							'Please capture and save photo into your gallery first and choose file saved photos to upload',
					};
				}

				dispatch(
					addToast(error.title, error.message, 'warning', 'help'),
				);
			},
		});

	const { isLoading: isSubmittingNewOrder, mutate: mutateRenewRx } =
		useMutation(renewRX, {
			onSuccess: () => {
				dispatch(
					addToast(
						'Order updated',
						'Changes Saved',
						'success',
						'check',
					),
				);
			},
			onError: (err) => {
				dispatch(addToast('Error', err.message, 'warning', 'help'));
			},
		});

	const setPayload = (values) => {
		const prescriptionItems = [];

		if (values.prescriptionItems) {
			values.prescriptionItems.forEach((item) =>
				prescriptionItems.push({
					generic: item.generic,
					brand: item.brand,
					quantity: item.quantity,
					price: 0,
				}),
			);
		}

		const payload = {
			preferredPickupType: values.pickupType,
			vendor: values.vendor,
			preferredBranch: values.preferredBranch,
			patient: values.patient || patientInfo?.id,
			fulfillment: values.fulfillment,
			patientNotes: values.patientNotes,
			maintenance: values.maintenance,
			mobile: values?.mobile || patientInfo?.mobile,
			email: values?.email || patientInfo?.email,
		};

		if (values?.pickupType !== 'branch' && values?.receiver) {
			Object.assign(payload, { receiver: values?.receiver });
		}

		if (prescriptionItems.length) {
			Object.assign(payload, { prescriptionItems });
		}

		return payload;
	};

	const setInitialValues = () => {
		const locationStateData = location?.state?.data || {};

		const {
			attachments: locationStateDataAttachments,
			branch,
			fulfillment,
			maintenance,
			patientNotes,
			paymentMethod,
			prescriptionItems,
			vendor,
		} = locationStateData;

		return {
			fulfillment:
				fulfillment ||
				validatePatientOrderModel.fulfillment ||
				'purchase_all',
			paymentMethod:
				paymentMethod ||
				validatePatientOrderModel.paymentMethod ||
				'gcash',
			patientNotes:
				patientNotes || validatePatientOrderModel.patientNotes || '',
			prescriptionItems:
				prescriptionItems ||
				validatePatientOrderModel.prescriptionItems,
			status: locationStateData
				? 'open'
				: validatePatientOrderModel.status,
			attachments:
				locationStateDataAttachments ||
				validatePatientOrderModel.attachments,
			vendor: vendor?.id || validatePatientOrderModel.vendor,
			preferredBranch:
				branch?.id || validatePatientOrderModel.preferredBranch,
			pickupType: 'grab',
			maintenance,
		};
	};

	const formikBag = useFormik({
		initialValues: setInitialValues(),
		validateOnBlur: true,
		validationSchema: PatientOrderFormValidationSchema,
		enableReinitialize: !prescriptionCode,
		onSubmit: async (values) => {
			let payload = null;

			let shouldSetFormData = false;

			const files = !isEmpty(location?.state?.data)
				? location.state.data.attachments
				: null;

			const { paymentMethod } = formData;

			const address = {
				brgy: values.brgy,
				brgyId: values.brgyId,
				city: values.city ? values.city : 'fill',
				cityId: values.cityId,
				line1: values.line1,
				line2: values.line2,
			};

			const receiver = {
				id: patientInfo?.customer?.id || null,
				user: patientInfo?.user?.id,
				firstName: values.receiverFirstName,
				lastName: values.receiverLastName,
				mobile: values.receiverContactNumber,
				email: values.receiverEmail,
				address,
			};

			const attachmentIds = [];

			const tempPayload = { ...values };

			switch (true) {
				case !isEmpty(location?.state?.data):
					if (values?.pickupType !== 'branch') {
						Object.assign(tempPayload, { receiver });
					}

					payload = setPayload({ ...tempPayload });

					if (files && files.length) {
						files.forEach((file) => {
							attachmentIds.push(file.id);
						});

						Object.assign(payload, {
							attachmentIds:
								attachments?.length > 0 ? [] : attachmentIds,
						});
					}

					break;
				case pickupTypes.includes(tempPayload?.pickupType):
					shouldSetFormData = true;

					payload = setPayload(formData);

					Object.assign(payload, { receiver, paymentMethod });
					break;
				default:
					payload = setPayload(tempPayload);
					break;
			}

			const dataPayload = {
				data: payload,
				attachments,
			};

			if (prescriptionCode) {
				Object.assign(dataPayload.data, { markAsReceived: true });
				mutateUpdate({ code: prescriptionCode, data: dataPayload });
			} else {
				mutate(dataPayload);
			}

			if (shouldSetFormData) {
				setFormData(values);
			}
		},
	});

	const { setFieldTouched, setFieldValue, values } = formikBag;

	const handleUpdateFormValues = () => {
		if (prescriptionDetails) {
			// eslint-disable-next-line no-prototype-builtins
			if (!prescriptionDetails.hasOwnProperty('receiver')) {
				setIsSameCustomerDeets(() => true);
			}

			const {
				attachments: attachmentData,
				deliveryAddress,
				fulfillment,
				maintenance,
				patient,
				patientNotes,
				paymentMethod,
				preferredBranch,
				prescriptionItems,
				receiver,
				status,
				vendor,
			} = prescriptionDetails;

			const newValues = {
				fulfillment,
				paymentMethod,
				patientNotes,
				prescriptionItems,
				status,
				attachments: attachmentData,
				vendor: vendor?.id ?? null,
				preferredBranch,
				pickupType: 'grab',
				receiverFirstName: receiver?.firstName || null,
				receiverLastName: receiver?.lastName || null,
				receiverEmail: receiver?.email,
				receiverContactNumber: receiver?.mobile,
				line1: receiver?.address?.line1 || null,
				line2: receiver?.address?.line2 || null,
				brgyId: receiver?.address?.brgyId?.id || null,
				cityId: receiver?.address?.cityId?.id || null,
				firstName: patient?.firstName || null,
				lastName: patient?.lastName || null,
				email: patient?.email,
				mobile: patient?.mobile,
				patient: patient?.id,
				maintenance,
			};

			Object.keys(newValues).forEach((key) => {
				const setNewValue = (keyVal, value) => {
					setFieldValue(keyVal, value);
					setFieldTouched(keyVal, true, true);
				};

				if (newValues?.[key]) {
					const fieldValue = newValues?.[key];
					setNewValue(key, fieldValue);
				}
			});
		}
	};

	useEffect(() => {
		if (prescriptionDetails?.status === 'received') {
			history.push('/');
		}

		handleUpdateFormValues();
	}, [prescriptionDetails]);

	const onPrev = () => {
		if (step > 1) setStep(step - 1);
		else return null;
	};

	const onNext = () => {
		if (step < steps.length) {
			setStep(step + 1);
			window.scrollTo(0, 0);
		} else return null;
	};

	const shippingFillable = [
		'receiverFirstName',
		'receiverLastName',
		'receiverContactNumber',
		'receiverEmail',
		'cityId',
		'brgyId',
		'line1',
	];

	const step3Fillable = [
		'cityMunId',
		'vendor',
		'preferredBranch',
		'paymentMethod',
	];

	const enableNextBtn = () => {
		switch (step) {
			case 1:
				setDisabled(
					!(
						(isEmpty(values.attachments) &&
							!isEmpty(values.prescriptionItems)) ||
						(!isEmpty(values.attachments) &&
							isEmpty(values.prescriptionItems)) ||
						(!isEmpty(values.attachments) &&
							!isEmpty(values.prescriptionItems))
					),
				);
				break;

			case 2:
				if (values.pickupType === 'grab') {
					setDisabled(false);

					shippingFillable.forEach((field) => {
						const val = get(values, field);
						if (isEmpty(val)) setDisabled(true);
					});
				} else if (!isEmpty(values.pickupType)) {
					setDisabled(false);
				} else {
					setDisabled(true);
				}
				break;

			case 3:
				setDisabled(false);
				step3Fillable.forEach((field) => {
					const val = get(values, field);
					if (isEmpty(val)) setDisabled(true);
				});
				break;

			default:
				break;
		}
	};

	useEffect(() => {
		enableNextBtn();

		if (values) {
			setFormData(values);
		}
	}, [values, step, disabled]);

	return (
		<>
			<Helmet title={t('users.title')} />
			<Page>
				<CustomStep steps={steps} />
				<PatientOrderForm
					disabled={disabled}
					formikBag={formikBag}
					isSameCustomerDeets={isSameCustomerDeets}
					isSubmitting={isSubmitting}
					isSubmittingNewOrder={
						isSubmittingNewOrder || isUpdatingPrescription
					}
					mutateRenewRx={mutateRenewRx}
					onNext={onNext}
					onPrev={onPrev}
					patientInfo={patientInfo}
					setAttachments={setAttachments}
					setIsSameCustomerDeets={setIsSameCustomerDeets}
					step={step}
					steps={steps}
				/>
				<RemindersModal isOpen={remindersOpen} rxCode={rxCode} />
			</Page>
		</>
	);
}

PatientOrder.defaultProps = {
	prescriptionCode: null,
};
